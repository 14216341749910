import React from 'react';
import { Image } from 'react-bootstrap';

function PreviewImage({ src }) {
  return (
    <div>
      <Image
        loading="lazy"
        src={src}
        alt="image.name"
        height={60}
        width={60}
      />
    </div>
  );
}
export default PreviewImage;
