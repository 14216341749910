import React, { useEffect, useState } from 'react';
import {
  Container, Row, Col, Card, Button,
} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Footer from '../components/Footer';
import Header from '../components/Header';
import axios from '../utils/axios';
import { BASE_URL } from '../utils/appconstants';
import Loader from '../components/Loader';
import { CustomSpan } from '../components/styled';

function SingleOrder() {
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    const fetchOrderById = async () => {
      try {
        const response = await axios.get(`/api/orders/${id}/`, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        setOrder(response.data);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error('Error fetching order data:', error);
        setLoading(false); // Set loading to false even in case of an error
        toast.error('Failed to load order data. Please try again.');
      }
    };

    fetchOrderById();
  }, [id, userInfo.token]);

  const completeOrderPayment = async (amount, orderId) => {
    try {
      setLoading(true); // Set loading to true before initiating payment

      const response = await axios.post(
        '/api/orders/update/complete/',
        { amount },
        {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        },
      );

      if (response.status === 200) {
        navigate('/order/payement/pesapal/complete/', {
          state: response.data.redirect_url,
          all: {
            amount,
          },
        });
        localStorage.setItem('completeOrderId', orderId);
      } else {
        toast.error('Unable to process the order at the moment. Please try again later.');
      }
    } catch (error) {
      toast.error('Unable to process the order at the moment. Please try again later.');
    } finally {
      setLoading(false); // Set loading to false after completing payment or handling errors
    }
  };

  return (
    <>
      <Header />
      <Container fluid className="mt-1 page-content-wrapper">
        <Row>
          <Col md={8} className="offset-md-2">
            {loading ? ( // Show loader while loading
              <Loader />
            ) : (
              <>
                <Card>
                  <Card.Header>
                    <h3>
                      Order ID:
                      {' '}
                      {order.id}
                      {' '}
                      -
                      {' '}
                      {order.payment_method}
                      {' '}
                      -
                      {' '}
                      {order.paidAmount}
                    </h3>
                  </Card.Header>
                  <Card.Body>
                    <p>
                      Total Price: Ksh
                      {' '}
                      {Number(order.totalPrice).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                    </p>
                    <p>
                      Payment Method:
                      {' '}
                      {order.payment_method}
                    </p>
                    <p>
                      Tax Price: Ksh
                      {' '}
                      {Number(order.taxPrice).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                    </p>
                    <p>
                      Shipping Price: Ksh
                      {' '}
                      {Number(order.shippingPrice).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                    </p>
                    <p>
                      Paid Amount: Ksh
                      {' '}
                      {Number(order.paidAmount).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                    </p>
                    <p>
                      Is Delivered:
                      {' '}
                      {order.isDelivered ? 'Yes' : 'No'}
                    </p>
                    <p>
                      Is Fully Paid:
                      {' '}
                      {order.isFullyPaid ? 'Yes' : 'No'}
                    </p>
                    <p>
                      Delivered At:
                      {' '}
                      {order.deliveredAt || 'Not delivered yet'}
                    </p>
                    {!order?.isFullyPaid && (
                      <Button
                        size="sm"
                        onClick={
                          () => completeOrderPayment(
                            order.totalPrice * 1 - order.paidAmount * 1,
                            order.id,
                          )
}
                        variant="outline-info"
                        className="complete-payment-button"
                      >
                        Complete Order Payment
                      </Button>
                    )}
                  </Card.Body>
                </Card>
                <Card className="mt-3">
                  <Card.Header>
                    <h3>Order Items:</h3>
                  </Card.Header>
                  <Card.Body>
                    {order.orderItems.map((item) => (
                      <Row className="order-item my-row p-md-3">
                        <Col xs={6} sm={6} md={4}>
                          <img
                            src={`${BASE_URL}/media/${item.image}`}
                            style={{ maxWidth: '80px', display: 'block' }}
                            alt={item.name}
                            className="item-image"
                          />
                        </Col>
                        <Col xs={1} sm={1} md={4} />
                        <Col xs={5} sm={5} md={4}>
                          <div className="item-details">
                            <CustomSpan>{item.name}</CustomSpan>
                            <br />
                            <CustomSpan>
                              Price: Ksh
                              {' '}
                              {Number(item.price).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                            </CustomSpan>
                          </div>
                        </Col>
                      </Row>
                    ))}
                  </Card.Body>
                </Card>
              </>
            )}
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default SingleOrder;
