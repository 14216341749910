/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import {
  Badge, Button, Card, Col, Container, Image, ListGroup, Nav, Navbar, NavDropdown, Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router';
import { FaBars, FaTimes } from 'react-icons/fa';
import { logout } from '../actions/userActions';
import cakeImage1 from '../assets/images/Page 1_3.png';
import cakeImage2 from '../assets/images/Cakes & Cakes Logo-white.png';
import { listLocations } from '../actions/locationAction';
import CartCanvas from './cartCanvas';
import { listCurriculum } from '../actions/curriculumnAction';
import { CreateProductButton, CustomSpan } from './styled';
import { listProducts } from '../actions/productActions';
import { listCategories } from '../actions/categoryAction';

function Header({ fixed }) {
  const [navbarOpacity, setNavbarOpacity] = useState(0);
  const [show, setShow] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [navbarClicked, setNavbarClicked] = useState(false);
  const [scrollBackground, setScrollBackground] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLocationColumns, setShowLocationColumns] = useState(false);
  const [showCoursesColumns, setShowCoursesColumns] = useState(false);
  const [showShopColumns, setShowShopColumns] = useState(false);
  const [showProfileColumns, setShowProfileColumns] = useState(false);

  const [shopNavLink, setShopNavLink] = useState(true);
  const [academyNavlink, setAcademyNavlink] = useState(true);
  const [locationNavlink, setLocationNavlink] = useState(true);
  const [cartLink, setCartLink] = useState(true);
  const [profileLink, setProfileLink] = useState(true);

  const { userLogin: { userInfo }, cart: { cartItems } } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navbarRef = useRef(null);

  const curriculumList = useSelector((state) => state.curriculumList);
  const { curriculums } = curriculumList;

  const locationsList = useSelector((state) => state.locationsList);
  const { loading, error, locations } = locationsList;

  const productList = useSelector((state) => state.productList);
  const { products = [] } = productList;
  const categoriesList = useSelector((state) => state.categoriesList);
  const { loading: loadingCategory, categories } = categoriesList;

  useEffect(() => {
    dispatch(listCurriculum());
  }, [dispatch, userInfo]);

  const logoutHandler = () => {
    dispatch(logout());
    navigate('/');
  };

  useEffect(() => {
    dispatch(listProducts());
    dispatch(listLocations());
  }, [dispatch, userInfo]);

  useEffect(() => {
    const handleScrollNavBar = () => {
      const scrollTop = window.pageYOffset;
      if (!isMobileView) {
        setNavbarOpacity(scrollTop > 50 ? 1 : 0);
        setScrollBackground(scrollTop > 50);
      } else {
        setNavbarOpacity(1);
        setScrollBackground(true);
      }
    };

    window.addEventListener('scroll', handleScrollNavBar);
    return () => {
      window.removeEventListener('scroll', handleScrollNavBar);
    };
  }, [isMobileView]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
      setShowDropdown(false);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(listCategories());
  }, [dispatch, navigate, userInfo]);

  const categoryProducts = () => (categories || []).reduce((acc, cat) => {
    acc[cat.name] = [];
    return acc;
  }, {});

  products.forEach((product) => {
    const { category, name } = product;
    if (categoryProducts().hasOwnProperty(category)) {
      categoryProducts()[category].push(name);
    }
  });

  const getRandomImage = (category) => {
    const catProducts = products.filter((
      product,
    ) => product && product?.category?.name === category);
    if (catProducts.length > 0) {
      const randomIndex = Math.floor(Math.random() * catProducts.length);
      return catProducts[randomIndex].image1;
    }
    return 'default-image-url.jpg';
  };

  const handleLocationClick = () => {
    setShowLocationColumns(!showLocationColumns);
    setShopNavLink(!shopNavLink);
    setAcademyNavlink(!academyNavlink);
    setCartLink(!cartLink);
    setProfileLink(!profileLink);
  };

  const handleCourseClick = () => {
    setShowCoursesColumns(!showCoursesColumns);
    setShopNavLink(!shopNavLink);
    setLocationNavlink(!locationNavlink);
    setCartLink(!cartLink);
    setProfileLink(!profileLink);
  };

  const handleShopClick = () => {
    setAcademyNavlink(!academyNavlink);
    setLocationNavlink(!locationNavlink);
    setCartLink(!cartLink);
    setProfileLink(!profileLink);
    setShowShopColumns(!showShopColumns);
  };

  const handleShowProfileClick = () => {
    setAcademyNavlink(!academyNavlink);
    setShopNavLink(!shopNavLink);
    setLocationNavlink(!locationNavlink);
    setCartLink(!cartLink);
    setShowProfileColumns(!showProfileColumns);
  };

  const handleClose = () => {
    setShow(false);
    setNavbarClicked(false);
  };

  const handleNavClose = () => {
    setNavbarClicked(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const numColumns = Object.keys(categoryProducts()).reduce(
    (count, category) => {
      const filteredProducts = products.filter(
        (product) => product?.category?.name === category,
      );
      if (filteredProducts.length > 0) {
        return count + 1;
      }
      return count;
    },
    0,
  );

  return (
    <header>
      <Navbar
        ref={navbarRef}
        fixed={fixed}
        className={`${
          fixed === 'top' && navbarOpacity === 0 ? 'unscrolled' : 'scrolled'
        } `}
        style={{
          backgroundColor: isMobileView ? `rgba(0, 112, 128, ${navbarOpacity})` : `rgba(255, 255, 255, ${navbarOpacity})`,
          padding: isMobileView ? '10px' : '',
        }}
        // onClick={() => setNavbarClicked(!navbarClicked)}
        collapseOnSelect
        id="header"
        expand="lg"
        variant="responsive"
      >
        <Container>
          <Navbar.Toggle
            className="custom-collapse"
            aria-controls="responsive-navbar-nav"
            as="button"
          >
            <FaBars style={{ color: '#fff', borderColor: '#fff' }} />
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="justify-content-between w-100 text-center">
              {isMobileView ? (
                shopNavLink && (
                  <Nav.Link className="flex-fill text-center mb-2 mr-auto" onClick={handleShopClick}>
                    <h3 type="text" className="custom-link">
                      <span className="mr-2">Shop</span>
                      {showShopColumns && isMobileView ? (
                        <i className="fa-solid fa-caret-left" />
                      ) : (
                        <i className="fa-solid fa-caret-right" />
                      )}
                    </h3>
                    <hr className="divider" />
                  </Nav.Link>
                )
              ) : (
                <Nav.Link className="text-center mb-2 mr-auto">
                  <div className="dropdownShop">
                    <h3
                      onClick={() => navigate('/shop')}
                      className="dropbtnShop custom-link"
                    >
                      <i className="fa-solid fa-shop" />
                      &nbsp;&nbsp;Shop
                    </h3>
                    <div
                      className="dropdownShop-content"
                      style={{
                        minWidth: numColumns === 1 ? '200px' : `${200 * numColumns}px`,
                        transform: numColumns > 2 ? 'translateX(calc(50% + 150px))' : 'translateX(50%)',
                      }}
                    >
                      {/* <Row className="justify-content-md-center p-1"> */}
                      <Row>
                        {Object.keys(categoryProducts()).map((category) => {
                        // Filter products based on category
                          const filteredProducts = products.filter(
                            (product) => product?.category?.name === category,
                          );

                          // Check if the filtered products array is empty
                          if (filteredProducts.length === 0) {
                            // If the array is empty, do not render the column for this category
                            return null;
                          }

                          let xsProp;
                          let mdProp;
                          if (numColumns === 1) {
                            xsProp = 12;
                            mdProp = '600px';
                          } else if (numColumns === 2) {
                            xsProp = 6;
                            mdProp = '300px';
                          } else {
                            xsProp = 12 / numColumns;
                          }

                          // If the array is not empty, render the column
                          return (
                            <Col key={category}>
                              <Card border="light" className="h-100 d-flex flex-column custom-card">
                                {getRandomImage(category) && (
                                <Card.Img
                                  className="img-fluid img-responsive custom-rounded"
                                  variant="top"
                                  src={`${getRandomImage(category)}`}
                                  alt={category}
                                  rounded
                                  style={{ objectFit: 'contain', display: 'block', width: '100%' }}
                                />
                                )}
                                <Card.Body>
                                  <Card.Text>
                                    <center>
                                      <CustomSpan
                                        onClick={() => navigate('/shop', { state: category })}
                                      >
                                        {category}
                                      </CustomSpan>
                                    </center>
                                  </Card.Text>
                                  <hr />
                                  <ListGroup variant="flush">
                                    {(filteredProducts.slice(0, 6) || []).map((product, index) => (
                                      <ListGroup.Item
                                        action
                                        onClick={() => navigate(`/product/${product.id}`)}
                                        key={index}
                                      >
                                        <CustomSpan>
                                          {product.name}
                                        </CustomSpan>
                                      </ListGroup.Item>
                                    ))}
                                  </ListGroup>
                                </Card.Body>
                              </Card>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  </div>
                </Nav.Link>
              )}
              {showShopColumns && isMobileView && (
                <>
                  <Row className="justify-content-md-center p-3">
                    {Object.keys(categoryProducts()).map((category) => (
                      <Col key={category} xs={6} sm={6} md={4} lg={4} className="mb-4">
                        <Card
                          style={{ backgroundColor: 'transparent' }}
                          border="light"
                          className="h-100 d-flex flex-column custom-card"
                        >
                          {getRandomImage(category) && (
                            <Card.Img
                              className="img-fluid img-responsive custom-rounded"
                              variant="top"
                              src={`${getRandomImage(category)}`}
                              alt={category}
                              rounded
                              style={{ objectFit: 'cover', display: 'block', height: '100px' }}
                            />
                          )}
                          <Card.Body>
                            <Card.Text>
                              <center>
                                <small>
                                  <h6 style={{ color: '#fff' }}>{category}</h6>
                                </small>
                              </center>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                  <Button onClick={() => navigate('/shop')} variant="outline-light" className="w-100">
                    View our shop
                  </Button>
                </>
              )}
              {isMobileView ? (
                academyNavlink && (
                  <Nav.Link className="flex-fill text-center mb-2 mr-auto" onClick={handleCourseClick}>
                    <h3 type="text" className="custom-link">
                      <span className="mr-2">Academy</span>
                      {showCoursesColumns && isMobileView ? (
                        <i className="fa-solid fa-caret-left" />
                      ) : (
                        <i className="fa-solid fa-caret-right" />
                      )}
                    </h3>
                    <hr className="divider" />
                  </Nav.Link>
                )
              ) : (
                <Nav.Link className="flex-fill text-center mb-2 mr-auto">
                  <div className="dropdownCurriculum">
                    <h3
                      onClick={() => navigate('/academy/curriculum')}
                      className="dropbtnCurriculum custom-link"
                    >
                      <i className="fa-solid fa-graduation-cap" />
                      &nbsp;&nbsp;Academy
                    </h3>
                    <div className="dropdownCurriculum-content">
                      <Row className="justify-content-md-center">
                        {curriculums && curriculums?.slice(0, 5).map((curr) => (
                          <Col key={curr.id}>
                            <Card
                              border="light"
                              onClick={() => navigate(`/academy/curriculum/${curr.id}`)}
                              className="h-100 d-flex flex-column custom-card"
                            >
                              <Card.Img
                                className="img-fluid img-responsive custom-rounded"
                                variant="top"
                                src={`${curr.image}`}
                                rounded
                                style={{ objectFit: 'contain', display: 'block', width: '100%' }}
                              />
                              <Card.Body>
                                <Card.Text>
                                  <center>
                                    <CustomSpan>{curr.title}</CustomSpan>
                                  </center>
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                </Nav.Link>
              )}
              {showCoursesColumns && isMobileView && (
                <>
                  <Row>
                    {curriculums && curriculums?.slice(0, 6).map((curr) => (
                      <Col xs={6} sm={6} md={4} className="px-2 mb-3" key={curr.id}>
                        <Card
                          border="light"
                          style={{ backgroundColor: 'transparent' }}
                          onClick={() => navigate(`/academy/curriculum/${curr.id}`)}
                          className="h-100 d-flex flex-column custom-card"
                        >
                          <Card.Img
                            className="img-fluid img-responsive custom-rounded"
                            variant="top"
                            src={`${curr.image}`}
                            rounded
                            style={{ objectFit: 'cover', display: 'block', height: '100px' }}
                          />
                          <Card.Body>
                            <Card.Text>
                              <center>
                                <small>
                                  <h6 style={{ color: '#fff' }}>{curr.title}</h6>
                                </small>
                              </center>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                  <Button onClick={() => navigate('/academy/curriculum')} variant="outline-light">
                    View our academies
                  </Button>
                </>
              )}
            </Nav>
            <Nav className="mx-auto text-center">
              <Navbar.Brand className="text-center">
                {isMobileView ? (
                  <Container>
                    <LinkContainer to="/">
                      <Image
                        className="d-inline-block align-top align-left navbar-image"
                        alt="Brand logo"
                        src={cakeImage2}
                        height={41.02}
                        width={60}
                      />
                    </LinkContainer>
                    {/* <Button variant="link" className="close-button">
                      <FaTimes />
                    </Button> */}
                  </Container>
                ) : (
                  <LinkContainer to="/">
                    <Col xs={12} sm={6} md={4} lg={3} xl={2} className="text-center">
                      <Image
                        loading="lazy"
                        className="d-inline-block align-top scaled-image"
                        alt="Brand logo"
                        src={fixed === 'top' && navbarOpacity === 0 ? cakeImage2 : cakeImage1}
                        height={100}
                      />
                    </Col>
                  </LinkContainer>
                )}
              </Navbar.Brand>
            </Nav>
            <Nav className="justify-content-between w-100 custom-collapse text-center">
              {isMobileView ? (
                locationNavlink && (
                  <Nav.Link className="flex-fill text-center mb-2 mr-auto" onClick={handleLocationClick}>
                    <h3 type="text" className="custom-link">
                      <span className="mr-2">Locations</span>
                      {showLocationColumns && isMobileView ? (
                        <i className="fa-solid fa-caret-left" />
                      ) : (
                        <i className="fa-solid fa-caret-right" />
                      )}
                    </h3>
                    <hr className="divider" />
                  </Nav.Link>
                )
              ) : (
                <Nav.Link className="flex-fill text-center mb-2 mr-auto">
                  <div className="dropdownLocation">
                    <h3
                      type="text"
                      onClick={() => navigate('/locations')}
                      className="dropbtnLocation custom-link"
                    >
                      <i className="fa-solid fa-location-dot" />
                      &nbsp;&nbsp;Locations
                    </h3>
                    <div className="dropdownLocation-content">
                      <Row className="justify-content-md-center">
                        {locations.slice(0, 4).map((location) => (
                          <Col key={location.id}>
                            <Card
                              border="light"
                              onClick={() => navigate(`/locations/${location.id}`)}
                              className="h-100 d-flex flex-column custom-card"
                            >
                              <Card.Img
                                className="img-fluid img-responsive custom-rounded"
                                variant="top"
                                src={location.location_image}
                                style={{ objectFit: 'contain', display: 'block', width: '100%' }}
                              />
                              <Card.Body>
                                <Card.Text>
                                  <center>
                                    <CustomSpan>{location.name}</CustomSpan>
                                  </center>
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                </Nav.Link>
              )}
              {showLocationColumns && isMobileView && (
                <>
                  <Row className="justify-content-center p-4">
                    {locations.map((location) => (
                      <Col key={location.id} xs={6} sm={6} md={4} className="px-2">
                        <Card
                          border="light"
                          style={{ backgroundColor: 'transparent' }}
                          onClick={() => navigate(`/locations/${location.id}`)}
                          className="px-0"
                        >
                          <Card.Img
                            className="img-fluid img-responsive"
                            variant="top"
                            src={location.location_image}
                            style={{ objectFit: 'cover', display: 'block', height: '100px' }}
                          />
                          <Card.Body>
                            <center>
                              <small>
                                <h6 style={{ color: '#fff' }}>{location.name}</h6>
                              </small>
                            </center>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                  <Button onClick={() => navigate('/locations')} variant="outline-light">
                    View all our Locations
                  </Button>
                </>
              )}
              {isMobileView ? (
                profileLink && (
                  <Nav.Link className="flex-fill text-center mb-2 mr-auto" onClick={handleShowProfileClick}>
                    <h3 type="text" className="custom-link">
                      <span className="mr-2">Profile</span>
                      {showProfileColumns && isMobileView ? (
                        <i className="fa-solid fa-caret-left" />
                      ) : (
                        <i className="fa-solid fa-caret-right" />
                      )}
                    </h3>
                    <hr className="divider" />
                  </Nav.Link>
                )
              ) : (
                <Nav.Link className="flex-fill text-center mb-2 mr-auto">
                  <div className="dropdownAccount">
                    <h3 type="text" className="dropbtnAccount custom-link">
                      {userInfo ? (
                        <>
                          <i className="fa-solid fa-user" />
                          &nbsp;&nbsp;
                          {userInfo.first_name}
                        </>
                      ) : (
                        <>
                          <i className="fa-solid fa-user" />
                          &nbsp;
                        </>
                      )}
                    </h3>
                    <div className="dropdownAccount-content">
                      {!userInfo && (
                        <NavDropdown.Item onClick={() => navigate('/authenticate')}>
                          <h6>Login</h6>
                        </NavDropdown.Item>
                      )}
                      {userInfo && userInfo.isAdmin && (
                        <LinkContainer to="/shop/admin">
                          <NavDropdown.Item>
                            <h6>
                              <i className="fa-solid fa-lock" />
                              &nbsp;Admin
                            </h6>
                          </NavDropdown.Item>
                        </LinkContainer>
                      )}

                      {userInfo && userInfo.is_student && (
                      <LinkContainer to="/studentportal">
                        <NavDropdown.Item>
                          <h6>
                            <i className="fa-solid fa-graduation-cap" />
                              &nbsp;Portal
                          </h6>
                        </NavDropdown.Item>
                      </LinkContainer>
                      )}
                      {userInfo && (
                      <>
                        <LinkContainer to="/profile">
                          <NavDropdown.Item>
                            <h6>
                              <i className="fas fa-user" />
                                &nbsp;Profile
                            </h6>
                          </NavDropdown.Item>
                        </LinkContainer>
                        <NavDropdown.Item onClick={logoutHandler}>
                          <h6>Logout</h6>
                        </NavDropdown.Item>
                      </>
                      )}
                    </div>
                  </div>
                </Nav.Link>
              )}
              {showProfileColumns && isMobileView && (
              <>
                {!userInfo && (
                <h6
                  style={{ color: '#fff' }}
                  onClick={() => navigate('/authenticate')}
                >
                  Login
                </h6>
                )}
                {userInfo?.isAdmin && (
                <h6 onClick={() => navigate('/studentportal')} style={{ color: '#fff' }}>
                  <i className="fa-solid fa-graduation-cap" />
                          &nbsp;portal
                </h6>
                )}
                <br />
                {userInfo?.isAdmin && (
                <h6 onClick={() => navigate('/shop/admin')} style={{ color: '#fff' }}>
                  <i className="fa-solid fa-lock" />
                          &nbsp;Admin
                </h6>
                )}
                <br />
                {userInfo && (
                <>
                  <h6 onClick={() => navigate('/profile')} style={{ color: '#fff' }}>
                    <i className="fas fa-user" />
                            &nbsp;Profile
                  </h6>
                  <br />
                  <h6 style={{ color: '#fff' }} onClick={logoutHandler}>
                    <i className="fas fa-sign-out-alt" />
                            &nbsp;Logout
                  </h6>
                </>
                )}
              </>
              )}

              {isMobileView ? (
                cartLink && (
                  <Nav.Link className="flex-fill text-center mb-2 mr-auto">
                    <h3 onClick={() => handleShow()} className="custom-link">
                      Cart
                    </h3>
                    <hr className="divider" />
                  </Nav.Link>
                )
              ) : (
                <Nav.Link className="flex-fill text-center mb-2 mr-auto">
                  <CreateProductButton className="custom-link" variant="outline" onClick={() => handleShow()}>
                    <i className="fa-solid fa-cart-shopping" />
                    {cartItems.length > 0 && (
                      <>
                        <Badge bg="secondary">{cartItems.length}</Badge>
                        <span className="visually-hidden">Cart</span>
                      </>
                    )}
                  </CreateProductButton>
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {show && <CartCanvas show={show} handleClose={() => handleClose()} />}
    </header>
  );
}

export default Header;
