/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Pagination from 'react-bootstrap/Pagination';
import WeightEditScreen from './WeightEditScreen';
import AddWeight from '../components/AddWeight';
import axios from '../utils/axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CustomSelect } from '../components/styled';

function WeightListScreen() {
  const [weights, setWeights] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredWeights, setFilteredWeights] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [productNameFilter, setProductNameFilter] = useState('');
  const [weightVisible, setWeightModalShow] = useState(false);
  const [selectedWeight, setWeightDetails] = useState(null);
  const [weightAddVisible, setWeightAddModalShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const fetchWeights = async () => {
    try {
      const response = await axios.get('/api/weights/');
      setWeights(response.data);
    } catch (error) {
      toast.error('Error while fetching weights');
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await axios.get('/api/products/', {
        params: {
          page: 1,
          page_size: 100,
          ordering: '-createdAt',
        },
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      setProducts(response.data.results);
    } catch (error) {
      toast.error('Error while fetching products');
    }
  };

  const filterWeights = () => {
    let filtered = weights;

    if (selectedProduct) {
      filtered = filtered.filter((item) => item.product && item.product.name === selectedProduct);
    }

    if (productNameFilter.trim() !== '') {
      filtered = filtered.filter((item) => item.product && item.product.name.toLowerCase()
        .includes(productNameFilter.toLowerCase()));
    }

    setFilteredWeights(filtered);
  };

  useEffect(() => {
    fetchWeights();
    fetchProducts();
  }, []);

  useEffect(() => {
    filterWeights();
  }, [weights, selectedProduct, productNameFilter]);

  const deleteWeight = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this weight?');
    if (confirmDelete) {
      try {
        await axios.delete(`/api/weights/${id}/`);
        toast.success('Weight deleted successfully');
        fetchWeights();
      } catch (error) {
        toast.error('Error while deleting weight');
      }
    }
  };

  const handleWeightModalClose = () => setWeightModalShow(false);
  const handleWeightModalShow = (item) => {
    setWeightModalShow(true);
    setWeightDetails(item);
  };

  const handleWeightAddModalClose = () => setWeightAddModalShow(false);
  const handleWeightAddModalShow = () => setWeightAddModalShow(true);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="main-card mb-3 card">
            <div className="card-header">
              <Form.Group controlId="selectedProduct">
                <CustomSelect
                  value={selectedProduct}
                  onChange={(e) => setSelectedProduct(e.target.value)}
                >
                  <option value="">Filter by Products</option>
                  {products.map((product) => (
                    <option key={product.id} value={product.name}>
                      {product.name}
                    </option>
                  ))}
                </CustomSelect>
              </Form.Group>
              <div className="btn-actions-pane-right">
                <div role="group" className="btn-group-sm btn-group">
                  <Button
                    onClick={handleWeightAddModalShow}
                    className="active btn btn-focus"
                  >
                    <i className="fas fa-plus" />
                    &nbsp;
                    Create Weight
                  </Button>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="align-middle mb-0 table table-borderless table-striped table-hover">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Weight</th>
                    <th className="text-center">Product</th>
                    <th className="text-center">Price</th>
                    <th className="text-center">Serves Per Weight</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {' '}
                  {filteredWeights.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="text-center">No weights found</td>
                    </tr>
                  ) : (filteredWeights.slice((currentPage - 1)
                   * itemsPerPage, currentPage * itemsPerPage).map((item) => (
                     <tr key={item.id}>
                       <td className="text-center text-muted">{item.id}</td>
                       <td>
                         <div className="widget-content p-0">
                           <div className="widget-content-wrapper">
                             <div className="widget-content-left mr-3">
                               <div className="widget-content-left">
                                 <img
                                   width="40"
                                   loading="lazy"
                                   className="rounded-circle"
                                   src="assets/images/avatars/4.jpg"
                                   alt=""
                                 />
                               </div>
                             </div>
                             <div className="widget-content-left flex2">
                               <div className="widget-heading">{item.cake_weight}</div>
                             </div>
                           </div>
                         </div>
                       </td>
                       <td className="text-center">
                         {item.product ? (
                           <div className="badge badge-warning">{item.product.name}</div>
                         ) : (
                           <div className="badge badge-warning">Product not available</div>
                         )}
                       </td>
                       <td className="text-center">
                         <div className="badge badge-warning">{item.price}</div>
                       </td>
                       <td className="text-center">
                         <div className="badge badge-warning">{item.serves_per_weight}</div>
                       </td>
                       <td className="text-center">
                         <Button
                           onClick={() => handleWeightModalShow(item)}
                           variant="light"
                           className="btn-sm"
                         >
                           <i className="fas fa-edit" />
                         </Button>
                         <Button
                           variant="danger"
                           className="btn-sm"
                           onClick={() => deleteWeight(item.id)}
                         >
                           <i className="fas fa-trash" />
                         </Button>
                       </td>
                     </tr>
                  ))
                  )}
                </tbody>
              </table>
            </div>
            <div className="d-block text-center card-footer">
              {/* React Bootstrap Pagination */}
              <Pagination
                size="lg"
                className="custom-pagination"
                style={{ float: 'right' }}
              >
                <Pagination.Prev
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {Array.from({
                  length: Math.ceil(weights.length / itemsPerPage),
                }).map((_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === Math.ceil(weights.length / itemsPerPage)}
                />
              </Pagination>
            </div>
          </div>
        </div>
      </div>

      {weightVisible && (
        <WeightEditScreen
          weightVisible={weightVisible}
          weightData={selectedWeight}
          fetchWeights={fetchWeights}
          handleWeightModalClose={handleWeightModalClose}
          handleWeightModalShow={handleWeightModalShow}
        />
      )}
      {weightAddVisible && (
        <AddWeight
          weightVisible={weightAddVisible}
          fetchWeights={fetchWeights}
          handleWeightModalClose={handleWeightAddModalClose}
          handleWeightModalShow={handleWeightAddModalShow}
        />
      )}
    </div>
  );
}

export default WeightListScreen;
