/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useNavigate } from 'react-router';

function SingleProductBreadCrumb({ product }) {
  const { category = {}, name = '' } = product;
  const { id = '', name: categoryName = '' } = category;
  const navigate = useNavigate();
  return (
    product && (
    <Breadcrumb className="breadcrumb-styling">
      <Breadcrumb.Item className="breadcrumb-link">
        <span
          onClick={() => navigate('/shop')}
          className="breadcrumb-item-text"
        >
          Shop
        </span>
      </Breadcrumb.Item>
      <Breadcrumb.Item active>
        <span className="breadcrumb-item-text">{name}</span>
      </Breadcrumb.Item>
    </Breadcrumb>
    )
  );
}

export default SingleProductBreadCrumb;
