export const CURRICULUM_LIST_SUCCESS = 'CURRICULUM_LIST_SUCCESS';
export const CURRICULUM_LIST_REQUEST = 'CURRICULUM_LIST_REQUEST';
export const CURRICULUM_LIST_FAIL = 'CURRICULUM_LIST_FAIL';

export const CURRICULUM_DETAILS_SUCCESS = 'CURRICULUM_DETAILS_SUCCESS';
export const CURRICULUM_DETAILS_REQUEST = 'CURRICULUM_DETAILS_REQUEST';
export const CURRICULUM_DETAILS_FAIL = 'CURRICULUM_DETAILS_FAIL';

export const CURRICULUM_DELETE_REQUEST = 'CURRICULUM_DELETE_REQUEST';
export const CURRICULUM_DELETE_SUCCESS = 'CURRICULUM_DELETE_SUCCESS';
export const CURRICULUM_DELETE_FAIL = 'CURRICULUM_DELETE_FAIL';

export const CURRICULUM_CREATE_REQUEST = 'CURRICULUM_CREATE_REQUEST';
export const CURRICULUM_CREATE_SUCCESS = 'CURRICULUM_CREATE_SUCCESS';
export const CURRICULUM_CREATE_FAIL = 'CURRICULUM_CREATE_FAIL';
export const CURRICULUM_CREATE_RESET = 'CURRICULUM_CREATE_RESET';

export const CURRICULUM_UPDATE_REQUEST = 'CURRICULUM_UPDATE_REQUEST';
export const CURRICULUM_UPDATE_SUCCESS = 'CURRICULUM_UPDATE_SUCCESS';
export const CURRICULUM_UPDATE_FAIL = 'CURRICULUM_UPDATE_FAIL';
export const CURRICULUM_UPDATE_RESET = 'CURRICULUM_UPDATE_RESET';

export const CURRICULUM_CREATE_REVIEW_REQUEST = 'CURRICULUM_CREATE_REVIEW_REQUEST';
export const CURRICULUM_CREATE_REVIEW_SUCCESS = 'CURRICULUM_CREATE_REVIEW_SUCCESS';
export const CURRICULUM_CREATE_REVIEW_FAIL = 'CURRICULUM_CREATE_REVIEW_FAIL';
export const CURRICULUM_CREATE_REVIEW_RESET = 'CURRICULUM_CREATE_REVIEW_RESET';

export const CURRICULUM_TOP_REQUEST = 'CURRICULUM_TOP_REQUEST';
export const CURRICULUM_TOP_SUCCESS = 'CURRICULUM_TOP_SUCCESS';
export const CURRICULUM_TOP_FAIL = 'CURRICULUM_TOP_FAIL';
