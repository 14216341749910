/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Loader from '../components/Loader'; // Import the Loader component
import axios from '../utils/axios';

function UserEnrollments() {
  const [enrollments, setEnrollments] = useState([]);
  const [loading, setLoading] = useState(true); // Add a loading state

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const fetchEnrollments = async () => {
    try {
      const response = await axios.get('/api/enrollments/user/details/', {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'Content-type': 'application/json',
        },
      });
      setEnrollments(response.data);
      setLoading(false); // Set loading to false once data is fetched
    } catch (error) {
      console.log('Error fetching enrollments:', error);
      setLoading(false); // Set loading to false even if there's an error
    }
  };

  useEffect(() => {
    fetchEnrollments();
  }, []);

  return (
    <>
      <Header />
      <Container className="page-content-wrapper">
        {loading ? ( // Check if loading is true, display loading indicator
          <Loader />
        ) : enrollments.length > 0 ? (
          <>
            <h1>
              Hello
              {' '}
              {userInfo.first_name}
              {' '}
              {userInfo.last_name}
            </h1>
            <Table striped bordered size="lg" hover className="custom-table table-odd">
              <thead>
                <tr>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Course Start Time</th>
                  <th>Curriculum</th>
                  <th>Enrollment Date</th>
                  {/* Add more columns based on your data */}
                </tr>
              </thead>
              <tbody>
                {enrollments.map((enrollment) => (
                  <tr key={enrollment.id}>
                    <td>{moment(enrollment.curriculum?.courses[0]?.start_date).format('LL') || 'N/A'}</td>
                    <td>{moment(enrollment.curriculum?.courses[0]?.end_date).format('LL') || 'N/A'}</td>
                    <td>{enrollment.course_time?.time_slot || 'N/A'}</td>
                    <td>{enrollment.curriculum?.title || 'N/A'}</td>
                    <td>{moment(enrollment.enrollment_date).format('LL LTS') || 'N/A'}</td>
                    {/* Add more columns based on your data */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        ) : (
          <div />
        )}
      </Container>
      <Footer />
    </>
  );
}

export default UserEnrollments;
