/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import {
  Button, Offcanvas, Form,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import axios from '../utils/axios';
import Loader from '../components/Loader';
import {
  CreateProductButton, CustomFormControl, CustomLabel, CustomSelect,
} from '../components/styled';

function InstructorListScreen() {
  const [instructors, setInstructors] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedInstructor, setSelectedInstructor] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [newUserId, setNewUserId] = useState('');
  const [newBio, setNewBio] = useState('');
  const [newAddress, setNewAddress] = useState('');

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  useEffect(() => {
    const fetchInstructors = async () => {
      setLoading(true);
      try {
        const response = await axios.get('/api/instructors/', config);
        setInstructors(response.data);
        setLoading(false);
      } catch (error) {
        toast.error('Failed to fetch instructors.');
        setLoading(false);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/users/', config);
        setUsers(response.data);
      } catch (error) {
        toast.error('Failed to fetch users.');
      }
    };

    fetchInstructors();
    fetchUsers();
  }, []);

  const handleEdit = (instructor) => {
    setSelectedInstructor(instructor);
    setNewUserId(instructor?.user?.id);
    setNewBio(instructor?.bio);
    setNewAddress(instructor?.address);
    setEditMode(true);
  };

  const handleUpdate = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    try {
      const response = await axios.put(
        `/api/instructors/${selectedInstructor?.id}/`,
        {
          user: newUserId,
          bio: newBio,
          address: newAddress,
          user_id: newUserId,
        },
        config,
      );
      const updatedInstructor = response.data;
      setInstructors((prevInstructors) => prevInstructors.map(
        (instructor) => (
          instructor?._id === updatedInstructor?._id ? updatedInstructor : instructor),
      ));
      setEditMode(false);
      setSelectedInstructor(null);
      toast.success('Instructor updated successfully');
    } catch (error) {
      toast.error('Failed to update instructor?.');
    }
  };

  const handleCreate = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Check if newUserId already exists in instructors or users
    const existingInstructor = instructors.find(
      (instructor) => instructor?.user?.id === Number(newUserId),
    );

    if (existingInstructor) {
      toast.error('Instructor with the selected ID already exists.');
      return; // Stop execution if instructor or user with the ID already exists
    }

    try {
      const response = await axios.post('/api/instructors/', {
        user_id: newUserId,
        user: newUserId,
        bio: newBio,
        address: newAddress,
      }, config);
      const createdInstructor = response.data;
      setInstructors((prevInstructors) => [...prevInstructors, createdInstructor]);
      toast.success('Instructor created successfully');
      setNewUserId('');
      setNewBio('');
      setNewAddress('');
      setEditMode(false);
    } catch (error) {
      toast.error('Failed to create instructor.');
    }
  };

  const handleDelete = async (instructor) => {
    const confirmDelete = (window.confirm('Are you sure you want to delete this instructor?'));
    if (confirmDelete) {
      try {
        await axios.delete(`/api/instructors/${instructor?.id}/`, config);
        setInstructors((prevInstructors) => prevInstructors.filter(
          (inst) => inst._id !== instructor?._id,
        ));
        toast.success('Instructor deleted successfully');
      } catch (error) {
        toast.error('Failed to delete instructor?.');
      }
    }
  };

  const handleToggleEditMode = () => {
    setEditMode((prevEditMode) => !prevEditMode);
    setSelectedInstructor(null);
    setNewUserId('');
    setNewBio('');
    setNewAddress('');
  };

  return (
    <div className="row">
      {loading ? (
        <Loader />
      ) : (
        <>

          <div className="row">
            <div className="col-md-12">
              <div className="main-card mb-3 card">
                <div className="card-header">
                  Instructors
                  <div className="btn-actions-pane-right">
                    <div role="group" className="btn-group-sm btn-group">
                      {!editMode && (
                      <Button
                        className="active btn btn-focus"
                        onClick={handleToggleEditMode}
                      >
                        {' '}
                        <i className="fas fa-plus" />
              &nbsp;Create Instructor

                      </Button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">Name</th>
                        <th className="text-center">Bio</th>
                        <th className="text-center">Address</th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {instructors.map((instructor) => (
                        <tr key={instructor?._id}>
                          <td className="text-center">
                            {instructor?.user?.first_name}
                            {' '}
                            {instructor?.user?.last_name}
                          </td>
                          <td className="text-center">{instructor?.bio}</td>
                          <td className="text-center">{instructor?.address}</td>
                          <td className="text-center">
                            <Button
                              onClick={() => handleEdit(instructor)}
                              variant="light"
                              className="btn-sm"
                            >
                              <i className="fas fa-edit" />
                            </Button>

                            <Button variant="danger" className="btn-sm" onClick={() => handleDelete(instructor)}>
                              <i className="fas fa-trash" />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>

                  </table>
                </div>
                <div className="d-block text-center card-footer">
                  {/* <button className="mr-2 btn-icon btn-icon-only btn btn-outline-danger">
                  <i className="pe-7s-trash btn-icon-wrapper"> </i></button>
                  <button className="btn-wide btn btn-success">Save</button> */}
                </div>
              </div>
            </div>
          </div>

          <Offcanvas
            backdrop="static"
            placement="end"
            show={editMode}
            onHide={handleToggleEditMode}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>{selectedInstructor ? 'Edit Instructor' : 'Create Instructor'}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Form>
                <Form.Group className="mb-3" controlId="formUserId">
                  <CustomLabel>User</CustomLabel>
                  <CustomSelect
                    required
                    className="w-100 required-field"
                    value={newUserId}
                    onChange={(e) => setNewUserId(e.target.value)}
                  >
                    <option value="">Select User</option>
                    {users.map((user) => (
                      <option key={user._id} value={user.id}>
                        {user.first_name}
                        {' '}
                        {user.last_name}
                      </option>
                    ))}
                  </CustomSelect>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBio">
                  <CustomLabel>Bio</CustomLabel>
                  <CustomFormControl
                    className="w-100 required-field"
                    as="textarea"
                    rows={5}
                    value={newBio}
                    onChange={(e) => setNewBio(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formAddress">
                  <CustomLabel>Address</CustomLabel>
                  <CustomFormControl
                    className="w-100 required-field"
                    type="text"
                    value={newAddress}
                    onChange={(e) => setNewAddress(e.target.value)}
                  />
                </Form.Group>
                <div className="cart-footer-product">
                  <CreateProductButton
                    className="w-100 required-field"
                    onClick={selectedInstructor ? handleUpdate : handleCreate}
                    variant="outline-secondary"
                    type="submit"
                  >
                    {selectedInstructor ? 'Update' : 'Create'}
                  </CreateProductButton>
                  {/* <Button variant="secondary" onClick={handleToggleEditMode}>Cancel</Button> */}
                </div>
              </Form>
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )}
    </div>
  );
}

export default InstructorListScreen;
