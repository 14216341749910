/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Offcanvas } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CreateProductButton, CustomFormControl, CustomLabel } from '../components/styled';
import axios from '../utils/axios';

function UserEditScreen({
  show, handleClose, selectedUser, fetchUsers,
}) {
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    setFirstName(selectedUser.first_name);
    setLastName(selectedUser.last_name);
    setPhoneNumber(selectedUser.phone_number);
    setEmail(selectedUser.email);
    setIsAdmin(selectedUser.isAdmin);
  }, [selectedUser, navigate]);

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      await axios.put(`/api/users/update/edit/${selectedUser._id}/`, {
        first_name,
        last_name,
        phone_number,
        email,
        is_staff: isAdmin,
      }, config);
      toast.success('User edited successfully');
      handleClose();
      fetchUsers();
      // Handle success or perform any additional actions
    } catch (err) {
      toast.error('Error while updating users');
    }
  };

  return (
    <Offcanvas
      scroll
      show={show}
      onHide={handleClose}
      backdrop
      placement="end"
      className="custom-offcanvas"
    >

      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <h3>
            Edit User
          </h3>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>

        <Form onSubmit={submitHandler}>
          <Form.Group controlId="firstname">
            <CustomLabel>First Name</CustomLabel>
            <CustomFormControl
              type="firstname"
              placeholder="Enter first name"
              value={first_name}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Group>
          <br />
          <Form.Group controlId="lastname">
            <CustomLabel>Last Name</CustomLabel>
            <CustomFormControl
              type="lastname"
              placeholder="Enter last name"
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Group>
          <br />
          <Form.Group controlId="phonenumber">
            <CustomLabel>Phone Number</CustomLabel>
            <CustomFormControl
              type="phonenumber"
              placeholder="Enter phone number"
              value={phone_number}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Form.Group>
          <br />
          <Form.Group controlId="email">
            <CustomLabel>Email Address</CustomLabel>
            <CustomFormControl
              type="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <br />

          <Form.Group controlId="isadmin">
            <Form.Check
              type="checkbox"
              disabled={selectedUser.isAdmin && selectedUser.phone_number === userInfo.phone_number}
              label="Is Admin"
              checked={isAdmin}
              onChange={(e) => setIsAdmin(e.target.checked)}
            />
          </Form.Group>
          <br />
          <div className="cart-footer-product">
            <CreateProductButton
              className="btn-block"
              type="submit"
              variant="outline-secondary"
            >
              Update
            </CreateProductButton>
          </div>
        </Form>

      </Offcanvas.Body>
    </Offcanvas>

  );
}

export default UserEditScreen;
