/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Form, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import FormContainer from '../components/FormContainer';
import { saveShippingAddress } from '../actions/cartActions';
import CheckoutSteps from '../components/CheckoutStep';
import Header from '../components/Header';
import { CreateProductButton, CustomFormControl, CustomLabel } from '../components/styled';
import { listLocations } from '../actions/locationAction';
import FooterBanner from '../components/FooterBanner';

function ShippingScreen() {
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState(shippingAddress.email);
  const [company, setCompany] = useState(shippingAddress.company);
  const [street_road, setStreetRoad] = useState(shippingAddress.street_road);
  const [building_apartment, setBuildingApartment] = useState(shippingAddress.building_apartment);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(saveShippingAddress({
      company, street_road, postalCode: 'test',
    }));
    navigate('/payment');
  };

  useEffect(() => {
    dispatch(listLocations());
  }, [dispatch]);

  return (
    <>
      <Header />
      <Container className="mb-5 mt-5">
        <FormContainer>
          <CheckoutSteps step1 step2 />
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="email">
              <CustomLabel>Email</CustomLabel>
              <CustomFormControl
                required
                type="email"
                placeholder="Enter email"
                value={email || ''}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <br />
            <h3>Shipping company</h3>
            <br />
            <Form.Group controlId="firstName">
              <CustomLabel>First Name</CustomLabel>
              <CustomFormControl
                className="w-100"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </Form.Group>
            <br />
            <Form.Group controlId="lastName">
              <CustomLabel>Last Name</CustomLabel>
              <CustomFormControl
                className="w-100"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </Form.Group>
            <br />
            <Form.Group controlId="company">
              <CustomLabel>Company</CustomLabel>
              <CustomFormControl
                required
                type="text"
                placeholder="Enter company"
                value={company || ''}
                onChange={(e) => setCompany(e.target.value)}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="street_road">
              <CustomLabel>Street/Road</CustomLabel>
              <CustomFormControl
                required
                type="text"
                placeholder="Enter street/road"
                value={street_road || ''}
                onChange={(e) => setStreetRoad(e.target.value)}
              />
            </Form.Group>
            <br />
            <Form.Group controlId="street_road">
              <CustomLabel>Building/Apartment</CustomLabel>
              <CustomFormControl
                required
                type="text"
                placeholder="Enter bulding/apartment"
                value={building_apartment || ''}
                onChange={(e) => setBuildingApartment(e.target.value)}
              />
            </Form.Group>
            <br />
            <CreateProductButton variant="outline-secondary" type="submit">
              Continue to payment
            </CreateProductButton>
          </Form>
        </FormContainer>
      </Container>
      <FooterBanner />
    </>
  );
}

export default ShippingScreen;
