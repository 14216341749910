/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Button, Alert, Pagination, Container, Col, Form, Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/Loader';
import { deleteUser } from '../actions/userActions';
import UserEditScreen from './UserEditScreen';
import { CustomFormControl, CustomLabel } from '../components/styled';
import axios from '../utils/axios';

function UserListScreen() {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
  });

  const [show, setShow] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleClose = () => setShow(false);

  const handleShow = (user) => {
    setSelectedUser(user);
    setShow(true);
  };

  const fetchUsers = () => {
    setLoading(true);
    axios
      .get('/api/users/', {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      })
      .then((response) => {
        setUsers(response.data);
        setLoading(false);
      })
      .catch((er) => {
        console.error(er);
        setError('Failed to fetch users');
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      axios
        .delete(`/api/users/${id}/`, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        })
        .then(() => {
          dispatch(deleteUser(id));
          fetchUsers();
        })
        .catch((e) => {
          console.error(e);
          setError('Failed to delete user');
        });
    }
  };

  const itemsPerPage = 10; // Number of items to display per page
  const totalItems = !loading && users ? users.length : 1; // Total number of items

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // Perform any data fetching or updating based on the selected page
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    // Perform any data fetching or updating based on the filters
  };

  const renderTableRows = () => {
    // Replace with your own data fetching logic
    const data = users || []; // Array of your data

    const filteredData = data.filter(
      (user) => user.first_name.toLowerCase().includes(filters.first_name.toLowerCase())
      && user.last_name.toLowerCase().includes(filters.last_name.toLowerCase())
      && user.email.toLowerCase().includes(filters.email.toLowerCase())
      && user.phone_number.toLowerCase().includes(filters.phone_number.toLowerCase()),
    );

    // Filter and slice the data based on the current page
    const paginatedData = filteredData.slice(startIndex, endIndex);

    // eslint-disable-next-line no-unused-vars
    return paginatedData.map((user) => (
      <tr key={user._id}>
        <td className="text-center text-muted">{user.customer_id}</td>
        <td className="text-center">
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left mr-3">
                <div className="widget-content-left">
                  <img
                    width="40"
                    loading="lazy"
                    className="rounded-circle"
                    src="assets/images/avatars/4.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div className="widget-content-left flex2">
                <div className="widget-heading">{user.first_name}</div>
                <div className="widget-subheading opacity-7">
                  {user.last_name}
                </div>
              </div>
            </div>
          </div>
        </td>
        <td className="text-center">{user.email}</td>
        <td className="text-center">
          <div className="badge badge-warning">{user.phone_number}</div>
        </td>
        <td className="text-center">
          <div className="badge badge-warning">
            {user.lifetime_value}
          </div>
        </td>
        <td className="text-center">
          {user.isAdmin ? (
            <i className="fa-regular fa-circle-check" style={{ color: 'green' }} />
          ) : (
            <i className="fa-regular fa-circle-xmark" style={{ color: 'red' }} />
          )}
        </td>
        <td className="text-center">
          <Button onClick={() => handleShow(user)} variant="light" className="btn-sm">
            <i className="fas fa-edit" />
          </Button>
          {' '}
          <Button
            disabled={user.isAdmin && user.phone_number === userInfo.phone_number}
            variant="danger"
            className="btn-sm"
            onClick={() => deleteHandler(user._id)}
          >
            <i className="fas fa-trash" />
          </Button>
        </td>
      </tr>
    ));
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div className="main-card mb-3 card">
              <div className="card-header">
                Customers
                <div className="btn-actions-pane-right">
                  <div role="group" className="btn-group-sm btn-group">
                    {/* <Button
                      // onClick={handleWeightAddModalShow}
                      className="active btn btn-focus"
                    >
                      <i className="fas fa-plus" />
                      &nbsp;
                      Create Users
                    </Button> */}
                    {/* {' '}
                    <Button className="btn btn-focus">All time</Button> */}
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <div className="filters">
                  <Container>
                    <Row className="mt-0 pb-3 row-1 mb-0 px-sm-3 px-2">
                      <Col>
                        <Form.Group controlId="firstNameFilter">
                          <CustomLabel>First name</CustomLabel>
                          <CustomFormControl
                            type="text"
                            name="first_name"
                            value={filters.first_name}
                            onChange={handleFilterChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="lastNameFilter">
                          <CustomLabel>Last name</CustomLabel>
                          <CustomFormControl
                            type="text"
                            name="last_name"
                            value={filters.last_name}
                            onChange={handleFilterChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="emailFilter">
                          <CustomLabel>Email</CustomLabel>
                          <CustomFormControl
                            type="email"
                            name="email"
                            value={filters.email}
                            onChange={handleFilterChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="phoneNumberFilter">
                          <CustomLabel>Phone number</CustomLabel>
                          {/* <PhoneInput
                            regions={['america', 'europe', 'asia', 'oceania', 'africa']}
                            className="tel-phone"
                            value={filters.phone_number}
                            country="ke"
                            required
                            onChange={handleFilterChange}
                          /> */}
                          <CustomFormControl
                            type="text"
                            name="phone_number"
                            value={filters.phone_number}
                            onChange={handleFilterChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </div>
                <table className="align-middle mb-0 table table-borderless table-striped table-hover">
                  <thead>
                    <tr>
                      <th className="text-center">Customer ID</th>
                      <th className="text-center">Name</th>
                      <th className="text-center">Email</th>
                      <th className="text-center">Phone number</th>
                      <th className="text-center">Lifetime value</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderTableRows()}
                  </tbody>
                </table>
              </div>
              <div className="d-block text-center card-footer">
                <Pagination
                  size="lg"
                  className="custom-pagination"
                  style={{ float: 'right' }}
                >
                  <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                  {Array.from({ length: Math.ceil(totalItems / itemsPerPage) }, (_, index) => (
                    <Pagination.Item
                      key={index + 1}
                      active={currentPage === index + 1}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
                  />
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      )}
      {show && (
        <UserEditScreen
          selectedUser={selectedUser}
          show={show}
          handleClose={() => handleClose()}
          fetchUsers={fetchUsers}
        />
      )}
    </div>
  );
}

export default UserListScreen;
