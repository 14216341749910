/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import moment from 'moment';
import CategoryModal from './categoryModal';
import axios from '../utils/axios';

function CategoryListScreen() {
  const [categoryVisible, setCategoryModalShow] = useState(false);
  const [selectedCategory, setCategoryDetails] = useState(null);
  const [categories, setCategories] = useState([]);

  const userLogin = useSelector((state) => state.userLogin);
  // eslint-disable-next-line no-unused-vars
  const { userInfo } = userLogin;

  const fetchCategories = async () => {
    try {
      const response = await axios.get('/api/categories/', {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      setCategories(response.data);
    } catch (error) {
      toast.error('Error while fetching categories');
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const deleteHandler = async (id) => {
    const confirmDelete = (window.confirm('Are you sure you want to delete this category?'));
    if (confirmDelete) {
      try {
        await axios.delete(`/api/categories/${id}/`, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        toast.success('Category deleted successfully');
        fetchCategories();
      } catch (err) {
        toast.error('Error while deleting Category');
      }
    }
  };

  const handleCategoryModalClose = () => setCategoryModalShow(false);
  const handleCategoryModalShow = (cat) => {
    setCategoryModalShow(true);
    setCategoryDetails(cat);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="main-card mb-3 card">
            <div className="card-header">
              Categories
              <div className="btn-actions-pane-right">
                <div role="group" className="btn-group-sm btn-group">
                  <Button
                    className="active btn btn-focus"
                    onClick={() => handleCategoryModalShow('')}
                  >
                    <i className="fas fa-plus" />
                    &nbsp;
                    Create Category
                  </Button>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="align-middle mb-0 table table-borderless table-striped table-hover">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th className="text-center">Date Created</th>
                    <th className="text-center">Name</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>

                <tbody>
                  {categories.map((category) => (
                    <tr key={category._id}>
                      <td className="text-center text-muted">{category.id}</td>
                      <td className="text-center text-muted">{category && moment(category?.createdAt).format('LLLL')}</td>
                      <td className="text-center">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3">
                              <div className="widget-content-left">
                                <img
                                  width="40"
                                  loading="lazy"
                                  className="rounded-circle"
                                  src="assets/images/avatars/4.jpg"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="widget-content-left flex2">
                              <div className="widget-heading">{category.name}</div>
                              {/* <div className="widget-subheading opacity-7">
                              {item.price}</div> */}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <Button
                          onClick={() => handleCategoryModalShow(category)}
                          variant="light"
                          className="btn-sm"
                        >
                          <i className="fas fa-edit" />
                        </Button>
                        <Button variant="danger" className="btn-sm" onClick={() => deleteHandler(category.id)}>
                          <i className="fas fa-trash" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="d-block text-center card-footer">
              {/* <button className="mr-2 btn-icon btn-icon-only btn btn-outline-danger">
                  <i className="pe-7s-trash btn-icon-wrapper"> </i></button>
                  <button className="btn-wide btn btn-success">Save</button> */}
            </div>
          </div>
        </div>
      </div>

      {categoryVisible && (
      <CategoryModal
        show={categoryVisible}
        onHide={handleCategoryModalClose}
        category={selectedCategory}
        fetchCategories={fetchCategories}
      />
      )}

    </div>
  );
}

export default CategoryListScreen;
