export const WEIGHT_LIST_SUCCESS = 'WEIGHT_LIST_SUCCESS';
export const WEIGHT_LIST_REQUEST = 'WEIGHT_LIST_REQUEST';
export const WEIGHT_LIST_FAIL = 'WEIGHT_LIST_FAIL';

export const WEIGHT_LIST_TOTAL_SUCCESS = 'WEIGHT_LIST_TOTAL_SUCCESS';
export const WEIGHT_LIST_TOTAL_REQUEST = 'WEIGHT_LIST_TOTAL_REQUEST';
export const WEIGHT_LIST_TOTAL_FAIL = 'WEIGHT_LIST_FAIL';

export const WEIGHT_DETAILS_SUCCESS = 'WEIGHT_DETAILS_SUCCESS';
export const WEIGHT_DETAILS_REQUEST = 'WEIGHT_DETAILS_REQUEST';
export const WEIGHT_DETAILS_FAIL = 'WEIGHT_DETAILS_FAIL';
export const WEIGHT_DETAILS_RESET = 'WEIGHT_DETAILS_RESET';

export const WEIGHT_DELETE_REQUEST = 'WEIGHT_DELETE_REQUEST';
export const WEIGHT_DELETE_SUCCESS = 'WEIGHT_DELETE_SUCCESS';
export const WEIGHT_DELETE_FAIL = 'WEIGHT_DELETE_FAIL';

export const WEIGHT_UPDATE_REQUEST = 'WEIGHT_UPDATE_REQUEST';
export const WEIGHT_UPDATE_SUCCESS = 'WEIGHT_UPDATE_SUCCESS';
export const WEIGHT_UPDATE_FAIL = 'WEIGHT_UPDATE_FAIL';
export const WEIGHT_UPDATE_PROFILE_RESET = 'WEIGHT_UPDATE_PROFILE_RESET';
export const WEIGHT_UPDATE_RESET = 'WEIGHT_UPDATE_RESET';

export const WEIGHT_CREATE_REQUEST = 'WEIGHT_CREATE_REQUEST';
export const WEIGHT_CREATE_SUCCESS = 'WEIGHT_CREATE_SUCCESS';
export const WEIGHT_CREATE_FAIL = 'WEIGHT_CREATE_FAIL';
export const WEIGHT_CREATE_PROFILE_RESET = 'WEIGHT_CREATE_PROFILE_RESET';
export const WEIGHT_CREATE_RESET = 'WEIGHT_CREATE_RESET';
