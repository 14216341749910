import React, { useState } from 'react';
import {
  Badge, ButtonGroup, Card, Form,
} from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { AddToCartButton, CustomSelect, CustomSpan } from './styled';
import { numberWithCommas, truncateText } from '../utils/utlilities';

function CakeAddToCart({ product }) {
  const [selectedValue, setSelectedValue] = useState('');
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/product/${product.id}`);
  };

  const shouldDisplayWeights = product && product?.weights?.length > 0;
  const shouldDisplayCakeQuantity = product && product?.cake_quantity_price > 0;

  return (
    <Card
      className="add-to-cart-card course-card flex-grow-1"
      onClick={handleClick}
    >
      <div className="clickable-card-content d-flex flex-column">
        <Card.Img
          src={`${product.image1}`}
          alt={product.name}
          className="img-fluid img-phone-fluid  small-image"
          loading="lazy"
        />
        {product.discount !== null && product.promotion_start_date !== null && (
          <Badge
            pill
            bg="warning"
            className="discount-badge"
          >
            {product.discount}
          </Badge>
        )}
        <Card.Body>
          <Card.Title className="title">
            <h5 className="product-name">{truncateText(product.name, 15)}</h5>
          </Card.Title>
          <Form>
            <Form.Group className="mb-3">
              {shouldDisplayWeights && (
                <div>
                  <CustomSelect
                    className="w-100"
                    style={{ minWidth: '100%' }}
                    value={selectedValue}
                    onChange={(event) => setSelectedValue(event.target.value)}
                  >
                    {product.weights.map((option) => (
                      <option key={option.id} value={JSON.stringify(option)}>
                        {option.cake_weight}
                      </option>
                    ))}
                  </CustomSelect>
                </div>
              )}
            </Form.Group>
          </Form>
          <Card.Text className="card-text">
            {shouldDisplayWeights
            && (
              <CustomSpan>
                <strong>
                  From &nbsp;Ksh &nbsp;
                  {numberWithCommas(Number(product?.weights[0]?.price)) ?? ''}
                  /-
                </strong>
              </CustomSpan>
            )}

            {shouldDisplayCakeQuantity && (
              <CustomSpan>
                <strong>
                  From &nbsp;Ksh &nbsp;
                  {numberWithCommas(Number(product?.cake_quantity_price)) ?? ''}
                  {' '}
                  per cake
                  /-
                </strong>
              </CustomSpan>
            )}
          </Card.Text>
          <ButtonGroup>
            <AddToCartButton
              variant="secondary"
              type="submit"
              size="sm"
            >
              Add to Cart
            </AddToCartButton>
          </ButtonGroup>
        </Card.Body>
      </div>
    </Card>
  );
}

export default CakeAddToCart;
