/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import {
  Button, Form, Container, Row, Col,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from '../utils/axios';
import Loader from '../components/Loader';
import { CustomFormControl, CustomLabel } from '../components/styled';

function LoginScreen() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [contactMethod, setContactMethod] = useState('email');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  const loginCustomer = async () => {
    setLoading(true);
    try {
      const { data } = await axios.patch(
        '/api/users/login-otp/',
        { phone_number: phoneNumber, email, contact_type: contactMethod },
        config,
      );
      setLoading(false);
      localStorage.setItem('userDetails', JSON.stringify(data));

      // if (email && (data.isAdmin || data.is_student)) {
      //   navigate(`/login_password_otp/${email}`, { state: data });
      // } else
      if (email) {
        navigate(`/otp/${email}`, { state: data });
      } else {
        navigate(`/otp/${phoneNumber ?? email}`, { state: data });
      }
    } catch (err) {
      toast.error('Invalid credentials');
      setLoading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    loginCustomer();
  };

  const handleContactMethodChange = (event) => {
    const selectedContactMethod = event.target.value;
    setContactMethod(selectedContactMethod);

    // Clear the corresponding input value when contact method changes
    if (selectedContactMethod === 'email') {
      setPhoneNumber('');
    } else if (selectedContactMethod === 'phone') {
      setEmail('');
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <Container style={{ maxWidth: '500px' }}>
      <Row>
        <Col>
          <h2>Login</h2>
          {loading && <Loader />}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="contactMethod">
              <CustomLabel>How would you like to sign in?</CustomLabel>
              <br />
              <div className="d-flex flex-column">
                <Form.Check
                  inline
                  type="radio"
                  label="Email"
                  value="email"
                  checked={contactMethod === 'email'}
                  onChange={handleContactMethodChange}
                />
                <Form.Check
                  type="radio"
                  inline
                  label="Phone"
                  value="phone"
                  checked={contactMethod === 'phone'}
                  onChange={handleContactMethodChange}
                />
              </div>
            </Form.Group>

            {contactMethod === 'email' && (
              <Form.Group controlId="email">
                <CustomLabel>Email address</CustomLabel>
                <CustomFormControl
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={handleEmailChange}
                />
              </Form.Group>
            )}
            {contactMethod === 'phone' && (
              <Form.Group className="mb-3" controlId="phoneNumber">
                <CustomLabel>Phone number</CustomLabel>
                <PhoneInput
                  regions={['america', 'europe', 'asia', 'oceania', 'africa']}
                  className="tel-phone"
                  value={phoneNumber}
                  country="ke"
                  required
                  onChange={(phone) => setPhoneNumber(`+${phone}`)}
                />
              </Form.Group>
            )}
            <br />
            <div className="d-grid gap-2">
              <Button
                variant="outline-light"
                style={{ backgroundColor: '#FFC87D', color: '#002D33' }}
                type="submit"
              >
                Sign In
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default LoginScreen;
