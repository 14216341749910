/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Footer from '../Footer';
import Header from '../Header';
import { AddToCartCard, CreateProductButton } from '../styled';
import { listCurriculum } from '../../actions/curriculumnAction';
import { numberWithCommas } from '../../utils/utlilities';

function CurriculumScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const curriculumList = useSelector((state) => state.curriculumList);
  const { curriculums } = curriculumList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productDelete = useSelector((state) => state.productDelete);
  const { success: successDelete } = productDelete;

  useEffect(() => {
    dispatch(listCurriculum());
  }, [dispatch, userInfo, successDelete]);

  return (
    <>
      <Header />

      <Container className="mb-5 page-content-wrapper">
        <Row className="mb-5">
          <Col xs={12} sm={12} md={8} lg={8}>
            <h1 className="mb-2">Our Classes</h1>
            <span className="mb-5">
              Our courses are thoughtfully designed to cater to all levels of expertise.
              Whether you're a beginner looking to master the basics or an experienced
              baker aiming to refine your skills, our comprehensive curriculum has something for everyone:
              <br />
              <br />
              The good thing about learning with us, once you register we provide every
              ingredient and all other equipment and tools that you will be using in class.
              We also provide aprons, headgear and ingredient notebooks which you can use even
              after you’re done with learning with us. The icing on it all is that all baked
              goods a student does, you get to carry them home.

            </span>
          </Col>
        </Row>
        <Row className="mb-5">
          {curriculums.map((curriculum) => (
            <Col className="mb-3" key={curriculum.id} xs={6} sm={6} md={4}>
              <AddToCartCard>
                <AddToCartCard.Text>
                  <h4 className="truncate-text">{curriculum.title}</h4>
                </AddToCartCard.Text>
                <AddToCartCard.Img
                  style={{ display: 'block', width: '100%' }}
                  variant="top"
                  src={`${curriculum.image}`}
                />
                <CreateProductButton
                  onClick={() => navigate(`/academy/curriculum/${curriculum.id}`)}
                  variant="secondary"
                  block
                >
                  Ksh
                  {' '}
                  {numberWithCommas(curriculum.price)}
                  {' '}
                  <i className="fa-solid fa-caret-right" />
                </CreateProductButton>
              </AddToCartCard>
            </Col>
          ))}
        </Row>

      </Container>
      <Footer />
    </>
  );
}

export default CurriculumScreen;
