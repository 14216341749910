/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Form, Button, Modal, Alert,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CustomFormControl, CustomLabel } from '../components/styled';
import axios from '../utils/axios';

function ToppingAddScreen({
  ToppingVisible, handleToppingModalClose,
  handleToppingModalOpen, fetchToppings,
}) {
  const [toppingName, setToppingName] = useState('');
  const [toppingPrice, setToppingPrice] = useState('');
  const [error, setError] = useState('');

  const addTopping = async () => {
    try {
      await axios.post('/api/toppings/', {
        name: toppingName,
        price: toppingPrice,
      });

      // Reset form fields
      setToppingName('');
      setToppingPrice('');
      setError('');
      fetchToppings();
      handleToppingModalClose();

      toast.success('Topping added successfully');
    } catch (err) {
      handleToppingModalOpen();
      setError('Failed to add topping.');
      toast.error('Failed to add topping');
    }
  };

  return (
    <Form onSubmit={addTopping}>
      <Modal show={ToppingVisible} onHide={handleToppingModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Add Topping</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form.Group controlId="toppingName">
            <CustomLabel>Name</CustomLabel>
            <CustomFormControl
              type="text"
              required
              value={toppingName}
              onChange={(e) => setToppingName(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="toppingPrice">
            <CustomLabel>Price</CustomLabel>
            <CustomFormControl
              type="text"
              required
              value={toppingPrice}
              onChange={(e) => setToppingPrice(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleToppingModalClose}>
            Close
          </Button>
          <Button onClick={addTopping} type="submit" variant="primary">
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}

export default ToppingAddScreen;
