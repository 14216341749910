/* eslint-disable import/prefer-default-export */
import {
  WEIGHT_CREATE_FAIL,
  WEIGHT_CREATE_REQUEST,
  WEIGHT_CREATE_SUCCESS,
  WEIGHT_DELETE_FAIL,
  WEIGHT_DELETE_REQUEST,
  WEIGHT_DELETE_SUCCESS,
  WEIGHT_DETAILS_FAIL,
  WEIGHT_DETAILS_REQUEST,
  WEIGHT_DETAILS_SUCCESS,
  WEIGHT_LIST_FAIL,
  WEIGHT_LIST_REQUEST,
  WEIGHT_LIST_SUCCESS,
  WEIGHT_LIST_TOTAL_FAIL,
  WEIGHT_LIST_TOTAL_REQUEST,
  WEIGHT_LIST_TOTAL_SUCCESS,
  WEIGHT_UPDATE_FAIL,
  WEIGHT_UPDATE_REQUEST,
  WEIGHT_UPDATE_SUCCESS,
} from '../constants/weightConstants';
import axios from '../utils/axios';

export const listWeights = () => async (dispatch, getState) => {
  try {
    dispatch({ type: WEIGHT_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get('/api/weights/', config);

    dispatch({
      type: WEIGHT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WEIGHT_LIST_FAIL,
      payload: error.response && error.response.data.detail
        ? error.response.data.detail : error.message,

    });
  }
};

export const listTotalWeight = () => async (dispatch, getState) => {
  try {
    dispatch({ type: WEIGHT_LIST_TOTAL_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get('/api/weights/total/', config);

    dispatch({
      type: WEIGHT_LIST_TOTAL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WEIGHT_LIST_TOTAL_FAIL,
      payload: error.response && error.response.data.detail
        ? error.response.data.detail : error.message,
    });
  }
};

export const deleteWeight = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: WEIGHT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `/api/weights/delete/${id}/`,
      config,
    );

    dispatch({
      type: WEIGHT_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WEIGHT_DELETE_FAIL,
      payload: error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message,
    });
  }
};

export const updateWeight = (weight) => async (dispatch, getState) => {
  try {
    dispatch({
      type: WEIGHT_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/weights/update/${weight.id}/`,
      weight,
      config,
    );

    dispatch({
      type: WEIGHT_UPDATE_SUCCESS,
    });

    dispatch({
      type: WEIGHT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WEIGHT_UPDATE_FAIL,
      payload: error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message,
    });
  }
};

export const createWeight = (weight) => async (dispatch, getState) => {
  try {
    dispatch({
      type: WEIGHT_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      '/api/weights/create/',
      weight,
      config,
    );

    dispatch({
      type: WEIGHT_CREATE_SUCCESS,
    });

    dispatch({
      type: WEIGHT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WEIGHT_CREATE_FAIL,
      payload: error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message,
    });
  }
};

export const getWeightDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: WEIGHT_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `/api/weights/${id}/`,
      config,
    );

    dispatch({
      type: WEIGHT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WEIGHT_DETAILS_FAIL,
      payload: error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message,
    });
  }
};
