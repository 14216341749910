/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Image1 from '../assets/images/carousel1.png';
import Image2 from '../assets/images/carousel2.png';
// import Image3 from '../assets/images/carousel3.png';
import Image4 from '../assets/images/carousel4.png';
// import Image5 from '../assets/images/carousel5.png';
// import Image6 from '../assets/images/carousel6.png';
// import Image7 from '../assets/images/carousel7.png';
import { CarouselButton, CarouselText } from './styled';

function ControlledCarousel() {
  const [index, setIndex] = useState(0);
  const [isMobileView, setIsMobileView] = useState(false);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderCarouselIcons = () => {
    if (isMobileView) {
      return null; // Hide the carousel icons for mobile view
    }

    return {
      prevIcon: (
        <CarouselButton
          size="lg"
          style={{ borderColor: '#fff', borderWidth: '4px', backgroundColor: '#007080' }}
          className="rounded-circle"
          onClick={() => handleSelect(index - 1)}
        >
          <i className="fa-solid fa-caret-left" />
        </CarouselButton>
      ),
      nextIcon: (
        <CarouselButton
          size="lg"
          style={{ borderColor: '#fff', borderWidth: '4px', backgroundColor: '#007080' }}
          className="rounded-circle"
          onClick={() => handleSelect(index + 1)}
        >
          <i className="fa-solid fa-caret-right" />
        </CarouselButton>
      ),
    };
  };

  // Array of carousel images
  const carouselImages = [Image1, Image2, Image4];

  // Generate a random index for the carousel
  const randomIndex = Math.floor(Math.random() * carouselImages.length);

  const preloadImages = () => {
    carouselImages.forEach((image) => {
      new Image().src = image;
    });
  };

  useEffect(() => {
    preloadImages();
  }, []);

  return (
    <Carousel
      variant="dark"
      fade
      indicators={false}
      activeIndex={index}
      onSelect={handleSelect}
      // style={{ height: !isMobileView ? '100vh' : '' }}
      {...(!isMobileView && renderCarouselIcons())}
    >
      <Carousel.Item>
        <LazyLoadImage
          alt="Slide 1"
          className="d-block w-100"
          src={Image1}
          loading="lazy"
          effect="blur"
          style={{ objectFit: 'cover' }}
        />
        <Carousel.Caption className={isMobileView ? 'text-start' : 'text-center'}>
          <CarouselText style={{ fontSize: isMobileView ? '1.2rem' : '' }}>
            {randomIndex === 0 ? 'Make Memorable Moments' : 'Make Memorable Moments'}
          </CarouselText>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <LazyLoadImage
          alt="Slide 2"
          className="d-block w-100"
          src={Image2}
          loading="lazy"
          effect="blur"
          style={{ objectFit: 'cover' }}
        />
        <Carousel.Caption className={isMobileView ? 'text-start' : 'text-center'}>
          <CarouselText style={{ fontSize: isMobileView ? '1.2rem' : '' }}>
            {randomIndex === 1 ? 'Make Memorable Moments' : 'Make Memorable Moments'}
          </CarouselText>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <LazyLoadImage
          alt="Slide 3"
          className="d-block w-100"
          src={Image4}
          loading="lazy"
          effect="blur"
          style={{ objectFit: 'cover' }}
        />
        <Carousel.Caption className={isMobileView ? 'text-start' : 'text-center'}>
          <CarouselText style={{ fontSize: isMobileView ? '1.2rem' : '' }}>
            {randomIndex === 2 ? 'Make Memorable Moments' : 'Make Memorable Moments'}
          </CarouselText>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>

  );
}

export default ControlledCarousel;
