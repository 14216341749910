/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useNavigate } from 'react-router';
import { CustomSpan, ProductDescriptionCard } from './styled';
import { numberWithCommas, truncateText } from '../utils/utlilities';

function ProductDetailsCard({ product }) {
  const navigate = useNavigate();

  const shouldDisplayWeights = product && product?.weights?.length > 0;
  const shouldDisplayCakeQuantity = product && product?.cake_quantity_price;

  return (
    <ProductDescriptionCard
      className="flex-grow-1"
      onClick={() => navigate(`/product/${product.id}`)}
    >
      {' '}
      <div className="clickable-card-content d-flex flex-column">
        <ProductDescriptionCard.Img
          variant="top"
          fluid
          src={`${product.image1}`}
          alt={product.name}
          style={{ display: 'block', width: '100%' }}
          loading="lazy"
        />
        <ProductDescriptionCard.Body>
          <h5>{product.name}</h5>
          <CustomSpan>
            {truncateText(product.description, 80)}
          </CustomSpan>
          <br />
          {shouldDisplayWeights
            && (
              <CustomSpan>
                <strong>
                  From &nbsp;Ksh &nbsp;
                  {numberWithCommas(Number(product?.weights[0]?.price)) ?? ''}
                  /-
                </strong>
              </CustomSpan>
            )}

          {shouldDisplayCakeQuantity && (
          <CustomSpan>
            <strong>
              From &nbsp;Ksh &nbsp;
              {numberWithCommas(Number(product?.cake_quantity_price)) ?? ''}
              {' '}
              per cake
              /-
            </strong>
          </CustomSpan>
          )}
        </ProductDescriptionCard.Body>
      </div>
    </ProductDescriptionCard>
  );
}

export default ProductDetailsCard;
