/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Alert, Image } from 'react-bootstrap';
import axios from '../utils/axios';
import Loader from '../components/Loader';
import cakeImage1 from '../assets/images/Page 1_3.png';

function OrderCompleteConfirmationPage() {
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderTrackingId = searchParams.get('OrderTrackingId');

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const initiateOrderPaymentAfterStatus = async (trackingID) => {
    setLoading(true);

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const completeOrderId = localStorage.getItem('completeOrderId');

    // Step 1: Validate completeOrderId
    if (!completeOrderId) {
      // Handle the case when completeOrderId is missing or not set in localStorage
      setLoading(false);
      setError('Complete Order ID not found');
      return; // Exit the function early since the order ID is not valid
    }

    try {
      const { data } = await axios.put(
        '/api/orders/order/complete/payment_status/',
        {
          orderTrackingId: trackingID,
          id: completeOrderId,
        },
        config,
      );
      navigate('/profile');
      localStorage.removeItem('completeOrderId');
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err.response.data.error);
    }
  };

  // Function to check transaction status
  const checkTransactionStatus = async () => {
    initiateOrderPaymentAfterStatus(orderTrackingId);
  };

  useEffect(() => {
    checkTransactionStatus();
  }, [orderTrackingId]);

  return (
    <div>
      {paymentStatus === 'success' && (
      <Alert variant="success">
        Payment successful! Your order has been placed.
      </Alert>
      )}
      {paymentStatus === 'failed' && (
        <Alert variant="danger">
          Payment failed. Please try again or choose an alternative payment method.
        </Alert>
      )}
      { error && <Alert variant="danger">{error}</Alert>}
      { loading && <Loader />}
      <div className="not-found-template">
        {/* <h4>
              {' '}
              {searchQuery}
              {' '}
              not found.
            </h4> */}
        <Image
          loading="lazy"
          src={cakeImage1}
          alt="404 Not Found"
          fluid
        />
        <h3>Please Do not refresh this page as we redirect you to profile page</h3>
        {/* You can add a nice design and additional information here */}
      </div>
    </div>
  );
}

export default OrderCompleteConfirmationPage;
