/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router';
import Header from '../components/Header';
import Loader from '../components/Loader';

function PesapalPaymentScreen() {
  const { state } = useLocation();
  const navigate = useNavigate();

  // Check if the state is empty
  if (!state) {
    // Redirect to the home page
    navigate('/');
    return null; // Return null to prevent rendering anything else
  }

  return (
    <>
      <Header />
      <Container>
        {!state && <Loader />}
        <div>
          <iframe
            id="pesapal-iframe"
            src={state}
            width="100%"
            height="600"
            scrolling="no"
            frameBorder="0"
            allowTransparency="true"
          />
        </div>
      </Container>
    </>
  );
}

export default PesapalPaymentScreen;
