/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Button, Row, Col, Stack, Pagination, Form, Container,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import { deleteProduct } from '../actions/productActions';
import CreateProductScreen from './CreateProductScreen';
import ProductDetailsCanvas from './ViewProductDetailsScreen';
import ProductEditDetailsCanvas from './productEditAdminScreen';
import { CustomFormControl, CustomLabel } from '../components/styled';
import axios from '../utils/axios';

function ProductListScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [show, setShow] = useState(false);
  const [showEditProduct, setEditProductShow] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [showProduct, setProductShow] = useState(false);
  const [filters, setFilters] = useState({
    name: '',
    description: '',
    category: '',
  });

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleProductClose = () => setProductShow(false);
  const handleEditProductClose = () => setEditProductShow(false);

  const handleSelectedtProduct = (product) => {
    setProductShow(true);
    setSelectedProduct(product);
  };

  const handleEditSelectedtProduct = (product) => {
    setEditProductShow(true);
    setSelectedProduct(product);
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get('/api/products/', {
        params: {
          page: 1,
          page_size: 100,
          ordering: '-createdAt',
        },
        headers: {
          Authorization: `Bearer ${userInfo.token}`, // Add the token as a bearer token
        },
      });

      setProducts(data?.results);
      setLoading(false);
    } catch (error) {
      // Throw the error to v
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const deleteHandler = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this product?');
    if (confirmDelete) {
      try {
        await axios.delete(`/api/products/${id}/`, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        toast.success('Product deleted successfully');
        fetchProducts();
      } catch (err) {
        toast.error('Error while deleting product');
      }
    }
  };

  const itemsPerPage = 10; // Number of items to display per page
  const totalItems = products.length; // Total number of items

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // Perform any data fetching or updating based on the selected page
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    // Perform any data fetching or updating based on the filters
  };

  const renderTableRows = () => {
    // Replace with your own data fetching logic
    const data = products || []; // Array of your data

    const filteredData = data.filter((item) => (
      item.name.toLowerCase().includes(filters.name.toLowerCase())
        && item.description && item.description.toLowerCase()
        .includes(filters.description.toLowerCase())
        && item.category && item.category.name.toLowerCase()
        .includes(filters.category.toLowerCase())
    ));

    // Filter and slice the data based on the current page
    const paginatedData = filteredData.slice(startIndex, endIndex);

    return paginatedData.map((item, index) => (
      <tr key={item.id}>
        <td className="text-center text-muted">{item.id}</td>
        <td className="text-center">
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left mr-3">
                <div className="widget-content-left">
                  <img loading="lazy" width="40" className="rounded-circle" src="assets/images/avatars/4.jpg" alt="" />
                </div>
              </div>
              <div className="widget-content-left flex2">
                <div className="widget-heading">{item.name}</div>
              </div>
            </div>
          </div>
        </td>
        <td className="text-center">{item.description}</td>
        <td className="text-center">
          <div className="badge badge-warning">
            {item && item.category && item.category.name}
          </div>
        </td>
        <td className="text-center">
          <Stack direction="horizontal" gap={3}>
            <Button
              variant="light"
              onClick={() => handleEditSelectedtProduct(item)}
              className="btn-sm"
            >
              <i className="fas fa-edit" />
            </Button>

            <Button variant="danger" className="btn-sm" onClick={() => deleteHandler(item.id)}>
              <i className="fas fa-trash" />
            </Button>
            {' '}
            <Button
              variant="info"
              className="btn-sm"
              onClick={() => handleSelectedtProduct(item)}
            >
              view Product
            </Button>

          </Stack>
        </td>
      </tr>
    ));
  };

  return (
    <>
      {loading
        ? (<Loader />)
        : (
          <div className="row">
            <div className="col-md-12">
              <div className="main-card mb-3 card">
                <div className="card-header">
                  Products
                  <div className="btn-actions-pane-right">
                    <div role="group" className="btn-group-sm btn-group">
                      <Button
                        className="active btn btn-focus"
                          // onClick={() => navigate('/shop/admin/product/create')}
                        onClick={() => handleShow()}
                      >
                        <i className="fas fa-plus" />
                        &nbsp;
                        Create Product
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <div className="filters">
                    <Container>
                      <Row className="mt-0 pb-3 row-1 mb-0 px-sm-3 px-2">
                        <Col>
                          <Form.Group controlId="nameFilter">
                            <CustomLabel>Name</CustomLabel>
                            <CustomFormControl
                              type="text"
                              name="name"
                              value={filters.name}
                              onChange={handleFilterChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group controlId="descriptionFilter">
                            <CustomLabel>Description</CustomLabel>
                            <CustomFormControl
                              type="text"
                              name="description"
                              value={filters.description}
                              onChange={handleFilterChange}
                            />
                          </Form.Group>

                        </Col>
                        <Col>
                          <Form.Group controlId="categoryFilter">
                            <CustomLabel>Category</CustomLabel>
                            <CustomFormControl
                              type="text"
                              name="category"
                              value={filters.category}
                              onChange={handleFilterChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  <table className="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th className="text-center">Name</th>
                        <th className="text-center">Description</th>
                        <th className="text-center">Category</th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      {renderTableRows()}
                    </tbody>
                  </table>
                </div>
                <div className="d-block text-center card-footer">
                  <Pagination
                    size="lg"
                    className="custom-pagination"
                    style={{ float: 'right' }}
                  >
                    <Pagination.Prev
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    />
                    {Array.from({ length: Math.ceil(totalItems / itemsPerPage) }, (_, index) => (
                      <Pagination.Item
                        key={index + 1}
                        active={currentPage === index + 1}
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
                    />
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        )}
      {show && (
      <CreateProductScreen
        show={show}
        handleClose={() => handleClose()}
        handleShow={() => handleShow()}
        fetchProducts={fetchProducts}
      />
      )}
      {showProduct && (
      <ProductDetailsCanvas
        show={showProduct}
        selectedProduct={selectedProduct}
        handleClose={() => handleProductClose()}
      />
      )}
      {showEditProduct && (
      <ProductEditDetailsCanvas
        show={showEditProduct}
        selectedProduct={selectedProduct}
        handleClose={() => handleEditProductClose()}
        fetchProducts={fetchProducts}
      />
      )}

    </>
  );
}

export default ProductListScreen;
