/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from '../utils/axios';
import ToppingEditScreen from './EditToppingScreen';
import ToppingAddScreen from './AddTopping';
import { numberWithCommas } from '../utils/utlilities';

function ToppingsListScreen() {
  const [availableToppings, setAvailableToppings] = useState([]);
  const [selectedTopping, setSelectedTopping] = useState({});
  const [editToppingVisible, setEditToppingVisible] = useState(false);

  const [ToppingVisible, setToppingVisible] = useState(false);

  const fetchToppings = async () => {
    try {
      const response = await axios.get('/api/toppings/');
      setAvailableToppings(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchToppings();
  }, []);

  const deleteTopping = async (toppingId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this topping?');
    if (confirmDelete) {
      try {
        await axios.delete(`/api/toppings/${toppingId}/`);
        const updatedToppings = availableToppings.filter((topping) => topping.id !== toppingId);
        setAvailableToppings(updatedToppings);
        toast.success('Topping deleted successfully');
      } catch (error) {
        toast.error('Error while deleting topping');
      }
    }
  };

  const handleEditToppingModalClose = () => setEditToppingVisible(false);
  const handleEditToppingModalOpen = (data) => {
    setSelectedTopping(data);
    setEditToppingVisible(true);
  };

  const handleToppingModalClose = () => setToppingVisible(false);
  const handleToppingModalOpen = () => setToppingVisible(true);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="main-card mb-3 card">
            <div className="card-header">
              Toppings
              <div className="btn-actions-pane-right">
                <div role="group" className="btn-group-sm btn-group">
                  <Button
                    onClick={() => handleToppingModalOpen()}
                    className="active btn btn-focus"
                  >
                    <i className="fas fa-plus" />
                    &nbsp;
                    Create Topping
                  </Button>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="align-middle mb-0 table table-borderless table-striped table-hover">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th className="text-center">Name</th>
                    <th className="text-center">Price</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {availableToppings.map((item) => (
                    <tr key={item.id}>
                      <td className="text-center text-muted">{item.id}</td>
                      <td className="text-center">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3">
                              <div className="widget-content-left">
                                <img width="40" className="rounded-circle" src="assets/images/avatars/4.jpg" alt="" />
                              </div>
                            </div>
                            <div className="widget-content-left flex2">
                              <div className="widget-heading">{item.name}</div>
                              {/* <div className="widget-subheading opacity-7">
                    {item.price}</div> */}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="badge badge-warning">{numberWithCommas(item.price)}</div>
                      </td>
                      <td className="text-center">
                        <Button
                          onClick={() => handleEditToppingModalOpen(item)}
                          variant="light"
                          className="btn-sm"
                        >
                          <i className="fas fa-edit" />
                        </Button>

                        <Button variant="danger" className="btn-sm" onClick={() => deleteTopping(item.id)}>
                          <i className="fas fa-trash" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="d-block text-center card-footer">
              {/* <button className="mr-2 btn-icon btn-icon-only btn btn-outline-danger">
        <i className="pe-7s-trash btn-icon-wrapper"> </i></button>
        <button className="btn-wide btn btn-success">Save</button> */}
            </div>
          </div>
        </div>
      </div>

      {selectedTopping && (
      <ToppingEditScreen
        editToppingVisible={editToppingVisible}
        selectedTopping={selectedTopping}
        handleEditToppingModalClose={handleEditToppingModalClose}
        handleEditToppingModalOpen={handleEditToppingModalOpen}
        fetchToppings={fetchToppings}
      />
      )}

      {ToppingVisible && (
      <ToppingAddScreen
        ToppingVisible={ToppingVisible}
        handleToppingModalClose={handleToppingModalClose}
        handleToppingModalOpen={handleToppingModalOpen}
        fetchToppings={fetchToppings}
      />
      )}

    </>
  );
}

export default ToppingsListScreen;
