/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import axios from '../utils/axios';
import { CustomFormControl, CustomLabel } from '../components/styled';

function CategoryModal({
  show, onHide, category, fetchCategories,
}) {
  const [name, setName] = useState(category ? category.name : '');

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      name: name.trim(),
    };
    try {
      if (category) {
        const response = await axios.put(`/api/categories/${category.id}/`, formData, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        toast.success('Category updated successfully');
        fetchCategories();
        onHide();
      } else {
        // Perform create
        const response = await axios.post('/api/categories/', formData, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        toast.success('Category created successfully');
        fetchCategories();
        onHide();
      }
    } catch (error) {
      toast.error('An error occurred');
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>
            {category ? 'Edit Category' : 'Add Category'}
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>
          <Form.Group controlId="formCategoryName">
            <CustomLabel>Category Name</CustomLabel>
            <CustomFormControl
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>
          <br />
          <Button variant="primary" type="submit">
            {category ? 'Update' : 'Add'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default CategoryModal;
