/* eslint-disable no-lonely-if */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import { Form, Stack, Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Countdown from 'react-countdown';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import Header from '../components/Header';
import Footer from '../components/Footer';
import axios from '../utils/axios';
import { CreateProductButton, CustomFormControl as Custom } from '../components/styled';

const CustomFormControl = styled(Custom)`
  height: 48px;
  font-size: 2rem !important; /* Increase the font size as desired */
  color: #007080 !important; /* Change the color to the desired color value */
  /* Add any additional custom styles here */
  border-color: ${(props) => (props.expired ? 'red' : '#ced4da')};
`;

function Completionist() {
  return <div />;
}

function OtpVerification() {
  const userInformation = JSON.parse(localStorage.getItem('userDetails'));
  const [otp, setOtp] = useState(Array(4).fill(''));
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [expiredOTP, setExpiredOTP] = useState(false);
  const [expirationTime, setExpirationTime] = useState(userInformation.expiration_time);
  const inputs = useRef([]);

  const navigate = useNavigate();
  const { contact } = useParams();

  const userLogin = useSelector((user) => user.userLogin);

  const { userInfo } = userLogin;

  const handleInputChange = (event, index) => {
    const { value } = event.target;
    const newOtp = [...otp];
    newOtp[index] = value;
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(value)) {
      // If entered value is not a number, do not update the OTP state
      return;
    }
    setOtp(newOtp);
    if (value) {
      inputs.current[index + 1].focus();
    }
  };

  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  const resendOTP = async () => {
    setLoading(true);
    try {
      let resendData = {};
      if (/^\S+@\S+\.\S+$/.test(contact)) {
        // Contact is an email
        resendData = { email: contact };
      } else if (/^\+?\d{9,15}$/.test(contact)) {
        // Contact is a phone number
        resendData = { phone_number: contact };
      } else {
        setError('Invalid contact format');
        setLoading(false);
        return;
      }

      const { data } = await axios.patch('/api/users/login-otp/', resendData, config);
      localStorage.setItem('userDetails', JSON.stringify(data));
      setExpiredOTP(true);
      setExpirationTime(data.expiration_time);
      setLoading(false);
      setMessage('OTP sent successfully');
      window.location.reload();
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    resendOTP();
  };

  useEffect(() => {
    if (userInfo) {
      navigate('/');
    }
  }, [userInfo, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (otp.some((digit) => digit === '')) {
      toast.error('Please fill in all OTP fields');
    } else {
      const enteredOtp = otp.join('');
      let verifyData = {};
      if (/^\S+@\S+\.\S+$/.test(contact)) {
        // Contact is an email
        verifyData = { email: contact, otp: enteredOtp };
      } else if (/^\+?\d{9,15}$/.test(contact)) {
        // Contact is a phone number
        verifyData = { phone_number: contact, otp: enteredOtp };
      } else {
        toast.error('Invalid contact format');
        return;
      }

      try {
        const response = await axios.post('/api/users/verify-otp/', verifyData);
        const { data } = response;

        localStorage.setItem('userInfo', JSON.stringify(data));
        window.location.reload('/');
      } catch (er) {
        toast.error('Invalid OTP');
      }
    }
  };

  // Renderer callback with condition
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      if (!expiredOTP) {
        setExpiredOTP(true); // Reset expiredOTP state when countdown completes
        setOtp(Array(4).fill('')); // Reset OTP input fields
        setMessage(''); // Clear any previous success message
        setError(''); // Clear any previous error message
      }
      return <Completionist />;
    }
    // Render a countdown
    return (
      <span>
        {minutes}
        :
        {seconds}
        {' '}
        S
      </span>
    );
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('Text').trim();
    if (pasteData.length === 4 && /^\d+$/.test(pasteData)) {
      const newOtp = pasteData.split('').slice(0, 4);
      setOtp(newOtp);
    }
  };

  return (
    <>
      <Header />

      <div className="center-div page-content-wrapper">
        <Form noValidate onSubmit={handleSubmit}>
          <div className="col-md-5 mx-auto" style={{ margin: '100px 300px 400px 300px' }}>
            {error && toast.error(error)}
            {message && toast.success(message)}
            {loading && <Loader />}
            <h1 style={{ margin: 'auto', width: '100%' }}>Customer verification</h1>
            <h6 style={{ margin: 'auto' }}>
              Enter the One Time Password (OTP) sent to &nbsp;
              {contact}
            </h6>
            <br />
            <Container onPaste={handlePaste}>
              <div className="d-flex justify-content-center">
                {otp.map((digit, index) => (
                  <CustomFormControl
                    key={index}
                    required
                    ref={(input) => (inputs.current[index] = input)}
                    className={`mx-2 ${expiredOTP ? 'expired' : ''}`}
                    style={{
                      height: '48px',
                      borderColor: expiredOTP ? 'red' : '#ced4da',
                    }}
                    type="text"
                    disabled={expiredOTP}
                    maxLength="1"
                    value={digit}
                    onChange={(event) => handleInputChange(event, index)}
                  />
                ))}
              </div>
            </Container>
            <br />
            <h6>
              <strong>
                &nbsp;&nbsp;
                {!expiredOTP && <i className={`fa-solid fa-hourglass ${!expiredOTP ? 'fa-rotate' : ''}`} />}
                &nbsp;&nbsp;
                <Countdown
                  date={new Date(expirationTime)}
                  renderer={renderer}
                />
              </strong>
            </h6>
            <Stack gap={2}>
              {!expiredOTP
            && (
            <>
              <CreateProductButton disabled={expiredOTP} variant="outline-dark" type="submit">
                Verify
              </CreateProductButton>
              <br />
            </>
            )}
              {expiredOTP
              && (

              <>
                <h6 style={{ margin: 'auto' }}>Didn’t get a One Time Password (OTP)?</h6>

                <CreateProductButton
                  variant="outline-secondary"
                  onClick={submitHandler}
                  disabled={!expiredOTP}
                >
                  Resend OTP
                </CreateProductButton>
              </>
              )}
            </Stack>
          </div>
        </Form>

      </div>

      <Footer />
    </>
  );
}

export default OtpVerification;
