import React from 'react';
import styled from 'styled-components';
import Autocomplete from './CustomAutoComplete';

const SearchInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

function SearchMapInput({
  options,
  onSelect,
  onSearch,
  disabled,
  itemLabel,
  placeholder,
}) {
  return (
    <SearchInputContainer>
      <p>{itemLabel}</p>
      <Autocomplete
        placeholder={placeholder}
        options={options}
        onSearch={onSearch}
        onSelect={onSelect}
        disabled={disabled}
        // className="search-bar-styling"
        // dropdownClassName="search-dropdown"
      />
    </SearchInputContainer>
  );
}

export default SearchMapInput;
