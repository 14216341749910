import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HomeScreen from './screens/HomeScreen';
import AuthenticationScreen from './screens/AuthenticationScreen';
import OtpVerification from './screens/otpVerificationScreen';
import CartScreen from './screens/CartScreen';
// import AdminScreen from './screens/AdminScreen';
import UserEditScreen from './screens/UserEditScreen';
import WeightEditScreen from './screens/WeightEditScreen';
import CategoryEditScreen from './screens/CategoryEditScreen';
import ProfileScreen from './screens/ProfileScreen';
import EmailPasswordScreen from './screens/PasswordEmailScreen';
import NotFoundScreen from './screens/NotFoundScreen';
import AllLocationScreen from './screens/Location/AllLocationScreen';
import SingleLocationScreen from './screens/Location/SingleLocationScreen';
import SingleProductScreen from './screens/SingleProductScreen';
import CourseScreen from './screens/student/CoursesScreen';
import AdminHome from './screens/Admin/AdminEccomerce';
import ShippingScreen from './screens/ShippingScreen';
import PaymentScreen from './screens/PaymentScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import OrderScreen from './screens/OrderScreen';
import ShopPage from './components/CakeCategoryFilter';
import EnrollmentForm from './screens/enrollment';
import CurriculumScreen from './components/curriculum/curriculumScreen';
import OrderPaymentScreen from './components/curriculum/OrderPaymentScreen';
import AdminUnusedHome from './components/unusedAdmin';
import AdminAcademyHome from './screens/Admin/AdminAcademy';
import SubscribersPage from './screens/Admin/Subscribers';
import PesapalPaymentScreen from './screens/PesapalPaymentScreen';
import OrderCreationConfirmationPage from './screens/orderCreationConfirmationPage';
import CourseCreationConfirmationPage from './components/CourseConfirmationPage';
import FaqPage from './screens/FAQPage';
import WhatsAppButton from './components/whatsapp/whatsapp';
import HowDidThisAllBegun from './screens/HowDidThisAllBegun';
import SingleOrder from './screens/SingleOrder';
import UserEnrollments from './screens/UserEnrolledCourses';
import CompletePesapalPayment from './screens/CompletePesapalPayment';
import OrderCompleteConfirmationPage from './screens/OrderCompletePage';

function App() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  return (
    <Router>
      <main>
        <Routes>
          <Route path="/" element={<HomeScreen />} exact />
          <Route path="/shop/cart" element={<CartScreen />} />
          <Route path="/academy/curriculum/:id" element={<CourseScreen />} />
          <Route path="/academy/curriculum" element={<CurriculumScreen />} />
          <Route path="/authenticate" element={<AuthenticationScreen />} />
          <Route path="/otp/:contact" element={<OtpVerification />} />
          <Route path="/login_password_otp/:email" element={<EmailPasswordScreen />} />
          <Route path="/locations" element={<AllLocationScreen />} />
          <Route path="/product/:id" element={<SingleProductScreen />} exact />
          <Route path="/locations/:id" element={<SingleLocationScreen />} />
          <Route path="/shipping" element={<ShippingScreen />} />
          <Route path="/test" element={<AdminUnusedHome />} />
          <Route path="/payment" element={<PaymentScreen />} />
          <Route path="/placeorder" element={<PlaceOrderScreen />} />
          <Route path="/order/:id" element={<OrderScreen />} />
          <Route path="/shop/" element={<ShopPage />} />
          <Route path="/orderpaymentScreen" element={<OrderPaymentScreen />} />
          <Route path="/academy/course/:curriculumId/:courseId/enroll" element={<EnrollmentForm />} />
          <Route path="/faqs" element={<FaqPage />} />
          <Route path="/howdiditbegin" element={<HowDidThisAllBegun />} />

          {userInfo && userInfo && (
            <>
              {userInfo.is_student && <Route path="/studentportal" element={<UserEnrollments />} />}
              <Route path="/orderCreation/page" element={<OrderCreationConfirmationPage />} />
              <Route path="/orderComplete/page" element={<OrderCompleteConfirmationPage />} />
              <Route path="/courseCheckout/page" element={<CourseCreationConfirmationPage />} />
              <Route path="/orders/:id" element={<SingleOrder />} />
              <Route path="/order/payement/pesapal/" element={<PesapalPaymentScreen />} />
              <Route path="/order/payement/pesapal/complete/" element={<CompletePesapalPayment />} />

              {userInfo.isAdmin && <Route path="/shop/admin/" element={<AdminHome />} />}
              {userInfo.isAdmin && <Route path="/shop/admin/courses" element={<AdminAcademyHome />} />}
              {userInfo.isAdmin && <Route path="/shop/admin/subscribers" element={<SubscribersPage />} />}
              <Route path="/profile" element={<ProfileScreen />} />
              {userInfo.isAdmin && <Route path="/shop/admin/user/:id/edit" element={<UserEditScreen />} />}
              {/* <Route path="/shop//admin/product/:id/edit" component={ProductEditScreen} /> */}
              {userInfo.isAdmin && <Route path="/shop/admin/weight/:id/edit" element={<WeightEditScreen />} />}
              {userInfo.isAdmin && <Route path="/shop/admin/category/:id/edit" element={<CategoryEditScreen />} />}
            </>
          )}
          <Route path="*" element={<NotFoundScreen />} />
        </Routes>
        <WhatsAppButton />
      </main>
    </Router>
  );
}

export default App;
