/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Form, Button, Modal, Alert,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CustomFormControl, CustomLabel } from '../components/styled';
import axios from '../utils/axios';

function ToppingEditScreen({
  editToppingVisible, selectedTopping, handleEditToppingModalClose,
  handleEditToppingModalOpen, fetchToppings,
}) {
  const [toppingName, setToppingName] = useState('');
  const [toppingPrice, setToppingPrice] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (selectedTopping) {
      setToppingName(selectedTopping.name);
      setToppingPrice(selectedTopping.price);
    }
  }, [selectedTopping]);

  const updateTopping = async () => {
    try {
      if (selectedTopping) {
        await axios.put(`/api/toppings/${selectedTopping.id}/`, {
          name: toppingName,
          price: toppingPrice,
        });

        // Reset form fields
        setToppingName('');
        setToppingPrice('');
        setError('');

        // Close the modal
        handleEditToppingModalClose();
        toast.success('Topping updated successfully');
        fetchToppings();
      }
    } catch (err) {
      handleEditToppingModalOpen();
      toast.error('Failed to update topping.');
    }
  };

  return (
    <Form onSubmit={updateTopping}>
      <Modal show={editToppingVisible} onHide={handleEditToppingModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Edit Topping</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form.Group controlId="toppingName">
            <CustomLabel>Name</CustomLabel>
            <CustomFormControl
              type="text"
              value={toppingName}
              onChange={(e) => setToppingName(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="toppingPrice">
            <CustomLabel>Price</CustomLabel>
            <CustomFormControl
              type="text"
              value={toppingPrice}
              onChange={(e) => setToppingPrice(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditToppingModalClose}>
            Close
          </Button>
          <Button onClick={updateTopping} type="submit" variant="primary">
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}

export default ToppingEditScreen;
