/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CakesCategory from '../components/cakesCategory';
import CardAcademy from '../components/CardAcademy';
import ProductCard from '../components/productCard';
import ImagesRow from '../components/bottomImages';
import HowItStarted from '../components/HowItStarted';
import Delivery from '../components/Delivery';
import Header from '../components/Header';
import ControlledCarousel from '../components/HomeCarousel';
import Footer from '../components/Footer';
import { listProducts } from '../actions/productActions';
import CategoryCart from '../components/CategoryCart';
import { listCategories } from '../actions/categoryAction';

function HomeScreen() {
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { products } = productList;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const productDelete = useSelector((state) => state.productDelete);
  const { success: successDelete } = productDelete;

  const categoriesList = useSelector((state) => state.categoriesList);
  const { categories } = categoriesList;

  useEffect(() => {
    dispatch(listCategories());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listProducts());
  }, [dispatch, userInfo, successDelete]);

  useEffect(() => {
    dispatch(listProducts());
  }, [dispatch]);

  return (
    <>
      <Header fixed="top" />
      <ControlledCarousel />

      <Container className="mt-5">

        <Delivery />
        {(categories || []).map((category) => {
          const lowercaseName = category.name.toLowerCase();

          if (lowercaseName.includes('occasion')) {
            return (
              <>
                <CakesCategory
                  key={category.id}
                  header="Our Occasion Cakes"
                  subHeader="Welcome to our occasion cakes section,
              where every slice tells a story of sweetness and celebration!"
                  description="Immerse yourself in the exquisite artistry of our bakery,
              where we craft delectable masterpieces that are the centerpiece of joyous
              occasions. With our unwavering passion for creating extraordinary event cakes,
              we bring your dreams
              to life and transform your special moments into delicious memories."
                />
                <CategoryCart key={category.id} category={category.name} />
              </>
            );
          } if (lowercaseName.includes('cup')) {
            return (
              <>
                <CakesCategory
                  key={category.id}
                  header="Our Cupcakes"
                  subHeader="Baked with love, eaten with love"
                  description="Our cupcakes are more than just desserts – they are miniature works of art that ignite the senses and bring smiles to faces.
              From the moment you lay your eyes on our beautifully decorated cupcakes, you know you're about to embark on a truly extraordinary treat. With our passion for creating divine treats, we invite you to experience a cupcake journey like no other,
              where each bite takes you on a delightful adventure."
                />
                <CategoryCart key={category.id} category={category.name} />
              </>
            );
          } if (lowercaseName.includes('mini')) {
            return (
              <>
                <CakesCategory
                  key={category.id}
                  header="Our Minicakes"
                  subHeader="Baked with love, eaten with love"
                  description="Discover a symphony of flavours as you bite into our soft,
              chewy mini cookies that melt in your mouth with a satisfying
              crunch of perfectly baked delights. From timeless classics to
              innovative twists, our cookie selection offers something for every palate, ensuring that each bite is a journey of unparalleled satisfaction."
                />
                <CategoryCart key={category.id} category={category.name} />
              </>
            );
          }
          return (
            <>
              <CakesCategory
                subHeader="Baked with love, eaten with love"
                header={`Our ${category.name}`}
              />
              <CategoryCart key={category.id} category={category.name} />

            </>
          );
        })}

        <Row className="row justify-content-center mb-2 pb-5">
          <CardAcademy />
        </Row>

        <HowItStarted />
      </Container>

      <ImagesRow />

      <Footer />
    </>
  );
}

export default HomeScreen;
