import {
  Button, Card, Container, Form, InputGroup, Row,
} from 'react-bootstrap';
import styled from 'styled-components';

export const ShopNowButton = styled(Button)`
    color: #002D33;
    margin-top: 10px;
    background-color: #FFC87D;
    border-radius: 8px;
    width: 318px;
    color: #002D33;
`;

export const ShopButton = styled.button`
    border: none;
    background-color: #FFC87D;
    width: 318px;
    height: 40px !important;
    margin-top: 10px;
    border-radius: 8px;
`;

export const SeeOurMenuButton = styled.button`
    background-color: #FFC87D;
    height: 40px !important;
    margin-top: 10px;
    border-radius: 8px;
    color: #002D33;
`;

export const LearnButton = styled.button`
    background-color: #FFC87D;
    height: 40px !important;
    margin-top: 10px;
    border-radius: 8px;
    color: #002D33;
`;

export const CreateProductButton = styled(Button)`
    color: #002D33;
    margin-top: 10px;
    background-color: #FFC87D;
`;

export const CustomFormControl = styled(Form.Control)`
    background-color: #CDF8EE;
    border: None !important
`;

export const CustomSelect = styled(Form.Select)`
  background-color: #CDF8EE;
  border: none;
  appearance: none; /* Remove default appearance */
  padding-right: 2rem; /* Add space for your custom dropdown icon */
  background-image: url('your-custom-icon.png'); /* Replace with your custom icon */
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 1.5rem; /* Adjust the size of your custom icon */
`;

export const CustomInpuTGroup = styled(InputGroup)`
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    text-align: center;
    white-space: nowrap;
    background-color: #CDF8EE;
    border: none;
    border-radius: 0.375rem;
`;

export const CustomLabel = styled(Form.Label)`
    color: #007080;
`;

export const DeleteUpload = styled(Button)`
    color: #804A3F;
    background-color: #FDD3CA;
    border: None;
`;

export const PriceCard = styled(Card)`
    box-sizing: border-box;
    width: 318px;
    height: 306px;
    left: 0px;
    top: 0px;
    border: 8px solid #007080;
    border-radius: 8px;
`;

export const CakeNameButton = styled.button`
    color: #007080;
    border: None;
    background-color: #CDF8EE;
    border-radius: 4px;
`;

export const CarouselButton = styled(Button)`
    background-color: '#007080'
`;

export const UserNavBarButton = styled(Button)`
    background-color: '#FFC87D'
    border: #007080;
    color: #002D33;
`;

export const AddToCartCard = styled(Card)`
    border: None !important;
    background-color: #FBFAF5;
`;

export const LocationCard = styled(Card)`
    border: none !important;
    background-color: #FBFAF5;
`;

export const CardLesson = styled(Card)`
    border: 1px #007080 !important;
    color: #007080;
`;

export const ProductDescriptionCard = styled(Card)`
    border: 'None';
    background-color: #FBFAF5;
`;

export const AcademyCard = styled(Card)` 
    border-radius: 8px;
    border: 'None';
`;

export const AcademyButton = styled(Button)`
    background: #FFC87D;
    color: #002D33;
    border-radius: 8px;
    border: 'None'
`;

export const AddToCartButton = styled(Button)`
    color: #002D33;
    background-color: #FFC87D;
`;

export const CarouselText = styled.h1`
    font-size: 75px;
    color: #fff;
`;

export const CustomGiftText = styled.h3`
    color: #312636;
`;

export const CustomSpan = styled.span`
    color: #007080;
`;

export const CustomGiftButton = styled(Button)`
    color: #002D33;
    background-color: #FFC87D;
    border: #000;
    right:0;
    bottom:0;
    text-align:'center;
`;

export const CustomRow = styled(Row)`
    margin: 0;
    padding: 0;
`;

export const CustomContainer = styled(Container)`
    --bs-gutter-x: 0 !important;
    --bs-gutter-y: 0 !important;
`;
