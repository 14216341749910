import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from 'react-toastify';
import axios from '../utils/axios';
import Loader from '../components/Loader';
import { CustomFormControl, CustomLabel } from '../components/styled';

function RegisterScreen() {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  const validatePhoneNumber = () => {
    // Add phone number validation logic here
    // Return true if the phone number is valid and the length is not greater than 15,
    // false otherwise Example validation: Check if the phone number is a valid E.164
    // formatted number and has a length less than or equal to 15
    const phoneNumberRegex = /^\+[1-9]\d{1,14}$/;
    return phoneNumber.match(phoneNumberRegex) !== null && phoneNumber.length <= 15;
  };

  const registerUser = async () => {
    setLoading(true);
    try {
      if (!validatePhoneNumber()) {
        toast.error('Please enter a valid phone number.');
        setLoading(false);
        return;
      }

      const { data } = await axios.post(
        '/api/users/register-otp/',
        {
          phone_number: phoneNumber,
          email,
          first_name: firstName,
          last_name: lastName,
        },
        config,
      );
      setLoading(false);
      localStorage.setItem('userDetails', JSON.stringify(data));
      navigate(`/otp/${phoneNumber}`);
    } catch (err) {
      toast.error(err.response.data.message);
      setLoading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    registerUser();
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="w-100">
        <h2>Create a new account</h2>
        {loading && <Loader />}

        <Form onSubmit={submitHandler}>
          <Form.Group className="mb-4" controlId="firstName">
            <CustomLabel>
              First name
              <span className="asterisk">*</span>
            </CustomLabel>
            <CustomFormControl
              type="text"
              required
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-4" controlId="lastName">
            <CustomLabel>
              Last name
              <span className="asterisk">*</span>
            </CustomLabel>
            <CustomFormControl
              type="text"
              required
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-4" controlId="email">
            <CustomLabel>
              Email address
              <span className="asterisk">*</span>
            </CustomLabel>
            <CustomFormControl
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-4" controlId="phoneNumber">
            <CustomLabel>
              Phone number
              <span className="asterisk">*</span>
            </CustomLabel>
            <PhoneInput
              regions={['america', 'europe', 'asia', 'oceania', 'africa']}
              className="tel-phone"
              value={phoneNumber}
              country="ke"
              required
              onChange={(phone) => setPhoneNumber(`+${phone}`)}
            />
            <p className="pt-4">We’ll send you a one-time password to verify your phone number.</p>
          </Form.Group>

          <div className="d-grid gap-2">
            <Button variant="outline-light" style={{ backgroundColor: '#FFC87D', color: '#002D33' }} type="submit">
              Register
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default RegisterScreen;
