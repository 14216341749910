/* eslint-disable camelcase */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable radix */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Card, Col, Image, ListGroup,
  Row, Form, Alert, Container, Carousel, Stack, Badge,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { listProductDetails, listProducts } from '../actions/productActions';
import Loader from '../components/Loader';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ProductDetailsCard from '../components/singleProductCard';
import {
  AddToCartButton, CustomFormControl, CustomLabel, CustomSpan, DeleteUpload,
} from '../components/styled';
import { addToCart } from '../actions/cartActions';
import CakeList from '../components/CakeWeightLIst';
import SingleProductBreadCrumb from '../components/breadcrumb/singleProductCrumb';
import { numberWithCommas } from '../utils/utlilities';

function SingleProductScreen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [image1, setImage1] = useState(null);
  const [preview1, setPreviewOne] = useState(false);
  const productDetails = useSelector((state) => state.productDetails);
  const { error, loading, product } = productDetails;
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedCakes, setSelectedCakes] = useState([]);

  const productList = useSelector((state) => state.productList);
  const { products } = productList;
  const [activeIndex, setActiveIndex] = useState(0);
  const [message, setMessage] = useState('');
  const [note, setNote] = useState('');
  const [cake_quantity, setCakeQuantity] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);
  };

  useEffect(() => {
    dispatch(listProductDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(listProducts());
  }, [dispatch]);

  const getTotalPrice = (qty) => {
    setTotalPrice(qty);
  };

  const handleTotalPriceChange = (price) => {
    setTotalPrice(price);
  };

  const handleCakeQuantityChange = (value) => {
    // Remove any non-digit characters from the input value
    const sanitizedValue = value.replace(/\D/g, '');

    // Ensure the value is not negative and does not contain a decimal point
    if (sanitizedValue >= 0 && !value.includes('.')) {
      const quantity = sanitizedValue !== '' ? parseInt(sanitizedValue) : 0;
      const price = parseFloat(product.cake_quantity_price);

      // Ensure the quantity doesn't exceed the max_quantity
      const maxQuantity = product?.max_quantity || Infinity;
      const validQuantity = Math.min(quantity, maxQuantity);

      if (quantity <= maxQuantity) {
        const total = validQuantity * price;

        setCakeQuantity(validQuantity.toString()); // Update with the valid quantity
        setTotalPrice(total);
      } else {
        toast.warn(`You cannot exceed maximum quantity: ${maxQuantity}`);
      }
    } else {
      setCakeQuantity('');
      setTotalPrice(0);
    }
  };

  // Assuming `product` is coming from the component's props or state

  const images = product && [product.image1, product.image2, product.image3]
    .filter((image) => image && image !== 'none' && !image.includes('placeholder.png'));

  const percentage = product && parseFloat(product.discount);
  const remainingPercentage = 100 - percentage;

  const hasPrice = product && product.weights && product.weights[0] && product.weights[0].price;
  const hasDiscount = product && product.discount !== null && product.discount !== '';

  // Calculate the discountPrice if available
  const discountPrice = hasPrice
    ? parseInt(totalPrice) * (parseInt(remainingPercentage) / 100)
    : null;

  const handleImage1Change = (e) => {
    setImage1(e.target.files[0]);
    setPreviewOne(true);
  };

  const removeImage1 = () => {
    setImage1(null);
    setPreviewOne(false);
  };

  const handleCakeChange = (cakes) => {
    setSelectedCakes(cakes);
  };

  function PreviewImage({ image }) {
    return (
      <div>
        <Image
          loading="lazy"
          src={URL.createObjectURL(image)}
          alt="image.name"
          height={60}
          width={60}
        />
      </div>
    );
  }

  const shouldDisplayWeights = product && product?.weights?.length > 0;
  const shouldDisplayCakeQuantity = product && product?.cake_quantity_price > 0;

  return (
    <>
      <Header />
      <Container className="page-content-wrapper">
        <SingleProductBreadCrumb product={product} />
        <br />
        {loading ? <Loader />
          : error ? <Alert variant="danger">{error}</Alert>

            : (
              <>
                <Row className="mb-5">
                  <Col md={6} lg={6} sm={6} xs={12}>
                    <Card className="bg-light text-white">
                      <Carousel
                        activeIndex={activeIndex}
                        fade
                        indicators={false}
                        prevIcon={(
                          <span className="carousel-control-prev-icon">
                            <i style={{ color: '#007080' }} className="fa-solid fa-arrow-left" />
                          </span>
      )}
                        nextIcon={(
                          <span className="carousel-control-next-icon">
                            <i style={{ color: '#007080' }} className="fa-solid fa-arrow-right" />
                          </span>
      )}
                        onSelect={handleSelect}
                        className="carousel-bg"
                      >
                        {images.map((image, index) => (
                          <Carousel.Item key={index} variant="dark">
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <img
                                className="d-block"
                                src={`${image}`}
                                alt={`Slide ${index}`}
                                style={{
                                  objectFit: 'contain',
                                  maxHeight: '300px', // Adjust the max height as needed
                                  maxWidth: '100%', // Adjust the max width as needed
                                }}
                              />
                              {product && product.discount !== null && (
                              <Badge
                                pill
                                bg="warning"
                                style={{
                                  position: 'absolute',
                                  top: '0.5rem',
                                  right: '0.5rem',
                                  backgroundColor: '#FFC87D',
                                  borderRadius: '50%',
                                  fontSize: '1.2rem',
                                  padding: '0.5rem',
                                  color: '#000',
                                }}
                              >
                                {product?.discount}
                              </Badge>
                              )}
                            </div>
                          </Carousel.Item>
                        ))}
                      </Carousel>
                      <Card.Body>
                        <Row>
                          {images.map((image, index) => (
                            <Col key={index} xs={3} md={3} className="mb-4">
                              <div className="card h-100 border-0">
                                <Image
                                  loading="lazy"
                                  className={`card-img-top ${activeIndex === index ? 'border border-primary' : ''}`}
                                  src={`${image}`}
                                  alt={`Slide ${index}`}
                                  fluid
                                  onClick={() => setActiveIndex(index)}
                                  style={{
                                    display: 'block',
                                  }}
                                />
                              </div>
                            </Col>
                          ))}
                        </Row>
                        <Card.Text>
                          <h4>Ingredients</h4>
                          <ul style={{ color: '#007080' }}>
                            <li>
                              <strong>Allergens:</strong>
                              {' '}
                              {product.allergens}
                            </li>
                            <li>
                              <strong>Ingredients:</strong>
                              {' '}
                              {product?.ingredients}
                            </li>
                            {product?.weights && product.weights.length > 0 && (
                              selectedCakes.length > 0 && (
                              <li>

                                <h6><strong>Serves:</strong></h6>
                                <ul>
                                  {selectedCakes.map((selectedCake) => (
                                    <li key={selectedCake.cake.id}>
                                      {`${selectedCake.cake.cake_weight}: ${selectedCake.cake.serves_per_weight}`}
                                    </li>
                                  ))}
                                </ul>
                              </li>
                              )
                            )}
                          </ul>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={6} lg={6} sm={12} xs={12}>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <h3>
                          {product?.name}
                        </h3>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <CustomSpan>
                          {product?.description}
                        </CustomSpan>
                      </ListGroup.Item>
                      <br />
                      <ListGroup.Item>
                        {shouldDisplayWeights && (
                          <>
                            <h6>Select cake size</h6>
                            <br />
                            <CakeList
                              onTotalPriceChange={handleTotalPriceChange}
                              min={product?.min_quantity}
                              max={product?.max_quantity}
                              cakes={product && (product?.weights || [])}
                              onCakeChange={handleCakeChange}
                            />

                          </>
                        ) }
                        {shouldDisplayCakeQuantity && (
                        <Form.Group className="mb-3" controlId="cakeQuantity">
                          <CustomLabel>
                            <h6>
                              Cake Quantity
                              (Price per cake
                              {' '}
                              {product?.cake_quantity_price}
                              )
                            </h6>

                          </CustomLabel>
                          <CustomFormControl
                            type="number"
                            value={cake_quantity}
                            onChange={(e) => handleCakeQuantityChange(e.target.value)}
                          />
                        </Form.Group>
                        )}
                      </ListGroup.Item>
                    </ListGroup>
                    {shouldDisplayWeights
                    && (
                    <>
                      <Form.Group className="mb-3" controlId="name">
                        <CustomLabel>Message on cake</CustomLabel>
                        <CustomFormControl
                          type="text"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                      </Form.Group>
                      <br />
                      <Form.Group className="mb-3" controlId="name">
                        <Row className="align-items-center">
                          <Col xs={12} md={4}>
                            {preview1 && <PreviewImage image={image1} thumbnail />}
                          </Col>
                          <Col xs={12} md={4} className="text-center">
                            <div className="form-group">
                              {image1 ? (
                                <Row className="align-items-center">
                                  <Col>
                                    <small className="text-muted">{image1.name}</small>
                                  </Col>
                                  <Col xs={4}>
                                    <DeleteUpload onClick={removeImage1} variant="danger">
                                      <i className="fa-solid fa-trash" />
                                      {' '}
                                      Delete
                                    </DeleteUpload>
                                  </Col>
                                </Row>
                              ) : (
                                <>
                                  <label htmlFor="image1" className="btn btn-outline-secondary">
                                    <i className="fa-regular fa-image" />
                                    {' '}
                                    Upload sample cake image
                                  </label>
                                  <input
                                    type="file"
                                    className="form-control-file"
                                    id="image1"
                                    accept="image/*"
                                    onChange={handleImage1Change}
                                    style={{ display: 'none' }}
                                  />
                                </>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Form.Group>
                    </>
                    )}
                    <br />
                    <Row>
                      <Col xs={6} md={4}>
                        <div>
                          {product && hasDiscount && (
                          <Badge
                            pill
                            bg="warning"
                            style={{
                              backgroundColor: '#FFC87D',
                              padding: '0.5rem',
                              color: '#000',
                            }}
                          >
                            {product.discount}
                            {' '}
                            off
                          </Badge>
                          )}
                        </div>
                      </Col>
                      <Col xs={6} md={4}>
                        <div className="ms-auto text-end">
                          {product && hasDiscount && (
                          <small>
                            <span style={{ textDecoration: 'line-through' }}>
                              Ksh
                              {' '}
                              {numberWithCommas(totalPrice)}
                            </span>
                          </small>
                          )}
                          <h3 style={{ lineHeight: '1.5' }}>
                            {hasPrice ? (
                              <>
                                Ksh
                                {' '}
                                {hasDiscount
                                  ? numberWithCommas(discountPrice) : numberWithCommas(totalPrice)}
                              </>
                            ) : (
                              shouldDisplayCakeQuantity ? (
                                <>
                                  Ksh
                                  {' '}
                                  {numberWithCommas(totalPrice)}
                                </>
                              ) : (
                                'Price not available'
                              )
                            )}
                          </h3>
                        </div>
                      </Col>

                      <Col xs={12} md={4}>
                        <AddToCartButton
                          onClick={() => dispatch(addToCart({
                            ...product,
                            totalPrice: hasPrice
                              ? hasDiscount
                                ? discountPrice
                                : totalPrice
                              : null,
                          // ...(other properties)
                          }))}
                          variant="outline-secondary"
                          disabled={totalPrice === 0}
                          className="w-100"
                        >
                          Add to cart
                        </AddToCartButton>
                      </Col>
                    </Row>

                  </Col>
                </Row>
                {products?.length > 1 && <h3>Try our other flavours</h3>}
                <br />
                <Row className="mt-0 pt-0 row-1 mb-2 px-sm-3 px-2">
                  {
                (products.slice(0, 4) || []).map((pro) => (
                  pro.id !== product.id && (
                    <Col
                      className="mt-2 row-1 mb-3 px-sm-3 px-2"
                      xs={12}
                      sm={6}
                      md={3}
                    >
                      <div className="px-2 d-flex flex-column h-100">
                        <ProductDetailsCard
                          product={pro}
                        />

                      </div>
                    </Col>
                  )
                ))
}
                </Row>
              </>
            )}

      </Container>
      <Footer />
    </>
  );
}

export default SingleProductScreen;
