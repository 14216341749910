/* eslint-disable react/prop-types */
import React from 'react';
import { Col, Row } from 'react-bootstrap';

function CakesCategory({ header, subHeader, description }) {
  return (
    <Row>
      <Col xs={12} sm={12} md={6} lg={6}>
        <h3>{header}</h3>
        <h5>{subHeader}</h5>
        <p>{description}</p>
      </Col>
      <Col xs={12} sm={12} md={6} lg={6} />
    </Row>
  );
}
export default CakesCategory;
