/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import {
  Alert, Button, Col, Container, Form, Image, ListGroup, Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  useParams, useSearchParams, Link, useNavigate,
} from 'react-router-dom';
import { removeFromCart } from '../actions/cartActions';
import Header from '../components/Header';
import { BASE_URL } from '../utils/appconstants';

function CartScreen() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const navigate = useNavigate();
  const qty = searchParams.get('qty');
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const removeFromCartHandler = (itemId) => {
    dispatch(removeFromCart(itemId));
  };

  const checkoutHandler = () => {
    navigate('/shipping');
  };

  return (
    <>
      <Header />
      <Container>
        <Row>
          <Col md={8}>
            <h1>Shopping Cart</h1>
            {cartItems.length === 0 ? (
              <Alert variant="info">
                Your Cart is empty
                {' '}
                <Link to="/">Go Back</Link>
              </Alert>
            ) : (
              <ListGroup variant="flush">
                {cartItems.map((item, i) => (
                  <ListGroup.Item key={i}>
                    <Row>
                      <Col md={2}>
                        <Image
                          loading="lazy"
                          src={`${item.image1}`}
                          alt={item.name}
                          fluid
                          rounded
                        />
                      </Col>
                      <Col md={3}>
                        <Link to={`/product/${item.product}`}>
                          <h5>{item.name}</h5>
                        </Link>
                      </Col>
                      <Col md={2}>
                        Ksh
                        {item.price}
                      </Col>
                      <Col md={2}>
                        <Button
                          type="button"
                          variant="danger"
                          onClick={() => removeFromCartHandler(item.product)}
                        >
                          <i className="fas fa-trash" />
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </Col>
          <Col md={4}>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>
                  Subtotal&nbsp;
                  {cartItems.length}
&nbsp;items
                </h2>
                Ksh &nbsp;
                {cartItems.reduce((acc, item) => acc + item.price, 0).toFixed(2)}
              </ListGroup.Item>
              <ListGroup.Item>
                <Button
                  type="button"
                  className="btn-block"
                  disabled={cartItems.length === 0}
                  onClick={checkoutHandler}
                >
                  Proceed to Checkout
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CartScreen;
