/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useNavigate } from 'react-router';

function SingleLocationBreadCrumb({ location }) {
  const { name = '' } = location;
  const navigate = useNavigate();
  return (
    location && (
    <Breadcrumb className="breadcrumb-styling">
      <Breadcrumb.Item className="breadcrumb-link">
        <span
          onClick={() => navigate('/locations')}
          className="breadcrumb-item-text"
        >
          Locations
        </span>
      </Breadcrumb.Item>

      <Breadcrumb.Item active>
        <span className="breadcrumb-item-text">{name}</span>
      </Breadcrumb.Item>
    </Breadcrumb>
    )
  );
}

export default SingleLocationBreadCrumb;
