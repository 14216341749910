/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/style-prop-object */
/* eslint-disable no-script-url */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
// import WeightTable from '../../components/WeightTable';
import {
  Button,
  Card, Col, Form, Image, Modal, ProgressBar, Row, Tab, Table,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import cakeImage from '../../assets/images/Page 1_3.png';
import FooterBanner from '../../components/FooterBanner';
import AdminEccomerceTable from '../AdminScreen';
import { listTotalWeight } from '../../actions/weightAction';
import { listTotalCategory } from '../../actions/categoryAction';
import { listTotalLocations } from '../../actions/locationAction';
import { listTotalProduct } from '../../actions/productActions';
import { listTotalUsers, logout } from '../../actions/userActions';
import { listTotalOrder } from '../../actions/orderActions';
import axios from '../../utils/axios';
import { CreateProductButton, CustomFormControl } from '../../components/styled';

function SubscribersPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [subscribers, setSubscribers] = useState([]);
  const [newEmail, setNewEmail] = useState('');
  const [showModal, setShowModal] = useState(false);

  const fetchSubscribers = async () => {
    try {
      const response = await axios.get('/api/users/subscribers/');
      setSubscribers(response.data);
    } catch (error) {
      toast.error('Failed to fetch subscribers:', error);
    }
  };

  useEffect(() => {
    fetchSubscribers();
  }, []);

  const handleCreateSubscriber = async () => {
    try {
      await axios.post('/api/users/subscribers/', { email: newEmail });
      setSubscribers([...subscribers, { email: newEmail }]);
      setNewEmail('');
      setShowModal(false);
      toast.success('Subscriber added successfully');
    } catch (error) {
      toast.error('Failed to create subscriber:', error);
    }
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listTotalWeight());
      dispatch(listTotalCategory());
      dispatch(listTotalLocations());
      dispatch(listTotalProduct());
      dispatch(listTotalUsers());
      dispatch(listTotalOrder());
    } else {
      navigate('/login');
    }
  }, [dispatch, navigate, userInfo]);

  const logoutHandler = () => {
    dispatch(logout());
    navigate('/');
  };

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
      <div className="app-header header-shadow">
        <div className="app-header__logo">
          {/* <div className="logo-src" /> */}
          <div className="header__pane ml-auto">
            <div>
              <button type="button" className="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="app-header__mobile-menu">
          <div>
            <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
          </div>
        </div>
        <div className="app-header__menu">
          <span>
            <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
              <span className="btn-icon-wrapper">
                <i className="fa fa-ellipsis-v fa-w-6" />
              </span>
            </button>
          </span>
        </div>
        {' '}
        <div className="app-header__content">
          <div className="app-header-left">
            <div className="search-wrapper">
              <div className="input-holder">
                <input type="text" className="search-input" placeholder="Type to search" />
                <button className="search-icon"><span /></button>
              </div>
              <button className="close" />
            </div>
          </div>
          <div className="app-header-right">
            <div className="header-btn-lg pr-0">
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left">
                    <div className="btn-group">
                      <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="p-0 btn">
                        <img
                          width="42"
                          loading="lazy"
                          className="rounded-circle"
                          src="assets/images/avatars/1.jpg"
                          alt=""
                        />
                        <i className="fa fa-angle-down ml-2 opacity-8" />
                      </a>
                      <div tabIndex="-1" role="menu" aria-hidden="true" className="dropdown-menu dropdown-menu-right">
                        <button type="button" tabIndex="0" onClick={() => navigate('/')} className="dropdown-item">View Site</button>
                        {/* <button type="button" tabIndex="0"
                        className="dropdown-item">Settings</button> */}
                        {/* <h6 tabIndex="-1" className="dropdown-header">Header</h6> */}
                        {/* <button type="button" tabIndex="0" className="dropdown-item">
                        Actions</button> */}
                        <div tabIndex="-1" className="dropdown-divider" />
                        <button type="button" tabIndex="0" onClick={logoutHandler} className="dropdown-item">Logout</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="app-main">
        <div className="app-sidebar sidebar-shadow">
          <div className="app-header__logo">
            <div className="logo-src" />
            <div className="header__pane ml-auto">
              <div>
                <button type="button" className="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="app-header__mobile-menu">
            <div>
              <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </div>
          </div>
          <div className="app-header__menu">
            <span>
              <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                <span className="btn-icon-wrapper">
                  <i className="fa fa-ellipsis-v fa-w-6" />
                </span>
              </button>
            </span>
          </div>
          {' '}
          <div className="scrollbar-sidebar">
            <div className="app-sidebar__inner">
              <ul className="vertical-nav-menu">
                <li className="app-sidebar__heading">Shop Dashboard</li>
                <li>
                  <a href="index.html" className="mm-active">
                    <i className="metismenu-icon pe-7s-rocket" />
                    <Link to="/shop/admin">
                      View Shop
                    </Link>
                  </a>
                </li>
                <li className="app-sidebar__heading">Academy Dashboard</li>
                <li>
                  <a href="index.html" className="mm-active">
                    <i className="metismenu-icon pe-7s-rocket" />
                    <Link to="/shop/admin/courses">
                      View Academy
                    </Link>
                  </a>
                </li>
                <li className="app-sidebar__heading">Subscribers Dashboard</li>
                <li>
                  <a href="index.html" className="mm-active">
                    <i className="metismenu-icon pe-7s-rocket" />
                    <Link to="/shop/admin/subscribers">
                      View Subscribers
                    </Link>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <div className="page-title-icon">
                    <Image loading="lazy" src={cakeImage} width={40} height={40} />
                  </div>
                  <div>
                    Cakes & cakes Subscribers
                  </div>
                </div>
                <div className="page-title-actions">
                  <div className="d-inline-block dropdown">
                    <div tabIndex="-1" role="menu" aria-hidden="true" className="dropdown-menu dropdown-menu-right" />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="main-card mb-3 card">
                  <div className="card-header">
                    Instructors
                    <div className="btn-actions-pane-right">
                      <div role="group" className="btn-group-sm btn-group">
                        <Button className="active btn btn-focus" onClick={() => setShowModal(true)}>
                          <i className="fas fa-plus" />
                          {' '}
                          Add Subscriber
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="align-middle mb-0 table table-borderless table-striped table-hover">
                      <thead>
                        <tr>
                          <th className="text-center">Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subscribers.map((subscriber) => (
                          <tr key={subscriber?._id}>
                            <td className="text-center">
                              {subscriber?.email}
                            </td>

                          </tr>
                        ))}
                      </tbody>

                    </table>
                  </div>
                  <div className="d-block text-center card-footer">
                    {/* <button className="mr-2 btn-icon btn-icon-only btn btn-outline-danger">
                  <i className="pe-7s-trash btn-icon-wrapper"> </i></button>
                  <button className="btn-wide btn btn-success">Save</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Add Subscriber</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <CustomFormControl
                    type="email"
                    placeholder="Enter email"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Cancel
              </Button>
              <Button type="submit" variant="primary" onClick={handleCreateSubscriber}>
                Add
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default SubscribersPage;
