/* eslint-disable default-param-last */
import {
  CURRICULUM_LIST_REQUEST,
  CURRICULUM_LIST_SUCCESS,
  CURRICULUM_LIST_FAIL,

  CURRICULUM_DETAILS_REQUEST,
  CURRICULUM_DETAILS_SUCCESS,
  CURRICULUM_DETAILS_FAIL,

  CURRICULUM_DELETE_REQUEST,
  CURRICULUM_DELETE_SUCCESS,
  CURRICULUM_DELETE_FAIL,

  CURRICULUM_CREATE_REQUEST,
  CURRICULUM_CREATE_SUCCESS,
  CURRICULUM_CREATE_FAIL,
  CURRICULUM_CREATE_RESET,

  CURRICULUM_UPDATE_REQUEST,
  CURRICULUM_UPDATE_SUCCESS,
  CURRICULUM_UPDATE_FAIL,
  CURRICULUM_UPDATE_RESET,

  CURRICULUM_CREATE_REVIEW_REQUEST,
  CURRICULUM_CREATE_REVIEW_SUCCESS,
  CURRICULUM_CREATE_REVIEW_FAIL,
  CURRICULUM_CREATE_REVIEW_RESET,

} from '../constants/curriculumConstants';

export const curriculumListReducer = (state = { curriculums: [] }, action) => {
  switch (action.type) {
    case CURRICULUM_LIST_REQUEST:
      return { loading: true, curriculums: [] };

    case CURRICULUM_LIST_SUCCESS:
      return { loading: false, curriculums: action.payload };

    case CURRICULUM_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const curriculumDetailsReducer = (state = { curriculum: {} }, action) => {
  switch (action.type) {
    case CURRICULUM_DETAILS_REQUEST:
      return { loading: true, ...state };

    case CURRICULUM_DETAILS_SUCCESS:
      return { loading: false, curriculum: action.payload };

    case CURRICULUM_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const curriculumDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CURRICULUM_DELETE_REQUEST:
      return { loading: true };

    case CURRICULUM_DELETE_SUCCESS:
      return { loading: false, success: true };

    case CURRICULUM_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const curriculumCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CURRICULUM_CREATE_REQUEST:
      return { loading: true };

    case CURRICULUM_CREATE_SUCCESS:
      return { loading: false, success: true, curriculum: action.payload };

    case CURRICULUM_CREATE_FAIL:
      return { loading: false, error: action.payload };

    case CURRICULUM_CREATE_RESET:
      return {};

    default:
      return state;
  }
};

export const curriculumUpdateReducer = (state = { curriculum: {} }, action) => {
  switch (action.type) {
    case CURRICULUM_UPDATE_REQUEST:
      return { loading: true };

    case CURRICULUM_UPDATE_SUCCESS:
      return { loading: false, success: true, curriculum: action.payload };

    case CURRICULUM_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case CURRICULUM_UPDATE_RESET:
      return { curriculum: {} };

    default:
      return state;
  }
};

export const curriculumReviewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CURRICULUM_CREATE_REVIEW_REQUEST:
      return { loading: true };

    case CURRICULUM_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true };

    case CURRICULUM_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload };

    case CURRICULUM_CREATE_REVIEW_RESET:
      return {};

    default:
      return state;
  }
};
