/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_UPDATE_ITEM,
  CART_SAVE_PAYMENT_METHOD,
} from '../constants/cartConstants';
import axios from '../utils/axios';

export const addToCart = (product) => async (dispatch, getState) => {
  const { data } = await axios.get(`/api/products/${product.id}`);

  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      product: product.id,
      name: product.name,
      image1: product.image1,
      price: product.totalPrice,
      countInStock: product.countInStock,
      msg: product.message,
      note: product.note,
    },
  });
  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  });

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};

export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  });

  localStorage.setItem('shippingAddress', JSON.stringify(data));
};

export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  });

  localStorage.setItem('paymentMethod', JSON.stringify(data));
};

export const updateCart = (updatedCart) => (dispatch, getState) => {
  dispatch({
    type: CART_UPDATE_ITEM,
    payload: updatedCart,
  });

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems));
};
