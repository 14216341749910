/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import axios from '../utils/axios';
import { CustomFormControl, CustomLabel, CustomSelect } from './styled';

function AddWeight({
  weightVisible, fetchWeights, handleWeightModalShow, handleWeightModalClose,
}) {
  const [cakeWeight, setCakeWeight] = useState('');
  const [price, setPrice] = useState('');
  const [servesPerWeight, setServesPerWeight] = useState('');
  const [toppings, setToppings] = useState([]);
  const [availableToppings, setAvailableToppings] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const fetchProducts = async () => {
    try {
      const { data } = await axios.get('/api/products/', {
        params: {
          page: 1,
          page_size: 100,
          ordering: '-createdAt',
        },
        headers: {
          Authorization: `Bearer ${userInfo.token}`, // Add the token as a bearer token
        },
      });

      setProducts(data?.results);
    } catch (error) {
      // Throw the error to v
      console.log('');
    }
  };

  const fetchToppings = async () => {
    try {
      const response = await axios.get('/api/toppings/');
      setAvailableToppings(response.data);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    fetchToppings();
    fetchProducts();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const formattedToppings = toppings.map((topping) => topping.id);

      await axios.post('/api/weights/', {
        cake_weight: cakeWeight,
        price,
        serves_per_weight: servesPerWeight,
        toppings: formattedToppings,
        product_id: selectedProduct,
      });

      // Reset form fields
      setCakeWeight('');
      setPrice('');
      setServesPerWeight('');
      setToppings([]);
      setSelectedProduct('');
      fetchWeights();
      handleWeightModalClose();
      toast.success('Weight added successfully!');
      fetchProducts();
    } catch (error) {
      handleWeightModalShow();
      toast.error('Failed to add weight.');
    }
  };

  const handleToppingsChange = (selectedOptions) => {
    setToppings(selectedOptions.map((option) => option.value));
  };

  return (
    <Form onSubmit={submitHandler}>
      <Modal show={weightVisible} onHide={handleWeightModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Add Weight</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="product">
            <CustomLabel>Select Product</CustomLabel>
            <CustomSelect
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e.target.value)}
            >
              <option value="">Select a product</option>
              {products.map((product) => (
              // Check if the product name contains "mini" or "cup" and hide if true
                (!product.name.toLowerCase().includes('mini')
      || !product.name.toLowerCase().includes('cup')) && (
        <option key={product.id} value={product.id}>
          {product.name}
        </option>
                )))}
            </CustomSelect>
          </Form.Group>
          <br />

          <Form.Group controlId="cakeWeight">
            <CustomLabel>Cake Weight</CustomLabel>
            <CustomFormControl
              type="text"
              value={cakeWeight}
              onChange={(e) => setCakeWeight(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="price">
            <CustomLabel>Price</CustomLabel>
            <CustomFormControl
              type="text"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="servesPerWeight">
            <CustomLabel>Serves Per Weight</CustomLabel>
            <CustomFormControl
              type="text"
              value={servesPerWeight}
              onChange={(e) => setServesPerWeight(e.target.value)}
            />
          </Form.Group>

          {/* <Form.Group controlId="toppings">
            <CustomLabel>Toppings</CustomLabel>
            <Form.Select
              multiple
              value={toppings}
              onChange={(e) => handleToppingsChange(Array.from(e.target.selectedOptions))}
            >
              {availableToppings.map((topping) => (
                <option key={topping.id} value={topping.id}>
                  <h6>
                    {topping.name}
                    {' '}
                    - Ksh
                    {topping.price}
                  </h6>
                </option>
              ))}
            </Form.Select>
          </Form.Group> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleWeightModalClose}>
            Close
          </Button>
          <Button type="submit" onClick={submitHandler} variant="primary">
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </Form>
  );
}

export default AddWeight;
