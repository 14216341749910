/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AcademyButton } from './styled';
import { listLocations } from '../actions/locationAction';

function CardAcademy() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);

  const locationsList = useSelector((state) => state.locationsList);
  const { locations = [], loading, error } = locationsList;

  useEffect(() => {
    dispatch(listLocations());
  }, [dispatch, navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % locations.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [locations.length]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div>
        Error:
        {' '}
        {error}
      </div>
    );
  }

  return (
    <Row className="no-gutters" style={{ height: '300px' }}>
      <Col xs={0} md={4} className="d-none d-md-flex flex-wrap">
        {locations.length > 0 && (
          <div className="image-carousel">
            {locations.map((location, index) => (
              <img
                key={index}
                className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
                style={{ maxHeight: '300px', width: '100%' }}
                alt={location.name}
                src={location.location_image}
              />
            ))}
          </div>
        )}
      </Col>
      <Col xs={12} md={8} className="d-flex align-items-center justify-content-center" style={{ backgroundColor: '#CDF8EE' }}>
        <div style={{ margin: 'auto' }} className="p-3">
          <div className="text-center">
            <h3>Our Academy</h3>
            <p>Delivering creamy deliciousness for you since forever</p>
          </div>
          <div className="text-center mt-3">
            <AcademyButton variant="outline-light" onClick={() => navigate('/locations')}>
              View our locations
            </AcademyButton>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default CardAcademy;
