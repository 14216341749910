/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Loader from '../components/Loader';
import Message from '../components/Message';
import FormContainer from '../components/FormContainer';
import { CATEGORY_UPDATE_RESET } from '../constants/categoryContants';
import { getCategoryDetails, updateCategory } from '../actions/categoryAction';

function CategoryEditScreen() {
  const { id } = useParams();

  const [name, setName] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const categoryDetails = useSelector((state) => state.categoryDetails);
  const { error, loading, category } = categoryDetails;

  const categoryUpdate = useSelector((state) => state.categoryUpdate);
  const { error: errorUpdate, loading: loadingUpdate, success: successUpdate } = categoryUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: CATEGORY_UPDATE_RESET });
      navigate('/shop/admin/');
    } else if (!category.name || category.id !== Number(id)) {
      dispatch(getCategoryDetails(id));
    } else {
      setName(category.name);
    }
  }, [category, id, successUpdate, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateCategory({
      id: category.id, name,
    }));
  };

  return (
    <div>
      <Link to="/shop/admin/">
        Go Back
      </Link>

      <FormContainer>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}

        {loading ? <Loader /> : error ? <Alert variant="danger">{error}</Alert>
          : (
            <Form onSubmit={submitHandler}>

              <Form.Group controlId="categoryName">
                <Form.Label>Category Name</Form.Label>
                <Form.Control
                  type="categoryName"
                  placeholder="Enter Category name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>

              <Button type="submit" variant="primary">
                Update
              </Button>

            </Form>
          )}

      </FormContainer>
    </div>

  );
}

export default CategoryEditScreen;
