import React from 'react';
import {
  Container, Row, Image, Col,
} from 'react-bootstrap';
import Header from '../components/Header';
import cakeImage1 from '../assets/images/Page 1_3.png';

function NotFoundScreen() {
  return (
    <>
      <Header />
      <div className="col-md-5 mx-auto" style={{ margin: '100px 300px 400px 300px' }}>
        <Container>
          <Row>
            <Col>
              <h1>404 Not Found</h1>
              <Image
                loading="lazy"
                src={cakeImage1}
                alt="404 Not Found"
                fluid
              />
              <p>The page you are looking for could not be found.</p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default NotFoundScreen;
