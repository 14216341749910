import React from 'react';
import { Image, ListGroup, Offcanvas } from 'react-bootstrap';

function ProductDetailsCanvas({ show, handleClose, selectedProduct }) {
  const shouldDisplayWeights = selectedProduct?.category?.name.toLowerCase().includes('occasion');
  const shouldDisplayCakeQuantity = selectedProduct?.category?.name.toLowerCase().includes('mini')
    || selectedProduct?.category?.name.toLowerCase().includes('cup');

  function formatDateString(dateString) {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  return (
    selectedProduct && (
      <Offcanvas scroll show={show} onHide={handleClose} backdrop placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{selectedProduct.name}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Image
            loading="lazy"
            className="product-image"
            src={selectedProduct.image1}
            alt="product"
          />
          <ListGroup className="mt-4">
            <ListGroup.Item>
              <p className="product-label">Description:</p>
              <p>{selectedProduct.description}</p>
            </ListGroup.Item>
            <ListGroup.Item>
              <p className="product-label">Allergens:</p>
              <p>{selectedProduct.allergens}</p>
            </ListGroup.Item>
            <ListGroup.Item>
              <p className="product-label">Ingredients:</p>
              <p>{selectedProduct.ingredients}</p>
            </ListGroup.Item>
            <ListGroup.Item>
              <p className="product-label">Min Quantity:</p>
              <p>{selectedProduct.min_quantity}</p>
            </ListGroup.Item>
            <ListGroup.Item>
              <p className="product-label">Max Quantity:</p>
              <p>{selectedProduct.max_quantity}</p>
            </ListGroup.Item>
            {selectedProduct.promotion_start_date && (
            <ListGroup.Item>
              <p className="product-label">Promotion Start Date:</p>
              <p>{formatDateString(selectedProduct.promotion_start_date)}</p>
            </ListGroup.Item>
            )}
            {selectedProduct.promotion_end_date && (
              <ListGroup.Item>
                <p className="product-label">Promotion End Date:</p>
                <p>{formatDateString(selectedProduct.promotion_end_date)}</p>
              </ListGroup.Item>
            )}
            {shouldDisplayWeights && (
              <ListGroup.Item>
                <p className="product-label">Weights:</p>
                <ul>
                  {selectedProduct?.weights.map((selectedCake) => (
                    <li key={selectedCake?.id}>
                      <span className="fw-bold">
                        {selectedCake.cake_weight}
                      </span>
                      {selectedCake.unit}
                      {' '}
                      - Ksh
                      {selectedCake.price}
                      {' '}
                      (Serves
                      {' '}
                      {selectedCake.serves_per_weight}
                      )
                    </li>
                  ))}
                </ul>
              </ListGroup.Item>
            )}
            {shouldDisplayCakeQuantity && (
              <>
                <ListGroup.Item>
                  <p className="product-label">Cake Quantity:</p>
                  <p>{selectedProduct.cake_quantity}</p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <p className="product-label">Cake Quantity Price:</p>
                  <p>{selectedProduct.cake_quantity_price}</p>
                </ListGroup.Item>
              </>
            )}
          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>
    )
  );
}

export default ProductDetailsCanvas;
