import React from 'react';
import { Spinner } from 'react-bootstrap';

function Loader() {
  return (
  // <Spinner
  //   animation="border"
  //   role="status"
  //   style={{
  //     height: '100px',
  //     width: '100px',
  //     margin: 'auto',
  //     display: 'block',

  //   }}

  // >
  //   <span className="sr-only">Loading...</span>
  // </Spinner>

    <div className="loader-overlay">
      <Spinner animation="border" variant="primary" role="status" className="loader">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>

  );
}

export default Loader;
