import React from 'react';
import { Breadcrumb } from 'react-bootstrap';

function AllLocationCrumb() {
  return (
    <Breadcrumb className="breadcrumb-styling">
      <Breadcrumb.Item active className="breadcrumb-link">
        <span className="breadcrumb-item-text">Locations /</span>
      </Breadcrumb.Item>
    </Breadcrumb>
  );
}

export default AllLocationCrumb;
