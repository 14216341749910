/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Alert, Col, Offcanvas, Row, Stack,
} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import { CreateProductButton, DeleteUpload } from './styled';
import { removeFromCart } from '../actions/cartActions';
import PreviewImage from './previewImage';
import { numberWithCommas } from '../utils/utlilities';

function CartCanvas({ show, handleClose }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const removeFromCartHandler = (itemId) => {
    dispatch(removeFromCart(itemId));
  };

  const checkoutHandler = () => {
    const currentHour = new Date().getUTCHours() + 3; // East African Time offset from UTC
    if (currentHour >= 8 && currentHour <= 20) {
      navigate('/orderpaymentScreen');
    } else {
      toast.error('Cakes & Cakes is currently closed. We are open between 8 am and 8 pm');
    }
  };

  const isMobileView = useMediaQuery({ maxWidth: 767 });

  return (
    <Offcanvas
      // className={`${isMobileView ? 'mobile-view' : ''}`}
      scroll
      show={show}
      onHide={handleClose}
      backdrop
      placement="end"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <h3>Cart</h3>
        </Offcanvas.Title>
      </Offcanvas.Header>

      <Offcanvas.Body>
        <h6>
          {cartItems.length}
          &nbsp;items
        </h6>
        <br />
        <Row>
          <Col>
            {cartItems.length === 0 ? (
              <Alert variant="info">Your Cart is empty</Alert>
            ) : (
              <div>
                {cartItems.map((item) => (
                  <>
                    <br />
                    <Link className="link" to={`/product/${item.product}`}>
                      <Row className="my-row p-md-3">
                        <Col xs={4} md={4} lg={3}>
                          <PreviewImage src={`${item.image1}`} alt={item.name} fluid rounded />
                        </Col>
                        <Col xs={4} md={8} lg={6}>
                          <h6>{item.name}</h6>
                          {item.weight_exists && (
                          <div className="message-container">
                            <small>Message on Cake:</small>
                            <blockquote>{item.msg}</blockquote>
                          </div>
                          )}
                        </Col>
                        <Col xs={4} md={4} lg={3}>
                          <h6>
                            Ksh
                            {' '}
                            {item && numberWithCommas(item.price)}
                          </h6>
                          <DeleteUpload
                            type="button"
                            variant="danger"
                            size="sm"
                            onClick={() => removeFromCartHandler(item.product)}
                          >
                            <i className="fa-solid fa-trash" />
                            &nbsp; Remove
                          </DeleteUpload>
                        </Col>
                      </Row>
                    </Link>
                    <hr />
                  </>
                ))}
              </div>
            )}
          </Col>
        </Row>
        <div className="cart-footer">
          <Stack>
            <Row>
              <h5>
                Subtotal
                <h5 style={{ float: 'right' }}>
                  &nbsp;Ksh&nbsp;
                  {cartItems.reduce((acc, item) => acc + item.price, 0).toFixed(2)}
                </h5>
              </h5>
            </Row>
            <CreateProductButton
              variant="secondary"
              className="checkout-button"
              onClick={checkoutHandler}
            >
              Checkout
            </CreateProductButton>
          </Stack>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default CartCanvas;
