import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  productListReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productReviewCreateReducer,
  productTopRatedReducer,
  productTotalReducers,
  productListReducerByCategory,
} from './reducers/productReducers';

import { cartReducer } from './reducers/cartReducer';
import {
  userDeleteReducer,
  userDetailsReducer,
  userListReducer,
  userLoginReducer,
  userRegisterReducer,
  userTotalReducers,
  userUpdateProfileReducer,
  userUpdateReducer,
} from './reducers/userReducer';
import {
  categoriesListReducers,
  categoryDeleteReducer,
  categoryDetailsReducer,
  categoryTotalReducers,
  categoryUpdateReducer,
} from './reducers/categoriesReducer';

import {
  weightCreateReducer,
  weightDeleteReducer,
  weightDetailsReducer,
  weightsListReducers,
  weightsTotalReducers,
  weightUpdateReducer,
} from './reducers/weightReducer';
import {
  orderCreateReducer,
  orderDeliverReducer,
  orderDetailsReducer,
  orderListMyReducer,
  orderListReducer,
  orderPayReducer,
  orderRevenueReducers,
  orderTotalReducers,
} from './reducers/orderReducers';
import {
  locationCreateReducer,
  locationDeleteReducer,
  locationDetailsReducer,
  locationsListReducers,
  locationTotalReducers,
  locationUpdateReducer,
} from './reducers/locationReducer';
import {
  courseCreateReducer,
  courseDeleteReducer,
  courseDetailsReducer,
  courseListReducer,
  courseReviewCreateReducer,
  courseUpdateReducer,
} from './reducers/courseReducer';
import {
  curriculumCreateReducer,
  curriculumDeleteReducer,
  curriculumDetailsReducer,
  curriculumListReducer,
  curriculumReviewCreateReducer,
  curriculumUpdateReducer,
} from './reducers/curriculumReducer';

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productReviewCreate: productReviewCreateReducer,
  productTopRated: productTopRatedReducer,
  productTotalList: productTotalReducers,
  productListByCategory: productListReducerByCategory,

  courseList: courseListReducer,
  courseDetails: courseDetailsReducer,
  courseDelete: courseDeleteReducer,
  courseCreate: courseCreateReducer,
  courseUpdate: courseUpdateReducer,
  courseReviewCreate: courseReviewCreateReducer,

  cart: cartReducer,

  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  userListTotal: userTotalReducers,

  weightsList: weightsListReducers,
  weightDelete: weightDeleteReducer,
  weightUpdate: weightUpdateReducer,
  weightCreate: weightCreateReducer,
  weightDetails: weightDetailsReducer,
  weightListTotal: weightsTotalReducers,

  categoriesList: categoriesListReducers,
  categoryDelete: categoryDeleteReducer,
  categoryUpdate: categoryUpdateReducer,
  categoryDetails: categoryDetailsReducer,
  categoriesListTotal: categoryTotalReducers,

  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderListMy: orderListMyReducer,
  orderList: orderListReducer,
  orderDeliver: orderDeliverReducer,
  orderListTotal: orderTotalReducers,
  orderRevenue: orderRevenueReducers,

  locationsList: locationsListReducers,
  locationListTotal: locationTotalReducers,
  locationDelete: locationDeleteReducer,
  locationUpdate: locationUpdateReducer,
  locationCreate: locationCreateReducer,
  locationDetails: locationDetailsReducer,

  curriculumList: curriculumListReducer,
  curriculumDetails: curriculumDetailsReducer,
  curriculumDelete: curriculumDeleteReducer,
  curriculumCreate: curriculumCreateReducer,
  curriculumUpdate: curriculumUpdateReducer,
  curriculumReviewCreate: curriculumReviewCreateReducer,

});

const cartItemsFromStorage = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems')) : [];

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo')) : null;

const shippingAddressFromStorage = localStorage.getItem('shippingAddress')
  ? JSON.parse(localStorage.getItem('shippingAddress')) : {};

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
