import React from 'react';
import { Breadcrumb } from 'react-bootstrap';

function BreadcrumbComponent() {
  return (
    <div className="d-grid gap-6">
      <Breadcrumb>
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/account">
          Account
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Sign Up</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
}

export default BreadcrumbComponent;
