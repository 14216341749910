/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import {
  Button,
  Card, Col, Container, Image, Row, Stack,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { listCurriculumDetails } from '../../actions/curriculumnAction';
import { AddToCartButton } from '../../components/styled';

function CourseScreen() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const curriculumDetails = useSelector((state) => state.curriculumDetails);
  const { curriculum } = curriculumDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productDelete = useSelector((state) => state.productDelete);
  const { success: successDelete } = productDelete;

  useEffect(() => {
    dispatch(listCurriculumDetails(id));
  }, [dispatch, userInfo, successDelete, id]);

  return (
    <>
      <Header />
      <div
        className="hero-image"
        style={{ backgroundImage: `url(${curriculum?.image})` }}
      >

        <Row>
          <Col>
            <h1
              style={{
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '48px',
              }}
              className="text-white"
            >
              {curriculum?.title}

            </h1>
          </Col>
        </Row>
      </div>
      <br />
      <Container>
        <Row className="mb-5">
          <Col md={8}>
            <p>{curriculum?.description}</p>
          </Col>
        </Row>
        <h3>Availability</h3>
        <Row className="mt-0 pt-0 row-1 mb-5 px-sm-3 px-2">
          {(curriculum?.courses || []).map((course) => (
            <Col xs={12} sm={6} md={4} lg={4} key={course?.id}>
              <Card className="pb-3 pr-3 pt-3 pl-3 course-card">
                <div className="course-image-container">
                  <div className="course-image-overlay" />
                </div>
                <Card.Body>
                  <Stack direction="horizontal" gap={3}>
                    <div>

                      <Card.Subtitle className="course-title">
                        From
                        <br />
                        {course?.start_date}
                      </Card.Subtitle>
                      <br />
                      <Card.Subtitle className="course-subtitle">
                        To
                        <br />
                        {course?.end_date}
                      </Card.Subtitle>
                    </div>
                    <div className="ms-auto">
                      <AddToCartButton
                        variant="secondary"
                        onClick={() => navigate(`/academy/course/${id}/${course?._id}/enroll`)}
                      >
                        Enroll
                      </AddToCartButton>
                    </div>
                  </Stack>
                  <div className="course-info">
                    {/* <div className="course-info-item">
                      <BsClockFill />
                      <span>{course?.duration}</span>
                    </div> */}
                    {/* <div className="course-info-item">
                      <BsPeopleFill />
                      <span>
                        {course?.seats_available}
                        seats
                      </span>
                    </div> */}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        {(curriculum && curriculum?.lessons
          ? JSON.parse(curriculum?.lessons) : []).length > 1 && (
          <>
            <h3>Course Content</h3>
            <br />
            <h6>Lesson Plan</h6>
            <Row className="mt-1 pt-1 row-1 mb-5 px-sm-3 px-2">
              {(curriculum && curriculum?.lessons
                ? JSON.parse(curriculum?.lessons) : []).map((lesson) => (
                  <Col className="p-2" xs={12} sm={6} md={4} lg={4} key={lesson?.id}>
                    <Button variant="outline-info" className="custom-button">
                      <h6>{lesson}</h6>
                    </Button>
                  </Col>
              ))}
            </Row>
          </>
        )}

        <Row className="mb-5 p-2">
          {curriculum && curriculum?.instructor && (
          <div className="container bootstrap snippets bootdey">
            <div className="profile">
              <div className="profile-body">
                <div className="profile-bio">
                  <Row className="">
                    <Col xs={12} md={4} className="text-center align-items-center p-2">
                      <Image
                        loading="lazy"
                        src={`${curriculum?.instructor?.user?.profile_photo}`}
                        alt=""
                        width="180"
                        height="180"
                        style={{ display: 'block' }}
                        onError={(e) => {
                          e.target.src = 'https://bootdey.com/img/Content/avatar/avatar7.png'; // Replace with the path to your placeholder image
                        }}
                        fluid
                        thumbnail
                        rounded
                      />
                    </Col>
                    <Col xs={12} md={8} className="p-2">
                      <h2>
                        {curriculum?.instructor?.user?.first_name}
                        {' '}
                        {curriculum?.instructor?.user?.last_name}
                      </h2>
                      <hr />
                      <p>{curriculum?.instructor?.bio}</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          )}
        </Row>

      </Container>
      <Footer />
    </>
  );
}

export default CourseScreen;
