/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
  Col, Form, Image, InputGroup, ListGroup, Offcanvas, Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  CustomFormControl, CustomLabel, CustomSelect, CreateProductButton, DeleteUpload,
} from '../components/styled';

import { listWeights } from '../actions/weightAction';
import { listCategories } from '../actions/categoryAction';
import axios from '../utils/axios';

function PreviewImage({ image }) {
  return (
    <div>
      <Image
        loading="lazy"
        src={URL.createObjectURL(image)}
        alt="image.name"
        height={60}
        width={60}
      />
    </div>
  );
}

function CreateProductScreen({ show, handleClose, handleShow }) {
  const [name, setName] = useState('');
  const [image1, setImage1] = useState('');
  const [image2, setImage2] = useState('');
  const [image3, setImage3] = useState('');
  const [description, setDescription] = useState('');
  const [ingredients, setIngredients] = useState('');
  const [min_quantity, setMinQuantity] = useState(null);
  const [max_quantity, setMaxQuantity] = useState(null);
  const [cat, setCategory] = useState(null);
  const [allergens, setAllergens] = useState(null);
  const [start_date, setStartDate] = useState(null);
  const [end_date, setEndDate] = useState(null);
  const [discount, setDiscount] = useState('');
  const [weightTags, setWeightTags] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [preview1, setPreviewOne] = useState(false);
  const [preview2, setPreviewTwo] = useState(false);
  const [preview3, setPreviewThree] = useState(false);
  const [cake_quantity, setCakeQuantity] = useState(null);
  const [cake_quantity_price, setCakeQuantityPrice] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const weightsList = useSelector((state) => state.weightsList);
  const { loading: loadingWeight, weights } = weightsList;
  const [hasWeights, setHasWeights] = useState(false);

  const categoriesList = useSelector((state) => state.categoriesList);
  const { loading: loadingCategory, categories } = categoriesList;

  useEffect(() => {
    dispatch(listWeights());
  }, [dispatch, navigate, userInfo]);

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listCategories());
    } else {
      navigate('/authenticate');
    }
  }, [dispatch, navigate, userInfo]);

  const createProductHandler = async (e) => {
    e.preventDefault();
    const weightTagIds = weightTags.map((option) => Number(option.id));

    const product = new FormData();
    product.append('name', name);

    if (image1 instanceof File) {
      product.append('image1', image1);
    }

    if (image2 instanceof File) {
      product.append('image2', image2);
    }

    if (image3 instanceof File) {
      product.append('image3', image3);
    }

    product.append('description', description);
    product.append('ingredients', ingredients);

    // Check if the selected category includes "cup cakes" or "mini cakes"
    const isCupCakes = cat && categories.find((category) => category.id === parseInt(cat, 10)).name.toLowerCase().includes('cup');
    const isMiniCakes = cat && categories.find((category) => category.id === parseInt(cat, 10)).name.toLowerCase().includes('mini');
    const isOcassionCakes = cat && categories.find((category) => category.id === parseInt(cat, 10)).name.toLowerCase().includes('occasion');

    // Validate weights array for occasion cakes
    if (isOcassionCakes && weightTagIds.length === 0) {
      toast.error('Weights are required for "Occasion Cakes" category.');
      return;
    }

    if (!hasWeights) {
      // For "cup cakes" and "mini cakes," make sure cake_quantity is provided
      if (!cake_quantity) {
        toast.error('Cake quantity is required for "Cup Cakes" and "Mini Cakes" categories.');
        return;
      }
      product.append('cake_quantity', cake_quantity);
      product.append('cake_quantity_price', cake_quantity_price);

      // Remove weights from the FormData if it was
      // added (should not be present for "cup cakes" and "mini cakes")
      product.delete('weights');
    } else if (isOcassionCakes) {
      // For "occasion cakes," set cake_quantity to null
      product.append('cake_quantity', '');

      // Check if weights were provided, and if so, include them in the FormData
      if (weightTagIds.length > 0) {
        product.append('weights', JSON.stringify([weightTagIds]));
      } else {
        toast.error('Weights are required for "Occasion Cakes" category.');
        return;
      }
    } else {
      // For other categories (except "cup cakes" and "mini cakes"), set cake_quantity to null
      product.append('cake_quantity', '');

      // Check if weights were provided, and if so, include them in the FormData
      if (weightTagIds.length > 0) {
        product.append('weights', JSON.stringify([weightTagIds]));
      }
    }

    product.append('min_quantity', min_quantity);
    product.append('max_quantity', max_quantity);
    // product.append('weights', JSON.stringify([weightTagIds])); // Convert array to JSON string
    product.append('category', cat);
    product.append('allergens', allergens);

    if (start_date) {
      product.append('promotion_start_date', moment(start_date).format('YYYY-MM-DD HH:mm:ss'));
    }

    if (end_date) {
      product.append('promotion_end_date', moment(end_date).format('YYYY-MM-DD HH:mm:ss'));
    }

    product.append('discount', discount);

    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await axios.post('/api/products/create/', product, { headers });
      handleClose();
      toast.success('Product added successfully!');
    } catch (error) {
      if (error.response && error.response.data.weights) {
        // If the error response contains "weights" field, show the error message for weights
        toast.error(error.response.data.weights[0]);
      } else {
        handleShow();
        toast.error('Failed to add product.');
      }
    }
  };

  const checkSelectedValue = (option) => {
    const doesObjectExist = weightTags.some((object) => object.id === option.id);
    return doesObjectExist;
  };

  const handleImage1Change = (e) => {
    setImage1(e.target.files[0]);
    setPreviewOne(true);
  };

  const removeImage1 = () => {
    setImage1(null);
    setPreviewOne(false);
  };

  const removeImage2 = () => {
    setImage2(null);
    setPreviewTwo(false);
  };

  const removeImage3 = () => {
    setImage3(null);
    setPreviewThree(false);
  };

  const handleImage2Change = (e) => {
    setImage2(e.target.files[0]);
    setPreviewTwo(true);
  };

  const handleImage3Change = (e) => {
    setImage3(e.target.files[0]);
    setPreviewThree(true);
  };

  const handleCheckboxChange = (option) => {
    if (checkSelectedValue(option)) {
      // If the option is already selected, remove it from the weightTags array
      setWeightTags(weightTags.filter((tag) => tag.id !== option.id));
    } else {
      // If the option is not selected, add it to the weightTags array
      setWeightTags([...weightTags, option]);
    }
  };

  const selectedCategory = categories.find((category) => category.id === parseInt(cat, 10));

  const isCupCakes = selectedCategory && selectedCategory?.name.toLowerCase().includes('cup');
  const isMiniCakes = selectedCategory && selectedCategory?.name.toLowerCase().includes('mini');
  const isOcassionCakes = selectedCategory && selectedCategory?.name.toLowerCase().includes('occasion');

  return (
    <Offcanvas
      scroll
      show={show}
      onHide={handleClose}
      backdrop="static"
      placement="end"
      className="custom-product-offcanvas"
    >

      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <h3>
            Create Product
          </h3>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {/* <CreateProduct category={category} /> */}
        <h4>Please fill in the form below</h4>
        <Form onSubmit={createProductHandler}>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <CustomLabel>
                  Product name
                  <span className="asterisk">*</span>
                </CustomLabel>
                <CustomFormControl
                  type="text"
                  value={name}
                  required
                  className="w-100 required-field"
                  onChange={(event) => setName(event.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <CustomLabel>
                  Product description
                  <span className="asterisk">*</span>
                </CustomLabel>
                <CustomFormControl
                  as="textarea"
                  rows={5}
                  required
                  className="w-100 required-field"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <CustomLabel>
                  Category
                  <span className="asterisk">*</span>
                </CustomLabel>
                <CustomSelect
                  required
                  className="w-100 required-field"
                  value={cat}
                  onChange={(event) => setCategory(event.target.value)}
                >
                  <option value="">Select an option</option>
                  {!loadingCategory && (categories || []).map((ctg) => (
                    <option key={ctg.id} value={ctg.id}>{ctg.name}</option>
                  ))}
                </CustomSelect>
              </Form.Group>
              <br />
              <Form.Group controlId="hasWeightsCheckbox">
                <Form.Check
                  type="checkbox"
                  label={<CustomLabel>Does category have weights?</CustomLabel>}
                  checked={hasWeights}
                  onChange={(e) => setHasWeights(e.target.checked)}
                />
              </Form.Group>
              <br />
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <CustomLabel>
                  Min quantity
                  <span className="asterisk">*</span>
                </CustomLabel>
                <CustomFormControl
                  value={min_quantity}
                  required
                  className="w-100 required-field"
                  onChange={(event) => setMinQuantity(event.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <CustomLabel>
                  Max quantity
                  <span className="asterisk">*</span>
                </CustomLabel>
                <CustomFormControl
                  value={max_quantity}
                  required
                  className="w-100 required-field"
                  onChange={(event) => setMaxQuantity(event.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Row>
                  {hasWeights ? (
                    <>

                      <Col xs={12} md={6} className="custom-column">
                        <CustomLabel>Weight</CustomLabel>
                        <div className="checkbox-group">
                          {weights.map((option) => (
                            <div key={option.id} className="checkbox-item">
                              <Form.Check
                                type="checkbox"
                                id={`weight-${option.id}`}
                                label={`${option.cake_weight} - ${option.price}`}
                                checked={checkSelectedValue(option)}
                                onChange={() => handleCheckboxChange(option)}
                              />
                            </div>
                          ))}
                        </div>
                      </Col>
                      <Col xs={12} md={6} className="custom-column">
                        <CustomLabel>Selected Weights</CustomLabel>
                        <div className="tag-group">
                          <ListGroup className="tag-list">
                            {weightTags.map((tag, index) => (
                              <ListGroup.Item key={tag.id}>
                                {tag.cake_weight}
                                {' '}
                                - price
                                {' '}
                                {tag.price}
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        </div>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <CustomLabel>
                          Cake Quantity
                          <span className="asterisk">*</span>
                        </CustomLabel>
                        <CustomFormControl
                          value={cake_quantity}
                          required
                          type="number"
                          className="w-100 required-field"
                          onChange={(event) => setCakeQuantity(event.target.value)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="cakeQuantityPrice">
                        <CustomLabel>
                          Cake Quantity Price
                          <span className="asterisk">*</span>
                        </CustomLabel>
                        <CustomFormControl
                          type="number"
                          value={cake_quantity_price}
                          required
                          className="w-100 required-field"
                          onChange={(event) => setCakeQuantityPrice(event.target.value)}
                        />
                      </Form.Group>
                    </>
                  )}

                </Row>

              </Form.Group>

              <br />
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <CustomLabel>
                  Allergens
                  <span className="asterisk">*</span>
                </CustomLabel>
                <CustomFormControl
                  required
                  className="w-100 required-field"
                  value={allergens}
                  onChange={(event) => setAllergens(event.target.value)}
                  as="textarea"
                  rows={3}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <CustomLabel>
                  Ingredients
                  <span className="asterisk">*</span>
                </CustomLabel>
                <CustomFormControl
                  required
                  className="w-100 required-field"
                  value={ingredients}
                  onChange={(event) => setIngredients(event.target.value)}
                  as="textarea"
                  rows={5}
                />
              </Form.Group>
              <ListGroup>
                <ListGroup.Item>
                  <Row style={{ border: 'None' }}>
                    <Col>
                      {preview1 && (
                      <PreviewImage
                        image={image1}
                        thumbnail
                      />
                      )}
                    </Col>
                    <Col style={{ float: 'left' }}>
                      {image1 && <small>{image1.name}</small>}
                    </Col>
                    <Col style={{ float: 'left' }}>
                      <div className="form-group">
                        {image1 ? (
                          <DeleteUpload onClick={removeImage1} variant={false}>
                            <i className="fa-solid fa-trash" />
                            &nbsp;
                            Delete
                          </DeleteUpload>
                        ) : (

                          <>
                            <label htmlFor="image1">
                              <span>
                                <i className="fa-regular fa-image" />
&nbsp; Upload image
                              </span>
                            </label>
                            <br />
                            <input
                              style={{ display: 'None' }}
                              type="file"
                              className="form-control-file"
                              id="image1"
                              onChange={(event) => handleImage1Change(event)}
                            />
                          </>

                        )}
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row style={{ border: 'None' }}>
                    <Col>
                      {preview2 && (
                      <PreviewImage
                        thumbnail
                        image={image2}
                      />
                      )}
                    </Col>
                    <Col style={{ float: 'left' }}>
                      {image2 && <small>{image2.name}</small>}
                    </Col>
                    <Col style={{ float: 'left' }}>
                      <div className="form-group">
                        {image2 ? (
                          <DeleteUpload onClick={removeImage2} variant={false}>
                            <i className="fa-solid fa-trash" />
                            &nbsp;
                            Delete
                          </DeleteUpload>
                        ) : (

                          <>
                            <label htmlFor="image2">
                              <span>
                                <i className="fa-regular fa-image" />
&nbsp; Upload image
                              </span>
                            </label>
                            <br />
                            <input
                              style={{ display: 'None' }}
                              type="file"
                              className="form-control-file"
                              id="image2"
                              onChange={(event) => handleImage2Change(event)}
                            />
                          </>

                        )}
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row style={{ border: 'None' }}>
                    <Col style={{ float: 'left' }}>
                      {preview3 && (
                      <PreviewImage
                        thumbnail
                        image={image3}
                      />
                      )}
                    </Col>
                    <Col style={{ float: 'left' }}>
                      {image3 && <small>{image3.name}</small>}
                    </Col>
                    <Col>
                      <div className="form-group">
                        {image3 ? (
                          <DeleteUpload onClick={removeImage3} variant={false}>
                            <i className="fa-solid fa-trash" />
                            &nbsp;
                            Delete
                          </DeleteUpload>
                        ) : (

                          <>
                            <label htmlFor="image3">
                              <span>
                                <i className="fa-regular fa-image" />
&nbsp; Upload image
                              </span>
                            </label>
                            <br />
                            <input
                              style={{ display: 'None' }}
                              type="file"
                              className="form-control-file"
                              id="image3"
                              onChange={(event) => handleImage3Change(event)}
                            />
                          </>

                        )}
                      </div>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
              <br />
              <h4>Promotion</h4>
              <Row>
                <Form.Group controlId="formStartDate">
                  <CustomLabel>Start Date</CustomLabel>
                  <InputGroup>
                    <DatePicker
                      selected={start_date}
                      onChange={(date) => setStartDate(date)}
                      // minDate={new Date()}
                      // maxDate={new Date(end_date)}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeCaption="time"
                      className="form-control"
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group controlId="formEndDate">
                  <CustomLabel>End Date</CustomLabel>
                  <InputGroup>
                    <DatePicker
                      selected={end_date}
                      onChange={(date) => setEndDate(date)}
                      minDate={new Date(start_date)}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeCaption="time"
                      className="form-control"
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group controlId="discount">
                  <CustomLabel>Discount</CustomLabel>
                  <Form.Control
                    placeholder="Enter discount"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </Form.Group>
              </Row>
            </Col>
          </Row>
          <div className="mt-3">
            <CreateProductButton
              className="checkout-button btn-block"
              variant="outline-secondary"
              type="submit"
            >
              Create Product
            </CreateProductButton>
          </div>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default CreateProductScreen;
