/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  FormControl,
  Row,
  Pagination,
  Tabs,
  Tab,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import axios from '../utils/axios';
import { BASE_URL } from '../utils/appconstants';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { listMyOrders } from '../actions/orderActions';
import './profile.scss';
import Loader from '../components/Loader';

function ProfilePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [showOrder, setOrderShow] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [user, setUser] = useState(null);
  const [filters, setFilters] = useState({
    totalPrice: '',
    createdAt: '',
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false); // Add loading state for payment

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`api/users/update/edit/${userInfo?._id}/`, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'Content-type': 'application/json',
        },
      });
      const userData = response.data;
      setUser(userData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!userInfo) {
      navigate('/authenticate');
    } else {
      dispatch(listMyOrders());
      fetchUserData();
    }
  }, [dispatch, navigate, userInfo]);

  const handleInputChange = (e) => {
    const {
      name, value, type, checked,
    } = e.target;
    const inputValue = type === 'checkbox' ? checked : value;

    setUser((prevUser) => ({
      ...prevUser,
      [name]: inputValue,
    }));
  };

  const handleFileInputChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleSaveClick = () => {
    setEditMode(false);
    const formData = new FormData();

    if (selectedFile) {
      formData.append('profile_photo', selectedFile);
    }

    formData.append('email', user.email || '');
    formData.append('first_name', user.first_name || '');
    formData.append('last_name', user.last_name || '');
    formData.append('phone_number', user.phone_number || '');
    formData.append('address', user.address || '');

    // Check if user.date_of_birth is a valid date
    const dateOfBirth = moment(user.date_of_birth, 'YYYY-MM-DD');
    const isValidDate = dateOfBirth.isValid();

    if (isValidDate) {
      const formattedDateOfBirth = dateOfBirth.format('YYYY-MM-DD');
      formData.append('date_of_birth', formattedDateOfBirth);
    } else {
      // Handle invalid date_of_birth value
      toast.error('Invalid date_of_birth value');
      return;
    }

    axios
      .put(`api/users/update/edit/${userInfo._id}/`, formData, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        fetchUserData();
      })
      .catch((error) => {
        toast.error('Error while updating user');
      });
  };

  const completeOrderPayment = async (amount, orderId) => {
    try {
      setLoadingPayment(true); // Set loadingPayment to true before making the request

      const response = await axios.post('/api/orders/update/complete/', { amount }, {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      });

      setLoadingPayment(false); // Set loadingPayment to false after the request is processed

      if (response.status === 200) {
        localStorage.setItem('completeOrderId', orderId);
        // Payment initiated successfully, redirect to the provided URL
        navigate('/order/payement/pesapal/complete/', {
          state: response.data.redirect_url,
          all: {
            amount,
          },

        });
        // const redirectUrl = response.data.redirect_url;
        // window.location.href = redirectUrl;
      } else {
        // Handle error response
        toast.error('Unable to process the order at the moment. Please try again later.');
      }
    } catch (error) {
      // Handle request error
      toast.error('Unable to process the order at the moment. Please try again later.');
      setLoadingPayment(false); // Set loadingPayment to false in case of an error
    }
  };

  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading: loadingOrders, error: errorOrders, orders } = orderListMy;

  const handleSelectedOrder = (order) => {
    setSelectedOrder(order);
    setOrderShow(true);
  };

  const itemsPerPage = 10;
  const filteredOrders = orders?.filter((order) => {
    const { totalPrice, createdAt } = filters;

    if (totalPrice && order.totalPrice !== Number(totalPrice)) {
      return false;
    }

    if (createdAt && !moment(order.createdAt).isSame(createdAt, 'day')) {
      return false;
    }

    return true;
  });

  const totalPages = Math.ceil(filteredOrders && filteredOrders.length / itemsPerPage);
  const paginatedOrders = filteredOrders?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalItems = filteredOrders && filteredOrders.length;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const renderOrderRows = () => {
    const paginatedData = filteredOrders?.slice(startIndex, endIndex);

    return (
      paginatedData
      && paginatedData.map((order, index) => (
        <Row className="my-row p-md-3">
          <div className="order-details">
            <h6 className={`float-left ${order?.deliveredAt ? 'text-success' : ''}`}>
              Order ID -
              {' '}
              {order?.order_tracking_id.split('-')[0]}
            </h6>

            <div className="buttons-container">
              {!order?.isFullyPaid && (
              <>
                {loadingPayment ? ( // Show the loader when the payment is being processed
                  <Loader />
                ) : (
                  <Button
                    size="sm"
                    onClick={() => completeOrderPayment(
                      order.totalPrice * 1 - order.paidAmount * 1,
                      order.id,
                    )}
                    variant="outline-info"
                    className="complete-payment-button"
                  >
                    Complete Order Payment
                  </Button>
                )}
              </>
              )}
              <Button
                size="sm"
                onClick={() => navigate(`/orders/${order.id}`)}
                variant="outline-warning"
                className="see-order-details-button"
              >
                See Order Details
              </Button>
            </div>
          </div>
        </Row>
      ))
    );
  };

  return (
    <>
      <Header />
      <Container className="container page-content-wrapper mt-2">
        <div className="main-body">

          <div className="row gutters-sm mb-15">
            <div className="col-md-4 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    <img
                      style={{ display: 'block' }}
                      src={
                        `${BASE_URL}${user?.profile_photo}` === `${BASE_URL}/media/placeholder.png`
                          ? 'https://bootdey.com/img/Content/avatar/avatar7.png'
                          : `${BASE_URL}${user?.profile_photo}`
                      }
                      alt="Admin"
                      className="rounded-circle"
                      width="150"
                    />

                    <div className="mt-3">
                      <h4>
                        {user?.first_name}
                        {' '}
                        {user?.last_name}
                      </h4>
                      <p className="text-secondary mb-1">
                        Customer:
                        {' '}
                        {user?.customer_id}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">First Name</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {editMode ? (
                        <FormControl
                          type="text"
                          name="first_name"
                          value={user?.first_name || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        user?.first_name
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Last Name</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {editMode ? (
                        <FormControl
                          type="text"
                          name="last_name"
                          value={user?.last_name || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        user?.last_name
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Email</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {editMode ? (
                        <FormControl
                          type="email"
                          name="email"
                          value={user?.email || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        user?.email
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Phone</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {editMode ? (
                        <FormControl
                          type="tel"
                          name="phone_number"
                          value={user?.phone_number || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        user?.phone_number
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Address</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {editMode ? (
                        <FormControl
                          type="text"
                          name="address"
                          value={user?.address || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        user?.address
                      )}
                    </div>
                  </div>
                  <hr />

                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">DOB</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {editMode ? (
                        <FormControl
                          type="date"
                          name="date_of_birth"
                          min="0001-01-01"
                          max="9999-12-31"
                          value={user?.date_of_birth || ''}
                          onChange={handleInputChange}
                        />
                      ) : (
                        user?.date_of_birth && moment(user?.date_of_birth).format('LLLL')
                      )}
                    </div>
                  </div>
                  <hr />

                  {editMode && (
                    <>
                      <div className="row">
                        <div className="col-sm-3">
                          <h6 className="mb-0">Profile Photo</h6>
                        </div>
                        <div className="col-sm-9 text-secondary">
                          <FormControl
                            type="file"
                            accept="image/*"
                            onChange={handleFileInputChange}
                          />
                        </div>
                      </div>
                      <hr />
                    </>
                  )}
                  {editMode ? (
                    <div className="row">
                      <div className="col-sm-12">
                        <Button variant="info" onClick={handleSaveClick}>
                          Save
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-sm-12">
                        <Button variant="info" onClick={handleEditClick}>
                          <span className="material-icons">edit</span>
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Tabs defaultActiveKey="orders" id="profile-tab">
            <Tab eventKey="orders" title="My Orders">
              {renderOrderRows()}
            </Tab>
          </Tabs>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default ProfilePage;
