/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Button, Offcanvas, Stack } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from '../utils/axios';
import CreateCourseForm from './Admin/CreateCurriculum';

function AdminCurriculumScreen() {
  const [curriculums, setCurriculums] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedCurriculum, setCurriculum] = useState({});
  const [viewCurriculum, setViewCurriculum] = useState(false);
  const [curriculumDetails, setSelectedCurriculum] = useState({});

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const fetchCurriculumns = async () => {
    try {
      const response = await axios.get('/api/curriculums/', {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      setCurriculums(response.data);
    } catch (error) {
      toast.error('Error while fetching curriculums');
    }
  };

  useEffect(() => {
    fetchCurriculumns();
  }, []);

  const deleteHandler = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this curriculum?');
    if (confirmDelete) {
      try {
        await axios.delete(`/api/curriculums/${id}/`, {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        toast.success('Curriculum deleted successfully');
        fetchCurriculumns();
      } catch (err) {
        toast.error('Error while deleting Curriculum');
      }
    }
  };

  const handleCurriculumModalClose = () => setShow(false);
  const handleCurriculumModalShow = (cur) => {
    setShow(true);
    setCurriculum(cur);
  };

  const handleViewCurriculum = (curriculum) => {
    setSelectedCurriculum(curriculum);
    setViewCurriculum(true);
  };

  const handleCloseCurriculum = () => {
    setViewCurriculum(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="main-card mb-3 card">
            <div className="card-header">
              Courses
              <div className="btn-actions-pane-right">
                <div role="group" className="btn-group-sm btn-group">
                  <Button
                    onClick={() => handleCurriculumModalShow('')}
                    className="active btn btn-focus"
                  >
                    <i className="fas fa-plus" />
                    &nbsp;
                    Create Curriculum
                  </Button>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="align-middle mb-0 table table-borderless table-striped table-hover">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th>Title</th>
                    <th className="text-center">Price</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {curriculums && curriculums.map((item) => (
                    <tr key={item.id}>
                      <td className="text-center text-muted">{item.id}</td>
                      <td>
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3">
                              <div className="widget-content-left">
                                <img width="40" loading="lazy" className="rounded-circle" src="assets/images/avatars/4.jpg" alt="" />
                              </div>
                            </div>
                            <div className="widget-content-left flex2">
                              <div className="widget-heading">{item.title}</div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="badge badge-warning">{item.price}</div>
                      </td>

                      <td className="text-center">
                        <Stack direction="horizontal" gap={3}>
                          <Button
                            onClick={() => handleCurriculumModalShow(item)}
                            variant="light"
                            className="btn-sm"
                          >
                            <i className="fas fa-edit" />
                          </Button>

                          <Button variant="danger" className="btn-sm" onClick={() => deleteHandler(item.id)}>
                            <i className="fas fa-trash" />
                          </Button>

                          <Button
                            variant="info"
                            className="btn-sm"
                            onClick={() => handleViewCurriculum(item)}
                          >
                            view Curriculum
                          </Button>
                        </Stack>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {viewCurriculum && (
      <Offcanvas show={viewCurriculum} onHide={handleCloseCurriculum} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h3>{curriculumDetails?.title}</h3>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* Display curriculum details here */}
          <div className="curriculum-details">
            <img
              src={curriculumDetails?.image}
              width="100%"
              style={{ display: 'block' }}
              className="curriculum-image"
              loading="lazy"
              alt="Curriculum"
            />
            <p>
              <strong>
                Description:
              </strong>
              {' '}
              <br />
              {curriculumDetails?.description}
            </p>
            <div className="curriculum-info">
              <p>
                <strong>Price:</strong>
                {' '}
                {curriculumDetails?.price}
              </p>
              <p>
                <strong>Location:</strong>
                {' '}
                {curriculumDetails?.location?.name}
              </p>
              <p>
                <strong>Instructor:</strong>
                {' '}
                <br />
                {curriculumDetails?.instructor?.user?.first_name}
                {' '}
                {curriculumDetails?.instructor?.user?.last_name}
              </p>
              <p>
                <strong>Instructor Bio:</strong>
                {' '}
                {curriculumDetails?.instructor?.bio}
              </p>
              <p>
                <strong>Classes:</strong>
              </p>
              <ul>
                {curriculumDetails?.courses.map((course) => (
                  <li key={course.id}>
                    <strong>Course ID:</strong>
                    {' '}
                    {course.id}
                    <br />
                    <strong>Start Date:</strong>
                    {' '}
                    {course.start_date}
                    <br />
                    <strong>End Date:</strong>
                    {' '}
                    {course.end_date}
                    <br />
                    <strong>Seats Available:</strong>
                    {' '}
                    {course.seats_available}
                    <br />
                    <strong>Enrollments:</strong>
                    {' '}
                    {course.enrollments.join(', ')}
                  </li>
                ))}
              </ul>
              <p>
                <strong>Lessons:</strong>
              </p>
              <ul>
                {(curriculumDetails && curriculumDetails.lessons
                  ? JSON.parse(curriculumDetails.lessons) : []).map(
                  (lesson, index) => (
                    <li key={index}>{lesson}</li>
                  ),
                )}
              </ul>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      )}

      {show && (
      <CreateCourseForm
        show={show}
        handleCurriculumModalClose={handleCurriculumModalClose}
        curriculum={selectedCurriculum}
        fetchCurriculumns={fetchCurriculumns}
        userInfo={userInfo}
      />
      )}
    </>
  );
}

export default AdminCurriculumScreen;
