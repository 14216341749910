/* eslint-disable max-len */
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../components/Header';
import Footer from '../components/Footer';

function HowDidThisAllBegun() {
  return (
    <>
      <Header />
      <Container className="page-content-wrapper my-4">
        <Row>
          <Col>
            <h3 className="how-it-started-container">How did this all begin?</h3>
            <p>
              The Cakes & Cakes Academy, a subsidiary of the renowned Cakes & Cakes Bakery, is dedicated to enhancing the skills of cake and pastry baking enthusiasts, aspiring entrepreneurs, and individuals seeking a career in the bakery and pastry industry. Our academy aims to become a prestigious pastry school, producing competitive professional Baking and Pastry chefs. Graduates of our academy will excel in creating master-class cakes, pastries, and confectionery, and possess the ability to secure employment in the dynamic bakery industry.
            </p>
            <p>Operating in a constantly evolving and competitive training environment, The Cakes & Cakes Academy understands the importance of staying up-to-date with cutting-edge technology, certifications, and training approaches. We are committed to remaining relevant to the ever-changing needs of 21st-century learners, striving to meet industry standards. With over 10 years of operational experience as an expert in skills development and an active player in the industry, our objectives include:</p>
            <ul>
              <li>Providing comprehensive training through short courses that cover pastry and baking from basic to advanced levels. Upon successful completion of our courses, students receive locally and internationally recognized certificates.</li>
              <li>Designing a practical and internationally recognized curriculum tailored to develop industry-relevant skills and knowledge. Our goal is to address the shortage of qualified pastry chefs, both locally and internationally, by producing well-trained talent.</li>
              <li>Establishing strategic partnerships with key players in the local and international restaurant, hospitality, cruise ship, and institutional catering industry. Through these partnerships, our successful graduates can secure meaningful job opportunities and ensure long-term job security.</li>
              <li>Expanding our reach and operations at Cakes and Cakes Bakery Ltd to create opportunities for the internal absorption of top graduates from our cake academy. This dual role enables us to both develop and recruit bakery and pastry talent.</li>
            </ul>
            <p>At The Cakes & Cakes Academy, we are dedicated to providing excellent training and education, empowering our students to thrive in the bakery and pastry industry. Moreover, we emphasize a strong focus and adherence to food safety, standards, and training in bakery and pastry. Recognizing the paramount importance of maintaining high standards of food safety and hygiene, we incorporate comprehensive training on food handling, sanitation, and quality control into our curriculum. We aim to cultivate a culture of food safety among our students, contributing to the development of a responsible and reliable workforce in the field.</p>
            <p>Join us at The Cakes & Cakes Academy as we embark on a journey to shape the future of baking and pastry making, with an unwavering commitment to excellence, innovation, and food safety. Together, we can create a thriving and sustainable bakery industry while producing skilled professionals who uphold the highest standards of craftsmanship and culinary safety.</p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default HowDidThisAllBegun;
