/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import whatsapp from '../../assets/images/w.png';
import './whatsapp.scss';

function WhatsAppButton() {
  const handleWhatsAppLinkClick = () => {
    const phoneNumber = '115152854';
    const message = ''; // Empty text

    window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`);
  };

  return (
    <div className="floating-whatsapp-button">
      <div
        className="whatsapp-icon"
        onClick={handleWhatsAppLinkClick}
      >
        <img src={whatsapp} alt="WhatsApp Icon" />
      </div>
    </div>
  );
}

export default WhatsAppButton;
