import React from 'react';
import { Container } from 'react-bootstrap';

function FooterBanner() {
  return (
    <Container fluid>
      <div className="text-center">
        <small>
          Made with
          {' '}
          <i className="fa-solid fa-heart" />
          {' '}
          by Tekkies.com
        </small>
      </div>
    </Container>
  );
}

export default FooterBanner;
