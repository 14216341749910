/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Alert, Card, Col, Container, Image, ListGroup, Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { getLocationDetails, listLocations } from '../../actions/locationAction';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import { LocationCard } from '../../components/styled';
import LocationMapScreen from './LocationMapScreen';
import SingleLocationBreadCrumb from '../../components/breadcrumb/singleLocationCrumb';

function SingleLocationScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const locationsList = useSelector((state) => state.locationsList);
  const { loading, error, locations } = locationsList;
  const locationDetails = useSelector((state) => state.locationDetails);
  const { location: singleLocation } = locationDetails;

  useEffect(() => {
    dispatch(listLocations());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getLocationDetails(id));
  }, [id]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  return (
    <>
      <Header />
      <Container className="mb-5 mt-5 page-content-wrapper">
        <SingleLocationBreadCrumb location={singleLocation} />
        <br />
        <Row>
          <Col md={6} className="py-3">
            <Card className="location-image-card">
              <Image style={{ display: 'block' }} loading="lazy" src={singleLocation.location_image} fluid rounded />
            </Card>
          </Col>
          <Col md={6} className="border-secondary pl-md-5">
            <Card className="location-details-card">
              <a
                href={singleLocation.url}
                target="_blank"
                className="text-decoration-none location-link mb-3 d-block text-secondary fw-bold hover-text"
                rel="noreferrer"
              >
                {' '}
                View Location on the Map
                {' '}
                {/* <i className="fa-solid fa-location-dot" /> */}
              </a>

              <ListGroup variant="flush" className="py-3">
                <ListGroup.Item className="d-flex align-items-center py-3 hover-bg">
                  <h6 className="m-0 text-secondary flex-grow-1">Name:</h6>
                  <h6 className="m-2 ml-3 flex-grow-1">{singleLocation.name}</h6>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex align-items-center py-3 hover-bg">
                  <h6 className="m-0 text-secondary flex-grow-1">Address:</h6>
                  <h6 className="m-2 ml-3 flex-grow-1">{singleLocation.address}</h6>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex align-items-center py-3 hover-bg">
                  <h6 className="m-0 text-secondary flex-grow-1">Contact:</h6>
                  <h6 className="m-2 ml-3 flex-grow-1">{singleLocation.contact}</h6>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
        </Row>
        <br />
        {locations?.length > 1 && <h3>Our Other Locations</h3>}
        <br />
        <Row>
          {locations.map((location, idx) => (
            singleLocation.id !== location.id && (
              <Col key={idx} xs={12} sm={6} md={3}>
                <LocationCard
                  onClick={() => navigate(`/locations/${location.id}`)}
                  style={{ width: '100%' }}
                >
                  <LocationCard.Img
                    style={{ display: 'block', width: '100%' }}
                    variant="top"
                    src={location.location_image}
                  />
                  <LocationCard.Body>
                    <h6>
                      Name:
                      {' '}
                      {location.name}
                    </h6>
                    <h6>
                      Address:
                      {' '}
                      {location.address}
                    </h6>
                  </LocationCard.Body>
                </LocationCard>
              </Col>
            )
          ))}
        </Row>
      </Container>
      <br />
      <br />
      <Footer />
      {show && (
        <LocationMapScreen show={show} name={singleLocation.name} handleClose={handleClose} />
      )}
    </>
  );
}

export default SingleLocationScreen;
