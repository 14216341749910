import React from 'react';
import { Col, Image } from 'react-bootstrap';
import Image1 from '../assets/images/1.png';
import Image2 from '../assets/images/2.png';
import Image3 from '../assets/images/3.png';
import Image4 from '../assets/images/4.png';
import { CustomContainer, CustomRow } from './styled';

function ImagesRow() {
  return (
    <div className="d-none d-sm-block">
      <CustomContainer className="padded-container" fluid>
        <h3 style={{ textAlign: 'center', margin: '10' }}>Ready for a party? We’ll bring Cake</h3>
        <CustomRow className="no-gutters">
          <Col xs={12} sm={12} md={4} lg={3}>
            <Image loading="lazy" src={Image1} alt="Image 1" style={{ width: '100%', height: '100%' }} />
          </Col>
          <Col xs={12} sm={12} md={4} lg={3}>
            <Image loading="lazy" src={Image2} alt="Image 2" style={{ width: '100%', height: '100%' }} />
          </Col>
          <Col xs={12} sm={12} md={4} lg={3}>
            <Image loading="lazy" src={Image3} alt="Image 3" style={{ width: '100%', height: '100%' }} />
          </Col>
          <Col xs={12} sm={12} md={4} lg={3}>
            <Image loading="lazy" src={Image4} alt="Image 4" style={{ width: '100%', height: '100%' }} />
          </Col>
        </CustomRow>
      </CustomContainer>
    </div>
  );
}

export default ImagesRow;
