/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  Alert,
  Pagination,
  Stack,
  Col,
  Form,
  Container,
  Row,
  Offcanvas,
  Image,
  Card,
  ListGroup,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import { CustomFormControl, CustomLabel } from '../components/styled';
import { BASE_URL } from '../utils/appconstants';
import axios from '../utils/axios';
import { numberWithCommas } from '../utils/utlilities';

function OrderScreenData({ history }) {
  const dispatch = useDispatch();
  const [showEditOrder, setEditOrderShow] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [showOrder, setOrderShow] = useState(false);
  const [orders, setOrders] = useState([]);
  const [filters, setFilters] = useState({
    totalPrice: '',
    createdAt: '',
  });
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const handleShowOffcanvas = (item) => {
    setSelectedOrder(item);
    setShowOffcanvas(true);
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success } = userUpdateProfile;

  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  const fetchOrders = async () => {
    try {
      const response = await axios.get('/api/orders/', config);
      setOrders(response.data); // Assuming the orders are in the response data
    } catch (error) {
      toast.error('Failed to fetch orders.');
    }
  };

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    } else if (!user || !user.name || success || userInfo._id !== user._id) {
      fetchOrders();
    }
  }, [dispatch, history, userInfo, user, success]);

  const handleSelectedOrder = (order) => {
    setSelectedOrder(order);
    setOrderShow(true);
  };

  const handleEditSelectedOrder = (order) => {
    setSelectedOrder(order);
    setEditOrderShow(true);
  };

  const handleDeleteOrder = async (orderId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this order?');
    if (confirmDelete) {
      try {
        await axios.delete(`/api/orders/${orderId}/delete/`, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        });
        // Assuming your API endpoint for deleting orders is '/api/orders/:id'
        toast.success('Order deleted successfully.');
      } catch (error) {
        toast.error('Failed to delete order. Please try again later.');
      }
    }
  };

  const itemsPerPage = 10; // Number of items to display per page

  // Filtered and sorted data based on the current filters
  const filteredOrders = orders
    ? orders.filter((item) => {
      if (filters.totalPrice && !item.totalPrice.includes(filters.totalPrice)) {
        return false;
      }
      if (filters.createdAt && !item.created_date.includes(filters.created_date)) {
        return false;
      }
      return true;
    })
    : [];

  const totalItems = filteredOrders.length; // Total number of items

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const renderTableRows = () => {
    const paginatedData = filteredOrders.slice(startIndex, endIndex);

    return paginatedData.map((item, index) => (
      <tr key={item.id}>
        <td className="text-center text-muted">{item.order_id}</td>
        <td className="text-center text-muted">{item && item.user.first_name}</td>
        <td className="text-center">
          {item ? moment(item.created_date).format('LLLL') : ''}
        </td>
        <td className="text-center">{item && numberWithCommas(item.totalPrice)}</td>
        <td className="text-center">{item && numberWithCommas(item.paidAmount)}</td>
        <td className="text-center">
          {item.isFullyPaid ? (
            <i className="fas fa-check" style={{ color: 'green' }} />
          ) : (
            numberWithCommas(Math.ceil(item.totalPrice - item.paidAmount))
          )}
        </td>
        <td className="text-center">{item && item.payment_method}</td>
        <td className="text-center">
          <Button
            onClick={() => handleDeleteOrder(item.id)}
            variant="danger"
            className="btn-sm"
          >
            <i className="fas fa-trash" />
          </Button>
          {' '}
          <Button
            variant="info"
            className="btn-sm"
            onClick={() => handleShowOffcanvas(item)}
          >
            View Order
          </Button>
        </td>
      </tr>
    ));
  };

  const renderOffcanvasContent = () => (
    <Offcanvas.Body>
      {selectedOrder && (
      <div>
        <h4>Order Details</h4>
        <p>
          Order ID:
          {selectedOrder.id}
        </p>
        {/* Render additional order details here */}
      </div>
      )}
    </Offcanvas.Body>
  );
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="main-card mb-3 card">
            <div className="card-header">
              Orders
              <div className="btn-actions-pane-right">
                <div role="group" className="btn-group-sm btn-group" />
              </div>
            </div>

            <div className="table-responsive">
              <div className="filters">
                <Container>
                  <Row className="mt-0 pb-3 row-1 mb-0 px-sm-3 px-2">
                    <Col>
                      <Form.Group controlId="totalPriceFilter">
                        <CustomLabel>Total Price</CustomLabel>
                        <CustomFormControl
                          type="text"
                          name="totalPrice"
                          value={filters.totalPrice}
                          onChange={handleFilterChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="createdAtFilter">
                        <CustomLabel>Created At</CustomLabel>
                        <CustomFormControl
                          type="date"
                          name="createdAt"
                          value={filters.createdAt}
                          onChange={handleFilterChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Container>
              </div>
              <Table className="align-middle mb-0 table table-borderless table-striped table-hover">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th className="text-center">Customer</th>
                    <th className="text-center">Ordered At</th>
                    <th className="text-center">Total Price</th>
                    <th className="text-center">Paid Amount</th>
                    <th className="text-center">is Fully Paid</th>
                    <th className="text-center">Payment Method</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>

                <tbody>
                  {renderTableRows()}
                </tbody>
              </Table>
            </div>
            <div className="d-block text-center card-footer">
              <Pagination
                size="lg"
                className="custom-pagination"
                style={{ float: 'right' }}
              >
                <Pagination.Prev
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {Array.from({ length: Math.ceil(totalItems / itemsPerPage) }, (_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={currentPage === index + 1}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
                />
              </Pagination>
            </div>
          </div>
        </div>
      </div>

      <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {selectedOrder.order_tracking_id}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {selectedOrder && (
          <>
            <div>
              <ListGroup>
                <ListGroup.Item>
                  <strong>Date:</strong>
                  {' '}
                  {new Date(selectedOrder.created_date).toLocaleDateString()}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Payment Method:</strong>
                  {' '}
                  {selectedOrder.payment_method}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Total Price:</strong>
                  {' '}
                  {selectedOrder.totalPrice}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Paid Amount:</strong>
                  {' '}
                  {selectedOrder.paidAmount}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Tax Price:</strong>
                  {' '}
                  {selectedOrder.taxPrice}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Shipping Price:</strong>
                  {' '}
                  {selectedOrder.shippingPrice}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Is Delivered:</strong>
                  {' '}
                  {selectedOrder.isDelivered ? 'Yes' : 'No'}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Is Fully Paid:</strong>
                  {' '}
                  {selectedOrder.isFullyPaid ? 'Yes' : 'No'}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Delivered At:</strong>
                  {' '}
                  {selectedOrder.deliveredAt ? moment(selectedOrder.deliveredAt).format('LLLL') : 'N/A'}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Order Tracking ID:</strong>
                  {' '}
                  {selectedOrder.order_tracking_id}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Confirmation Code:</strong>
                  {' '}
                  {selectedOrder.confirmation_code || 'N/A'}
                </ListGroup.Item>
                {/* <ListGroup.Item>
                  <strong>Call Back URL:</strong>
                  {' '}
                  {selectedOrder.call_back_url || 'N/A'}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Merchant Reference:</strong>
                  {' '}
                  {selectedOrder.merchant_reference || 'N/A'}
                </ListGroup.Item> */}
                <ListGroup.Item>
                  <strong>Currency:</strong>
                  {' '}
                  {selectedOrder.currency}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Payment Account:</strong>
                  {' '}
                  {selectedOrder.payment_account || 'N/A'}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Description:</strong>
                  {' '}
                  {selectedOrder.description || 'N/A'}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Amount:</strong>
                  {' '}
                  {selectedOrder.amount}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Payment Status Description:</strong>
                  {' '}
                  {selectedOrder.payment_status_description || 'N/A'}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Company:</strong>
                  {' '}
                  {selectedOrder.company || 'N/A'}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Street/Road:</strong>
                  {' '}
                  {selectedOrder.street_road || 'N/A'}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Building/Apartment:</strong>
                  {' '}
                  {selectedOrder.building_apartment || 'N/A'}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Is Fully Paid:</strong>
                  {' '}
                  {selectedOrder.is_FullyPaid ? 'Yes' : 'No'}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Location:</strong>
                  {' '}
                  {selectedOrder.location || 'N/A'}
                </ListGroup.Item>
              </ListGroup>
            </div>
            <br />
            <section>
              {' '}
              <h6>Order Items:</h6>
              <div className="order-items-list">
                {(selectedOrder?.orderItems ?? []).map((item) => (
                  <Card key={item.id} className="item-card">
                    <Card.Body>
                      <Image
                        loading="lazy"
                        src={`${BASE_URL}/media/${item.image}`}
                        alt={item.name}
                        fluid
                        rounded
                        width={90}
                        className="item-image"
                      />
                      <Card.Title className="item-name">{item.name}</Card.Title>
                      <Card.Text className="item-price">
                        Price:
                        {' '}
                        {item.price}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ))}
              </div>
            </section>

          </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default OrderScreenData;
