/* eslint-disable no-unused-vars */
/* eslint-disable react/style-prop-object */
/* eslint-disable no-script-url */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect } from 'react';
// import WeightTable from '../../components/WeightTable';
import {
  Card, Col, Image, Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import cakeImage from '../../assets/images/Page 1_3.png';
import AdminEccomerceTable from '../AdminScreen';
import { listTotalWeight } from '../../actions/weightAction';
import { listTotalCategory } from '../../actions/categoryAction';
import { listTotalLocations } from '../../actions/locationAction';
import { listTotalProduct } from '../../actions/productActions';
import { listTotalUsers, logout } from '../../actions/userActions';
import { listTotalOrder, getRevenue } from '../../actions/orderActions';

function AdminHome() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listTotalWeight());
      dispatch(listTotalCategory());
      dispatch(listTotalLocations());
      dispatch(listTotalProduct());
      dispatch(listTotalUsers());
      dispatch(listTotalOrder());
      dispatch(getRevenue());
    } else {
      navigate('/login');
    }
  }, [dispatch, navigate, userInfo]);

  const logoutHandler = () => {
    dispatch(logout());
    navigate('/');
  };

  const weightListTotal = useSelector((state) => state.weightListTotal);
  const { total: totalWeights } = weightListTotal;

  const productTotalList = useSelector((state) => state.productTotalList);
  const { total: totalProducts } = productTotalList;

  const userListTotal = useSelector((state) => state.userListTotal);
  const { total: totalUsers } = userListTotal;

  const categoriesListTotal = useSelector((state) => state.categoriesListTotal);
  const { total: totalCategories } = categoriesListTotal;

  const orderListTotal = useSelector((state) => state.orderListTotal);
  const { total: totalOrders } = orderListTotal;

  const locationListTotal = useSelector((state) => state.locationListTotal);
  const { total: totalLocations } = locationListTotal;

  const orderRevenue = useSelector((state) => state.orderRevenue);
  const { revenue } = orderRevenue;

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
      <div className="app-header header-shadow">
        <div className="app-header__logo">
          {/* <div className="logo-src" /> */}
          <div className="header__pane ml-auto">
            <div>
              <button type="button" className="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="app-header__mobile-menu">
          <div>
            <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
          </div>
        </div>
        <div className="app-header__menu">
          <span>
            <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
              <span className="btn-icon-wrapper">
                <i className="fa fa-ellipsis-v fa-w-6" />
              </span>
            </button>
          </span>
        </div>
        {' '}
        <div className="app-header__content">
          <div className="app-header-left">
            <div className="search-wrapper">
              <div className="input-holder">
                <input type="text" className="search-input" placeholder="Type to search" />
                <button className="search-icon"><span /></button>
              </div>
              <button className="close" />
            </div>
          </div>
          <div className="app-header-right">
            <div className="header-btn-lg pr-0">
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left">
                    <div className="btn-group">
                      <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="p-0 btn">
                        <img
                          width="42"
                          loading="lazy"
                          className="rounded-circle"
                          src="assets/images/avatars/1.jpg"
                          alt=""
                        />
                        <i className="fa fa-angle-down ml-2 opacity-8" />
                      </a>
                      <div tabIndex="-1" role="menu" aria-hidden="true" className="dropdown-menu dropdown-menu-right">
                        <button type="button" tabIndex="0" onClick={() => navigate('/')} className="dropdown-item">View Site</button>
                        {/* <button type="button" tabIndex="0"
                        className="dropdown-item">Settings</button> */}
                        {/* <h6 tabIndex="-1" className="dropdown-header">Header</h6> */}
                        {/* <button type="button" tabIndex="0" className="dropdown-item">
                        Actions</button> */}
                        <div tabIndex="-1" className="dropdown-divider" />
                        <button type="button" tabIndex="0" onClick={logoutHandler} className="dropdown-item">Logout</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="app-main">
        <div className="app-sidebar sidebar-shadow">
          <div className="app-header__logo">
            <div className="logo-src" />
            <div className="header__pane ml-auto">
              <div>
                <button type="button" className="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                  <span className="hamburger-box">
                    <span className="hamburger-inner" />
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="app-header__mobile-menu">
            <div>
              <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </div>
          </div>
          <div className="app-header__menu">
            <span>
              <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                <span className="btn-icon-wrapper">
                  <i className="fa fa-ellipsis-v fa-w-6" />
                </span>
              </button>
            </span>
          </div>
          {' '}
          <div className="scrollbar-sidebar">
            <div className="app-sidebar__inner">
              <ul className="vertical-nav-menu">
                <li className="app-sidebar__heading">Shop Dashboard</li>
                <li>
                  <a href="index.html" className="mm-active">
                    <i className="metismenu-icon pe-7s-rocket" />
                    <Link to="/shop/admin">
                      View Shop
                    </Link>
                  </a>
                </li>
                <li className="app-sidebar__heading">Academy Dashboard</li>
                <li>
                  <a href="index.html" className="mm-active">
                    <i className="metismenu-icon pe-7s-rocket" />
                    <Link to="/shop/admin/courses">
                      View Academy
                    </Link>
                  </a>
                </li>
                <li className="app-sidebar__heading">Subscribers Dashboard</li>
                <li>
                  <a href="index.html" className="mm-active">
                    <i className="metismenu-icon pe-7s-rocket" />
                    <Link to="/shop/admin/subscribers">
                      View Subscribers
                    </Link>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className="app-page-title">
              <div className="page-title-wrapper">
                <div className="page-title-heading">
                  <div className="page-title-icon">
                    <Image loading="lazy" src={cakeImage} width={40} height={40} />
                  </div>
                  <div>
                    Cakes & cakes Dashboard
                    <div className="page-title-subheading">
                      Welcome to cakes and cakes Shop
                    </div>
                  </div>
                </div>
                <div className="page-title-actions">
                  <div className="d-inline-block dropdown">
                    <div tabIndex="-1" role="menu" aria-hidden="true" className="dropdown-menu dropdown-menu-right" />
                  </div>
                </div>
              </div>
            </div>
            {' '}
            <Row>
              <Col md={6} xl={4}>
                <Card className="mb-3 widget-content bg-midnight-bloom">
                  <Card.Body className="widget-content-wrapper text-white">
                    <div className="widget-content-left">
                      <div className="widget-heading">Total Orders&nbsp;</div>
                      <div className="widget-subheading">Lifetime Orders</div>
                    </div>
                    <div className="widget-content-right">
                      <div className="widget-numbers text-white">
                        <span>{totalOrders && totalOrders?.total_orders}</span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={6} xl={4}>
                <Card className="mb-3 widget-content  bg-arielle-smile text-white">
                  <Card.Body className="widget-content-wrapper text-white">
                    <div className="widget-content-left">
                      <div className="widget-heading">Total Customers &nbsp;</div>
                      <div className="widget-subheading">Users</div>
                    </div>
                    <div className="widget-content-right">
                      <div className="widget-numbers">
                        <span>{totalUsers && totalUsers?.total_users}</span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={6} xl={4}>
                <Card className="mb-3 widget-content bg-grow-early text-white">
                  <Card.Body className="widget-content-wrapper text-white">
                    <div className="widget-content-left">
                      <div className="widget-heading">Total Weights &nbsp;</div>
                      <div className="widget-subheading">Weights</div>
                    </div>
                    <div className="widget-content-right">
                      <div className="widget-numbers">
                        <span>{totalWeights && totalWeights.total_weights}</span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={6} xl={4}>
                <Card className="mb-3 widget-content bg-premium-dark">
                  <Card.Body className="text-white">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <Card.Title className="mb-0 text-warning">Total Products &nbsp;</Card.Title>
                        <Card.Text className="text-warning">Products</Card.Text>
                      </div>
                      <div className="widget-numbers text-warning">
                        <span>{totalProducts && totalProducts?.total_products}</span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} xl={4}>
                <Card className="mb-3 widget-content">
                  <Card.Body className="widget-content-outer">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Total Locations &nbsp;</div>
                        <div className="widget-subheading">Locations &nbsp;</div>
                      </div>
                      <div className="widget-content-right">
                        <div className="widget-numbers text-success">
                          {totalLocations && totalLocations?.total_locations}
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} xl={4}>
                <Card className="mb-3 widget-content">
                  <Card.Body className="widget-content-outer">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left">
                        <div className="widget-heading">Total Categories &nbsp;</div>
                        <div className="widget-subheading">Categories</div>
                      </div>
                      <div className="widget-content-right">
                        <div className="widget-numbers text-danger">
                          {totalCategories && totalCategories?.total_categories}
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

            </Row>
            <AdminEccomerceTable />
          </div>
          <div className="app-wrapper-footer">
            {/* <FooterBanner /> */}
          </div>
        </div>
        <script src="http://maps.google.com/maps/api/js?sensor=true" />
      </div>
    </div>
  );
}

export default AdminHome;
