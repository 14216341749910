/* eslint-disable no-unused-vars */
import { GoogleMap, Marker } from '@react-google-maps/api';
import React, { useCallback, useRef, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import mapStyles from './mapStyles';
import SearchMapInput from './searchMap';

const containerStyle = {
  height: '100vh',
  display: 'flex',
  boarder: '2px',
};

const options = {
  styles: mapStyles,
  disableDefaultUl: true,
  ZoomControl: true,
};

function LocationMapScreen({ show, name, handleClose }) {
  const [currentLocation, setCurrentLocation] = useState({
    lat: -1.3252563537572846,
    lng: 36.84980479734451,
  });

  const mapRef = useRef();
  //   const [form] = Form.useForm();

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(15);
  }, []);

  const {
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 100 * 1000,
    },
  });

  const handleInput = (e) => {
    setValue(e);
  };

  const optionsToDisplay = data.map((place) => ({
    label: place.description,
    value: place.description,
  }));

  const onMapClick = useCallback(
    (event) => {
      setCurrentLocation({
        ...currentLocation,
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    },
    [currentLocation],
  );

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const handleDragEnd = (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setCurrentLocation({ lat, lng });
  };

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={handleClose}
      fullscreen
    >
      <Modal.Header closeButton>
        <Modal.Title>{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <SearchMapInput
          placeholder="Type in specific addresses"
          onSearch={handleInput}
          onSelect={handleSelect}
          options={optionsToDisplay}
        /> */}

        <GoogleMap
          zoom={14}
          options={options}
          center={currentLocation}
          onLoad={onMapLoad}
          mapContainerStyle={containerStyle}
          onClick={onMapClick}
        >

          <Marker
            draggable
            onDragEnd={handleDragEnd}
            position={{
              ...currentLocation,
            }}
          />
        </GoogleMap>
      </Modal.Body>
    </Modal>
  );
}

export default LocationMapScreen;
