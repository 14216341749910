/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable radix */
import React, { useEffect, useState } from 'react';
import {
  Container, Row, Col, Form, Button,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CustomFormControl } from './styled';
import { numberWithCommas } from '../utils/utlilities';

function CakeToppings({ toppings, onToppingChange }) {
  const [selectedToppings, setSelectedToppings] = useState([]);

  const handleToppingChange = (event, topping) => {
    const isChecked = event.target.checked;
    const updatedToppings = isChecked
      ? [...selectedToppings, topping]
      : selectedToppings.filter((selectedTopping) => selectedTopping.id !== topping.id);
    setSelectedToppings(updatedToppings);
    onToppingChange(updatedToppings.reduce((total, top) => total + parseInt(top.price), 0));
  };

  return (
    <div>
      <h6>Choose your toppings:</h6>
      <Form.Group>
        {toppings.map((topping) => (
          <Form.Check
            key={topping.id}
            type="checkbox"
            id={`topping-${topping.id}`}
            label={`${topping.name} - Ksh ${numberWithCommas(topping?.price)}`}
            onChange={(e) => handleToppingChange(e, topping)}
            style={{ color: '#007080' }}
          />
        ))}
      </Form.Group>
    </div>
  );
}

function CakeList({
  min, max, cakes, onTotalPriceChange, onCakeChange,
}) {
  const [selectedCakes, setSelectedCakes] = useState([]);
  const [toppingPrice, setToppingPrice] = useState(0);

  const handleCheckboxChange = (event, cake) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      const updatedSelectedCakes = [...selectedCakes, { cake, quantity: 1 }];
      setSelectedCakes(updatedSelectedCakes);
      onCakeChange(updatedSelectedCakes); // Call the onCakeChange prop with the updated selected cakes
    } else {
      const updatedSelectedCakes = selectedCakes.filter(
        (selectedCake) => selectedCake.cake.id !== cake.id,
      );
      setSelectedCakes(updatedSelectedCakes);
      onCakeChange(updatedSelectedCakes); // Call the onCakeChange prop with the updated selected cakes
    }
  };

  const handleQuantityChange = (event, cake) => {
    const newSelectedCakes = [...selectedCakes];
    const index = newSelectedCakes.findIndex(
      (selectedCake) => selectedCake.cake.id === cake.id,
    );

    const enteredQuantity = parseInt(event.target.value);
    const validQuantity = Math.min(enteredQuantity, max); // Ensure the entered quantity doesn't exceed max

    if (enteredQuantity > max) {
      toast.warn(`You cannot exceed maximum quantity: ${max}`);
    }

    newSelectedCakes[index].quantity = validQuantity;
    setSelectedCakes(newSelectedCakes);
  };

  const handleIncrement = (cake) => {
    const newSelectedCakes = [...selectedCakes];
    const index = newSelectedCakes.findIndex(
      (selectedCake) => selectedCake.cake.id === cake.id,
    );
    const currentQuantity = newSelectedCakes[index].quantity;
    if (currentQuantity < Number(max)) {
      newSelectedCakes[index].quantity += 1;
      setSelectedCakes(newSelectedCakes);
    } else {
      toast.warn(`You cannot exceed max quantity: ${max}`);
    }
  };

  const handleDecrement = (cake) => {
    const newSelectedCakes = [...selectedCakes];
    const index = newSelectedCakes.findIndex(
      (selectedCake) => selectedCake.cake.id === cake.id,
    );
    const currentQuantity = newSelectedCakes[index].quantity;
    if (currentQuantity > Number(min)) {
      newSelectedCakes[index].quantity -= 1;
      setSelectedCakes(newSelectedCakes);
    } else {
      toast.warn(`You cannot go below min quantity: ${min}`);
    }
  };

  const handleToppingChange = (newToppingPrice) => {
    setToppingPrice(newToppingPrice);
  };

  const totalCakePrice = selectedCakes.reduce((total, { cake, quantity }) => total + (toppingPrice * quantity) + (cake.price * quantity), 0);

  useEffect(() => {
    onTotalPriceChange(totalCakePrice);
  }, [totalCakePrice, onTotalPriceChange]);

  return (
    <Row>
      <Col>
        <Form>
          {cakes.map((cake) => (
            <React.Fragment key={cake.id}>
              <Form.Group as={Row} controlId={`cake-${cake.id}`}>
                <Col md={8}>
                  <Form.Check
                    type="checkbox"
                    label={`${cake.cake_weight} cake - Ksh ${numberWithCommas(Number(cake?.price))}`}
                    onChange={(e) => handleCheckboxChange(e, cake)}
                    style={{ color: '#007080' }}
                  />
                  <style type="text/css">
                    {`
                      #my-checkbox:checked + .form-check-label::before {
                        background-color: #FD937E;
                        border-color: #FD937E;
                      }
                    `}
                  </style>
                </Col>
                <Col md={2}>
                  {selectedCakes.some((selectedCake) => selectedCake.cake.id === cake.id) && (
                    <div className="d-flex align-items-center">
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={() => handleDecrement(cake)}
                        disabled={selectedCakes.find((selectedCake) => selectedCake.cake.id === cake.id)?.quantity === Number(min)}
                      >
                        -
                      </Button>
                      <CustomFormControl
                        type="number"
                        style={{ width: '4rem' }}
                        value={selectedCakes.find((selectedCake) => selectedCake.cake.id === cake.id)
                          .quantity}
                        onChange={(e) => handleQuantityChange(e, cake)}
                        className="mx-2 text-center"
                        disabled={selectedCakes.find((selectedCake) => selectedCake.cake.id === cake.id)?.quantity === Number(max)}
                      />
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={() => handleIncrement(cake)}
                        disabled={selectedCakes.find((selectedCake) => selectedCake.cake.id === cake.id)?.quantity === Number(max)}
                      >
                        +
                      </Button>
                    </div>
                  )}
                </Col>
              </Form.Group>
              {selectedCakes.some((selectedCake) => selectedCake.cake.id === cake.id)
              && cake?.topping?.length > 0 && (
                <CakeToppings onToppingChange={handleToppingChange} toppings={cake.toppings} />
              )}
              <hr />
            </React.Fragment>
          ))}
        </Form>
      </Col>
    </Row>

  );
}

export default CakeList;
