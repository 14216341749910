import React from 'react';
import {
  Card, Col, Container, Row,
} from 'react-bootstrap';
import BreadcrumbComponent from '../components/BreadCrumb';
import Footer from '../components/Footer';
import Header from '../components/Header';
import LoginScreen from './LoginScreen';
import RegisterScreen from './RegisterScreen';

function AuthenticationScreen() {
  return (
    <>
      <Header />
      <Container className="page-content-wrapper" style={{ marginTop: '10px' }}>
        <BreadcrumbComponent />
        <Row className="row justify-content-center mt-0 pt-0 row-1 mb-0  px-sm-3 px-2">
          <Col xs={12} sm={6} md={6} lg={6} xl={6}>
            <LoginScreen />
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} xl={6} className="mb-3 mt-3 p-3">
            <Card style={{ borderColor: '#007080' }}>
              <Card.Body>
                <RegisterScreen />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default AuthenticationScreen;
