/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
  Form, Row, Col, Container, Card, Stack, ListGroup,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import Footer from '../components/Footer';
import Header from '../components/Header';
import {
  CreateProductButton, CustomFormControl, CustomLabel, CustomSelect,
} from '../components/styled';
import { listLocations } from '../actions/locationAction';
import axios from '../utils/axios';
import { listCurriculumDetails } from '../actions/curriculumnAction';
import Loader from '../components/Loader';
import { numberWithCommas } from '../utils/utlilities';

function EnrollmentForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [address, setAddress] = useState('');
  const [medicalConditions, setMedicalConditions] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [courseStartDate, setCourseStartDate] = useState('');
  const [courseTime, setCourseTime] = useState('');
  const [location, setLocation] = useState('');
  const { courseId, curriculumId } = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [myClasses, setMyClasses] = useState([]);

  const curriculumDetails = useSelector((state) => state.curriculumDetails);
  const { curriculum } = curriculumDetails;

  const locationsList = useSelector((state) => state.locationsList);
  const { locations } = locationsList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const course = curriculum && curriculum?.courses
    && (curriculum.courses.filter((opt) => opt._id === courseId))[0];

  useEffect(() => {
    dispatch(listLocations());
    dispatch(listCurriculumDetails(curriculumId));
  }, [dispatch, userInfo, curriculumId]);

  const fetchMyClasses = async () => {
    try {
      const response = await axios.get('/api/courses/course-times/');
      setMyClasses(response.data);
    } catch (error) {
      setMyClasses([]);
    }
  };

  useEffect(() => {
    fetchMyClasses();
  }, [courseId, curriculumId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const updatedCart = {
      courseId: course.id,
      curriculumId: curriculum.id,
      dateOfBirth,
      address,
      idNumber,
      medicalConditions,
      courseStartDate,
      courseTime: selectedTimeSlot,
      firstName,
      lastName,
      course,
      selectedTimeSlot,
    };

    // Store form values in localStorage
    localStorage.setItem('enrollmentFormValues', JSON.stringify(updatedCart));

    if (!userInfo) {
      // User is not authenticated, navigate to authenticate
      toast.info('Please login to enroll in the class.');
      navigate('/authenticate'); // Replace with the appropriate authentication route
      return;
    }

    try {
      const response = await axios.post(
        '/api/enrollments/initiate_course_payment/',
        {
          firstName,
          lastName,
          dateOfBirth,
          address,
          medicalConditions,
          idNumber,
          courseStartDate,
          courseTime: selectedTimeSlot,
          curriculum_id: curriculum.id,
          course_id: course.id,
          price: curriculum.price,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        },
      );

      setLoading(false);
      navigate('/order/payement/pesapal/', {
        state: response.data.redirect_url,
      });
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.error);
    }
  };

  const handleTimeSlotChange = (e) => {
    setSelectedTimeSlot(e.target.value);
    // toast.success(`Selected time slot: ${e.target.value}`);
  };

  return (
    <>
      <Header />
      <div className="enrollment-form-bg">
        <Container className="page-content-wrapper">
          <h3>{`Enroll to ${curriculum?.title}`}</h3>
          {loading && <Loader />}
          <br />
          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Form onSubmit={handleSubmit}>
                <br />
                <Form.Group controlId="firstName">
                  <CustomLabel>
                    First Name
                    <span className="asterisk">*</span>
                  </CustomLabel>
                  <CustomFormControl
                    className="w-100 required-field"
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </Form.Group>
                <br />
                <Form.Group controlId="lastName">
                  <CustomLabel>
                    Last Name
                    <span className="asterisk">*</span>
                  </CustomLabel>
                  <CustomFormControl
                    className="w-100 required-field"
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </Form.Group>
                <br />
                <Form.Group controlId="dateOfBirth">
                  <CustomLabel>
                    Date of Birth
                    <span className="asterisk">*</span>
                  </CustomLabel>
                  <CustomFormControl
                    className="w-100 required-field"
                    type="date"
                    value={dateOfBirth}
                    onChange={(e) => setDateOfBirth(e.target.value)}
                    required
                    min="0001-01-01"
                    max="9999-12-31"
                  />
                </Form.Group>
                <br />
                <Form.Group controlId="address">
                  <CustomLabel>
                    Address
                    <span className="asterisk">*</span>
                  </CustomLabel>
                  <br />
                  <CustomFormControl
                    className="w-100 required-field"
                    as="textarea"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  />
                </Form.Group>
                <br />
                <Form.Group controlId="medicalConditions">
                  <CustomLabel>
                    Medical Conditions
                    <span className="asterisk">*</span>
                  </CustomLabel>
                  <br />
                  <CustomFormControl
                    className="w-100 required-field"
                    as="textarea"
                    value={medicalConditions}
                    onChange={(e) => setMedicalConditions(e.target.value)}
                  />
                </Form.Group>
                <br />
                <Form.Group controlId="idNumber">
                  <CustomLabel>
                    National ID number
                    <span className="asterisk">*</span>
                  </CustomLabel>
                  <CustomFormControl
                    className="w-100 required-field"
                    type="number"
                    value={idNumber}
                    onChange={(e) => setIdNumber(e.target.value)}
                    required
                  />
                </Form.Group>
                <br />
                <Form.Group controlId="courseTime">
                  <CustomLabel>
                    Course Time
                    <span className="asterisk">*</span>
                  </CustomLabel>
                  <CustomSelect value={selectedTimeSlot} onChange={handleTimeSlotChange}>
                    <option key="Select">Select a time slot</option>
                    {myClasses.map((myClass) => (
                      <option key={myClass.id} value={myClass.id}>
                        {myClass.time_slot}
                      </option>
                    ))}
                  </CustomSelect>
                </Form.Group>
                <br />
                {/* <Form.Group controlId="location">
                  <CustomLabel>
                    Location
                    <span className="asterisk">*</span>
                  </CustomLabel>
                  <CustomSelect
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    required
                  >
                    <option value="">Locations Offered</option>
                    {(locations || []).map((loc) => (
                      <option value={loc.id} key={loc.id}>
                        {loc.name}
                      </option>
                    ))}
                  </CustomSelect>
                </Form.Group> */}
                <br />
                <br />
                <Card style={{ margin: '10px', borderColor: '#007080', padding: '15px' }}>
                  <Stack direction="horizontal" gap={3}>
                    <h4>Price</h4>
                    <h4 className="ms-auto">
                      Ksh
                      {' '}
                      {numberWithCommas(curriculum?.price)}
                    </h4>
                  </Stack>
                  <CreateProductButton variant="outline-secondary" type="submit">
                    Register
                    {' '}
                    <i className="fa-solid fa-caret-right" />
                  </CreateProductButton>
                </Card>
                <br />
              </Form>
            </Col>
            <Col xs={12} md={6} className="mb-3">
              <br />
              <br />
              <Card className="mb-3">
                <Card.Img
                  variant="top"
                  src={`${curriculum?.image}`}
                  style={{
                    height: '200px', objectFit: 'cover', display: 'block', width: '100%',
                  }}
                />
                <Card.Body>
                  <Card.Text>
                    {curriculum?.description}
                  </Card.Text>
                </Card.Body>
              </Card>
              {curriculum && curriculum?.courses && (
              <ListGroup>
                <ListGroup.Item>
                  <strong>Start Date:</strong>
                  {' '}
                  {course.start_date}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>End Date:</strong>
                  {' '}
                  {course.end_date}
                </ListGroup.Item>
              </ListGroup>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default EnrollmentForm;
