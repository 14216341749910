import React, { useEffect, useState } from 'react';
import {
  Form, Button, Alert, Container,
} from 'react-bootstrap';
// import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { CustomFormControl, CustomLabel } from '../components/styled';
import Loader from '../components/Loader';
import { adminStudentLogin } from '../actions/userActions';

function EmailPasswordScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((user) => user.userLogin);

  const { error, loading, userInfo } = userLogin;
  //   const { state } = useLocation();

  useEffect(() => {
    if (userInfo) {
      navigate('/');
    }
  }, [userInfo, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(adminStudentLogin(email, password));
  };

  return (
    <>
      <Header />

      <div className="center-div">

        <Form noValidate onSubmit={handleSubmit}>
          <div className="col-md-5 mx-auto" style={{ margin: '100px 300px 400px 300px' }}>
            { error && <Alert variant="danger">{error}</Alert>}
            { loading && <Loader />}
            <h1 style={{ margin: 'auto', width: '100%' }}>Sign In</h1>
            <br />
            <Container>
              <Form.Group controlId="formEmail">
                <CustomLabel>Email</CustomLabel>
                <CustomFormControl
                  required
                  size="lg"
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Form.Group>
              <br />
              <Form.Group controlId="formPassword">
                <CustomLabel>Password</CustomLabel>
                <CustomFormControl
                  required
                  size="lg"
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </Form.Group>
              <div className="d-grid gap-2">
                <Button variant="outline-light" style={{ backgroundColor: '#FFC87D', color: '#002D33', marginTop: '30px' }} type="submit">Sign In</Button>
              </div>
            </Container>
          </div>
        </Form>

      </div>

      <Footer />
    </>
  );
}

export default EmailPasswordScreen;
