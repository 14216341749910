/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';

function HowItStarted() {
  return (
    <>
      <h3 className="how-it-started-container">How did this all begin?</h3>
      <p>
        The Cakes & Cakes Academy, a subsidiary of the renowned Cakes & Cakes Bakery, is dedicated to enhancing the skills of cake and pastry baking enthusiasts, aspiring entrepreneurs, and individuals seeking a career in the bakery and pastry industry. Our academy aims to become a prestigious pastry school, producing competitive professional Baking and Pastry chefs. Graduates of our academy will excel in creating master-class cakes, pastries, and confectionery, and possess the ability to secure employment in the dynamic bakery industry.
        <Link to="/howdiditbegin">&nbsp;...Show More</Link>
      </p>
    </>
  );
}

export default HowItStarted;
