/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Alert, Col, Container, Form, InputGroup, Modal, Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  CreateProductButton,
  CustomFormControl, CustomInpuTGroup, CustomLabel, CustomSelect, DeleteUpload,
} from '../styled';
import { listLocations } from '../../actions/locationAction';
import { removeFromCart } from '../../actions/cartActions';
import Footer from '../Footer';
import Header from '../Header';
import PreviewImage from '../previewImage';
import { BASE_URL } from '../../utils/appconstants';
import axios from '../../utils/axios';
import Loader from '../Loader';
import { numberWithCommas } from '../../utils/utlilities';

function OrderPaymentScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const locationsList = useSelector((state) => state.locationsList);
  const { locations } = locationsList;

  cart.itemsPrice = cart.cartItems.reduce((acc, item) => acc + item.price, 0).toFixed(2);
  cart.shippingPrice = (cart.itemsPrice > 100 ? 0 : 10).toFixed(2);
  cart.taxPrice = Number((0.0082) * cart.itemsPrice).toFixed(2);

  // cart.totalPrice = (Number(cart.itemsPrice)
  //  + Number(cart.shippingPrice) + Number(cart.taxPrice)).toFixed(2);

  cart.totalPrice = Number(cart.itemsPrice);

  const [company, setCompany] = useState();
  const [street_road, setStreetRoad] = useState('');
  const [building_apartment, setBuildingApartment] = useState('');
  const [pickUp, setPickUp] = useState('delivery');
  const [location, setLocation] = useState('');
  const [paymentOption, setPaymentOption] = useState('full');
  const [showModal, setShowModal] = useState(false);
  const [iframeModal, setIframeModal] = useState('');
  const [minimumPayment, setMinimumPayment] = useState(Math.ceil(cart.totalPrice * 0.5));
  const [showWarning, setShowWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  cart.shippingAddress = { street_road, company, building_apartment };

  const handleOptionChange = (e) => {
    setPaymentOption(e.target.value);
  };

  const handleMinimumPaymentChange = (e) => {
    const inputValue = e.target.value;
    const minValue = cart.totalPrice * 0.5;

    if (inputValue < minValue) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
    setMinimumPayment(inputValue);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const initiateOrderPayment = async (
    orderItems,
    shippingAddress,
    itemsPrice,
    shippingPrice,
    taxPrice,
    totalPrice,
    paidAmount,
  ) => {
    setLoading(true);

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    try {
      const { data } = await axios.post(
        '/api/orders/add/',
        {
          orderItems,
          shippingAddress,
          itemsPrice,
          shippingPrice,
          taxPrice,
          totalPrice,
          paidAmount,
        },
        config,
      );
      setLoading(false);
      // const { redirect_url } = data;
      setShowModal(true);
      setIframeModal(data.redirect_url);
      if (data.redirect_url) {
        navigate('/order/payement/pesapal/', {
          state: data.redirect_url,
          all: {
            orderItems,
            shippingAddress,
            itemsPrice,
            shippingPrice,
            taxPrice,
            totalPrice,
            paidAmount,
          },

        });
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.error);
    }
  };

  const handlePaymentSubmit = (e) => {
    e.preventDefault();

    const currentHour = new Date().getHours();

    // Check if it is between 8 am and 8 pm
    if (currentHour < 8 || currentHour >= 20) {
      toast.error('Orders can only be submitted between 8 am and 8 pm.');
      return;
    }

    // Add selected location to cart items
    const updatedCartItems = cartItems.map((item) => ({
      ...item,
      location,
      company,
      street_road,
      building_apartment,
    }));

    // handle payment logic here
    if (!userInfo) {
      toast.info('Please login to proceed with the order.');
      navigate('/authenticate');
    } else if (paymentOption === 'full') {
      initiateOrderPayment(
        updatedCartItems, // Use the updated cart items
        cart.shippingAddress,
        cart.itemsPrice,
        cart.shippingPrice,
        cart.taxPrice,
        cart.totalPrice,
        cart.totalPrice,
      );
      toast.success('Payment submitted successfully!');
    } else if (paymentOption === 'half') {
      initiateOrderPayment(
        updatedCartItems, // Use the updated cart items
        cart.shippingAddress,
        cart.itemsPrice,
        cart.shippingPrice,
        cart.taxPrice,
        cart.totalPrice,
        minimumPayment,
      );
      toast.success('Payment submitted successfully!');
    }
  };

  useEffect(() => {
    dispatch(listLocations());
    if (cartItems.length === 0) {
      navigate('/');
    }
  }, [dispatch, cartItems]);

  const handleSelectionChange = (event) => {
    setPickUp(event.target.value);
  };

  const removeFromCartHandler = (itemId) => {
    dispatch(removeFromCart(itemId));
  };

  return (
    <>
      <Header />
      <Container className="page-content-wrapper">
        <h2>Order Payment</h2>
        { loading && <Loader />}
        <Form onSubmit={handlePaymentSubmit}>
          <Form.Group className="mb-3" controlId="contactMethod">
            <CustomLabel>Please select one of the options below</CustomLabel>
            <br />
            <div className="d-flex flex-column">
              <Form.Check
                inline
                type="radio"
                label="Pick up cake from location"
                value="location"
                checked={pickUp === 'location'}
                onChange={handleSelectionChange}
              />
              <Form.Check
                type="radio"
                inline
                label="Deliver to address"
                value="delivery"
                checked={pickUp === 'delivery'}
                onChange={handleSelectionChange}
              />
            </div>
          </Form.Group>
          <Row>
            <Col className="pr-md-5">
              {pickUp === 'location' && (
              <Form.Group className="mb-3" controlId="location">
                <CustomLabel>
                  Location
                </CustomLabel>
                <CustomSelect
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  className="w-100 required-field"
                >
                  <option value="">Locations Offered</option>
                  {(locations || []).map((loc) => (
                    <option value={loc.id}>{loc.name}</option>))}
                </CustomSelect>
              </Form.Group>
              )}

              {pickUp === 'delivery' && (
              <>
                <h3>Delivery Details</h3>
                {
                userInfo && (
                <Form.Group className="mb-3" controlId="email">
                  <CustomLabel>
                    Email
                  </CustomLabel>
                  <CustomFormControl
                    required
                    type="email"
                    placeholder="Enter email"
                    disabled
                    value={userInfo.email ?? ''}
                  />
                </Form.Group>
                )
}

                {userInfo && (
                <Form.Group className="mb-3" controlId="firstName">
                  <CustomLabel>
                    First Name
                  </CustomLabel>
                  <CustomFormControl
                    className="w-100 required-field"
                    type="text"
                    value={userInfo.first_name ?? ''}
                    disabled
                    required
                  />
                </Form.Group>
                )}

                {userInfo && (
                <Form.Group className="mb-3" controlId="lastName">
                  <CustomLabel>
                    Last Name
                  </CustomLabel>
                  <CustomFormControl
                    className="w-100 required-field"
                    type="text"
                    disabled
                    required
                    value={userInfo.last_name ?? ''}
                  />
                </Form.Group>
                )}
                {userInfo && (
                <Form.Group className="mb-3" controlId="lastName">
                  <CustomLabel>
                    Phone number
                  </CustomLabel>
                  <CustomFormControl
                    className="w-100 required-field"
                    type="text"
                    disabled
                    required
                    value={userInfo?.phone_number ?? ''}
                  />
                </Form.Group>
                )}

                <Form.Group className="mb-3" controlId="company">
                  <CustomLabel>
                    Company
                  </CustomLabel>
                  <CustomFormControl
                    className="w-100 required-field"
                    type="text"
                    placeholder="Enter company"
                    value={company || ''}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="street_road">
                  <CustomLabel>Street/Road</CustomLabel>
                  <CustomFormControl
                    required
                    className="w-100 required-field"
                    type="text"
                    placeholder="Enter street/road"
                    value={street_road || ''}
                    onChange={(e) => setStreetRoad(e.target.value)}
                  />
                </Form.Group>
                <br />
                <Form.Group controlId="building_apartment">
                  <CustomLabel>Building/Apartment</CustomLabel>
                  <CustomFormControl
                    required
                    className="w-100 required-field"
                    type="text"
                    placeholder="Enter bulding/apartment"
                    value={building_apartment || ''}
                    onChange={(e) => setBuildingApartment(e.target.value)}
                  />
                </Form.Group>
              </>
              )}
            </Col>
            <Col className="border-start border-secondary pl-md-5">

              <Row>
                <Col>
                  {cartItems.length === 0 ? (
                    <Alert variant="info">
                      Your Cart is empty
                    </Alert>
                  ) : (
                    <div>
                      <h3>Order summary</h3>
                      {cartItems.map((item) => (
                        <>
                          <br />
                          <Row className="my-row p-md-3">
                            <Col>
                              <PreviewImage src={`${item.image1}`} alt={item.name} fluid rounded />
                            </Col>
                            <Col>
                              <Link className="link" to={`/product/${item.product}`}>
                                <h5>
                                  <small>
                                    {item.name}
                                  </small>
                                </h5>
                              </Link>
                            </Col>
                            <Col>
                              <Link className="link" to={`/product/${item.product}`}>
                                <h5>
                                  <small>
                                    Ksh
                                    {' '}
                                    {item && numberWithCommas(item.price)}
                                  </small>
                                </h5>
                              </Link>
                              <DeleteUpload
                                type="button"
                                variant="danger"
                                onClick={() => removeFromCartHandler(item.product)}
                              >
                                <i className="fa-solid fa-trash" />
                                &nbsp;
                                Remove
                              </DeleteUpload>
                            </Col>
                          </Row>

                          <hr />
                        </>
                      ))}
                      <Form.Group as={Col} controlId="paymentOption">
                        <CustomLabel>Payment Option</CustomLabel>
                        <div key="inline-radio" className="mb-3">
                          <Form.Check
                            inline
                            label="Full Payment"
                            name="paymentOption"
                            type="radio"
                            id="fullPayment"
                            value="full"
                            checked={paymentOption === 'full'}
                            onChange={handleOptionChange}
                          />
                          <Form.Check
                            inline
                            label="Pay Deposit"
                            name="paymentOption"
                            type="radio"
                            id="halfPayment"
                            value="half"
                            checked={paymentOption === 'half'}
                            onChange={handleOptionChange}
                          />
                        </div>
                      </Form.Group>

                      {paymentOption === 'full' ? (
                        <CreateProductButton
                          variant="secondary"
                          className="checkout-button btn btn-block"
                        // onClick={checkoutHandler}
                          disabled={cartItems.length === 0}
                          type="submit"
                        >
                          Continue to payment
                        </CreateProductButton>
                      ) : (
                        <>
                          <Form.Group className="mb-3" controlId="minimumPayment">
                            <CustomLabel>Minimum Payment</CustomLabel>
                            <CustomInpuTGroup>
                              <CustomInpuTGroup.Text style={{
                                backgroundColor: '#CDF8EE',
                                border: 'None',
                              }}
                              >
                                Ksh
                              </CustomInpuTGroup.Text>
                              <CustomFormControl
                                type="number"
                                placeholder="Enter minimum payment"
                                value={Math.ceil(minimumPayment)}
                                onChange={handleMinimumPaymentChange}
                              />
                            </CustomInpuTGroup>
                          </Form.Group>
                          <br />
                          {showWarning && (
                          <Alert variant="warning">
                            Deposit needs to be minimum 50% of total amount
                          </Alert>
                          )}
                          <CreateProductButton
                            variant="secondary"
                            type="submit"
                            className="checkout-button btn btn-block"
                          // onClick={checkoutHandler}
                            disabled={showWarning || cartItems.length === 0}
                          >
                            Continue to payment
                          </CreateProductButton>
                        </>
                      )}

                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          closeButton
          // backdrop="static"
          // keyboard={false}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>Pay with Pesapal</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { !iframeModal ? <Loader /> : (
              <iframe
              // id="pesapal-iframe"
                src={iframeModal}
                width="100%"
                height="600"
                scrolling="no"
                frameBorder="0"
                allowTransparency="true"
              />
            )}
          </Modal.Body>
        </Modal>
      </Container>
      <Footer />

    </>

  );
}

export default OrderPaymentScreen;
