/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CakeAddToCart from './cakeAddToCart';
import axios from '../utils/axios';
import Loader from './Loader';

function CategoryCart({ category }) {
  const navigate = useNavigate();

  const [products, setProducts] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [isLoading, setIsLoading] = useState(false);

  const listProductsByCategory = async () => {
    setIsLoading(true); // Start loading

    try {
      const { data } = await axios.get('/api/products/', {
        params: {
          category,
          page: 1,
          page_size: 6,
        },
      });
      setProducts(data.results);
      setIsLoading(false); // Stop loading
    } catch (error) {
      console.log('Error fetching products:', error);
      setIsLoading(false); // Stop loading in case of error
    }
  };

  const handleResize = () => {
    setIsMobileView(window.innerWidth <= 768);
  };

  useEffect(() => {
    listProductsByCategory();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [category]); // Fetch products when the category changes

  const renderProducts = () => {
    if (isLoading) {
      return <Loader />;
    }

    if (products.length === 0) {
      return (
        <div className="not-found-template">

          <h6>There are no cakes available at the moment</h6>

        </div>
      );
    }

    return (
      <>
        {products.map((product) => (
          <Col
            className="mt-2 row-1 mb-3 px-sm-3 px-2"
            xs={12}
            sm={6}
            md={4}
            key={product.id}
          >
            <div className="px-2">
              <CakeAddToCart product={product} />
            </div>
          </Col>
        ))}
      </>
    );
  };

  return (
    <Row className="mt-2 pt-0 row-1 mb-0 px-sm-3 px-2">
      {isMobileView ? (
        <Col xs={12}>
          <div className="d-flex flex-nowrap overflow-auto overflow-sm-visible">
            {products.slice(0, 6).map((product) => (
              <Col xs={6} sm={6} key={product.id}>
                <div className="px-2">
                  <CakeAddToCart product={product} />
                </div>
              </Col>
            ))}
          </div>
        </Col>
      ) : (
        renderProducts()
      )}
      <Col xs={12} className="mt-4">
        <button
          onClick={() => navigate('/shop', { state: category })}
          className="shop-all"
        >
          Shop all cakes&nbsp;
          <i className="fa-solid fa-chevron-right" />
        </button>
      </Col>
    </Row>
  );
}

export default CategoryCart;
