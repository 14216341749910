/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import {
  Alert, Col, Container, ListGroup, Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import { LocationCard } from '../../components/styled';
import AllLocationCrumb from '../../components/breadcrumb/allLocationCrumb';
import { listLocations } from '../../actions/locationAction';
import './location.scss';

function AllLocationScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationsList = useSelector((state) => state.locationsList);
  const { loading, error, locations } = locationsList;

  useEffect(() => {
    dispatch(listLocations());
  }, [dispatch]);

  return (
    <>
      <Header />
      <Container className="mt-5 mb-5 page-content-wrapper">
        <AllLocationCrumb />
        {loading ? (
          <Loader />
        ) : error ? (
          <Alert variant="danger">{error}</Alert>
        ) : (
          <Row className="mt-3">
            {locations.map((location) => (
              <Col xs={12} sm={12} md={6} lg={6} key={location.id}>
                <LocationCard
                  onClick={() => navigate(`/locations/${location.id}`)}
                  className="location-card"
                >
                  <LocationCard.Img
                    src={location.location_image}
                    alt={location.name}
                    className="location-image"
                    style={{ display: 'block', width: '100%' }}
                  />
                  <LocationCard.Body>
                    <ListGroup variant="flush" className="py-3">
                      <ListGroup.Item className="d-flex align-items-center py-3 hover-bg">
                        <h6 className="m-0 text-secondary">Name:</h6>
                        <h6 className="m-2 ml-3">{location.name}</h6>
                      </ListGroup.Item>
                      <ListGroup.Item className="d-flex align-items-center py-3 hover-bg">
                        <h6 className="m-0 text-secondary">Address:</h6>
                        <h6 className="m-2 ml-3">{location.address}</h6>
                      </ListGroup.Item>
                      <ListGroup.Item className="d-flex align-items-center py-3 hover-bg">
                        <h6 className="m-0 text-secondary">Contact:</h6>
                        <h6 className="m-2 ml-3">{location.contact}</h6>
                      </ListGroup.Item>
                    </ListGroup>
                  </LocationCard.Body>
                </LocationCard>
              </Col>
            ))}
          </Row>
        )}
      </Container>
      <Footer />
    </>
  );
}

export default AllLocationScreen;
