/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Button, Offcanvas, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import axios from '../utils/axios';
import Loader from '../components/Loader';
import { CreateProductButton, CustomFormControl, CustomLabel } from '../components/styled';
import { listLocations } from '../actions/locationAction';

function EnrollmentListScreen() {
  const [enrollments, setEnrollments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedEnrollment, setSelectedEnrollment] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [newFirstName, setNewFirstName] = useState('');
  const [newLastName, setNewLastName] = useState('');
  const [newDateOfBirth, setNewDateOfBirth] = useState('');
  const [newAddress, setNewAddress] = useState('');
  const [newMedicalConditions, setNewMedicalConditions] = useState('');
  const [newIdNumber, setNewIdNumber] = useState('');
  const [newCourseStartDate, setNewCourseStartDate] = useState('');
  const [newCourseTime, setNewCourseTime] = useState('');
  const [newLocation, setNewLocation] = useState('');
  const [newEnrollmentDate, setNewEnrollmentDate] = useState('');
  const [newOrderTrackingId, setNewOrderTrackingId] = useState('');
  const [newConfirmationCode, setNewConfirmationCode] = useState('');
  const [newCallBackUrl, setNewCallBackUrl] = useState('');
  const [newMerchantReference, setNewMerchantReference] = useState('');
  const [newCurrency, setNewCurrency] = useState('');
  const [newPaymentAccount, setNewPaymentAccount] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [newAmount, setNewAmount] = useState('');
  const [newPaymentStatusDescription, setNewPaymentStatusDescription] = useState('');

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();

  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  useEffect(() => {
    const fetchEnrollments = async () => {
      setLoading(true);
      try {
        const response = await axios.get('/api/enrollments/', config);
        setEnrollments(response.data);
        setLoading(false);
      } catch (error) {
        toast.error('Failed to fetch enrollments.');
        setLoading(false);
      }
    };

    fetchEnrollments();
  }, []);

  const handleEdit = (enrollment) => {
    setSelectedEnrollment(enrollment);
    setNewFirstName(enrollment.first_name);
    setNewLastName(enrollment.last_name);
    setNewDateOfBirth(enrollment.date_of_birth);
    setNewAddress(enrollment.address);
    setNewMedicalConditions(enrollment.medical_conditions);
    setNewIdNumber(enrollment.id_number);
    setNewCourseStartDate(enrollment.course_start_date);
    setNewCourseTime(enrollment.course_time);
    setNewLocation(enrollment.location);
    setNewEnrollmentDate(enrollment.enrollment_date);
    setNewOrderTrackingId(enrollment.order_tracking_id);
    setNewConfirmationCode(enrollment.confirmation_code);
    setNewCallBackUrl(enrollment.call_back_url);
    setNewMerchantReference(enrollment.merchant_reference);
    setNewCurrency(enrollment.currency);
    setNewPaymentAccount(enrollment.payment_account);
    setNewDescription(enrollment.description);
    setNewAmount(enrollment.amount);
    setNewPaymentStatusDescription(enrollment.payment_status_description);
    setEditMode(true);
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.put(
        `/api/enrollments/${selectedEnrollment._id}/`,
        {
          first_name: newFirstName,
          last_name: newLastName,
          date_of_birth: newDateOfBirth,
          address: newAddress,
          medical_conditions: newMedicalConditions,
          id_number: newIdNumber,
          course_start_date: newCourseStartDate,
          course_time: newCourseTime,
          location: newLocation,
          enrollment_date: newEnrollmentDate,
          order_tracking_id: newOrderTrackingId,
          confirmation_code: newConfirmationCode,
          call_back_url: newCallBackUrl,
          merchant_reference: newMerchantReference,
          currency: newCurrency,
          payment_account: newPaymentAccount,
          description: newDescription,
          amount: newAmount,
          payment_status_description: newPaymentStatusDescription,
        },
        config,
      );
      const updatedEnrollment = response.data;
      setEnrollments((prevEnrollments) => prevEnrollments.map(
        (enrollment) => (
          enrollment._id === updatedEnrollment._id ? updatedEnrollment : enrollment),
      ));
      setEditMode(false);
      setSelectedEnrollment(null);
      toast.success('Enrollment updated successfully');
    } catch (error) {
      toast.error('Failed to update enrollment.');
    }
  };

  const handleCreate = async () => {
    try {
      const response = await axios.post(
        '/api/enrollments/',
        {
          first_name: newFirstName,
          last_name: newLastName,
          date_of_birth: newDateOfBirth,
          address: newAddress,
          medical_conditions: newMedicalConditions,
          id_number: newIdNumber,
          course_start_date: newCourseStartDate,
          course_time: newCourseTime,
          location: newLocation,
          enrollment_date: newEnrollmentDate,
          order_tracking_id: newOrderTrackingId,
          confirmation_code: newConfirmationCode,
          call_back_url: newCallBackUrl,
          merchant_reference: newMerchantReference,
          currency: newCurrency,
          payment_account: newPaymentAccount,
          description: newDescription,
          amount: newAmount,
          payment_status_description: newPaymentStatusDescription,
        },
        config,
      );
      const createdEnrollment = response.data;
      setEnrollments((prevEnrollments) => [...prevEnrollments, createdEnrollment]);
      toast.success('Enrollment created successfully');
      setNewFirstName('');
      setNewLastName('');
      setNewDateOfBirth('');
      setNewAddress('');
      setNewMedicalConditions('');
      setNewIdNumber('');
      setNewCourseStartDate('');
      setNewCourseTime('');
      setNewLocation('');
      setNewEnrollmentDate('');
      setNewOrderTrackingId('');
      setNewConfirmationCode('');
      setNewCallBackUrl('');
      setNewMerchantReference('');
      setNewCurrency('');
      setNewPaymentAccount('');
      setNewDescription('');
      setNewAmount('');
      setNewPaymentStatusDescription('');
      setEditMode(false);
    } catch (error) {
      toast.error('Failed to create enrollment.');
    }
  };

  const locationsList = useSelector((state) => state.locationsList);
  const { locations } = locationsList;

  useEffect(() => {
    dispatch(listLocations());
  }, [dispatch]);

  const handleDelete = async (enrollment) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this student?');
    if (confirmDelete) {
      try {
        await axios.delete(`/api/enrollments/${enrollment.id}/`, config);
        setEnrollments((
          prevEnrollments,
        ) => prevEnrollments.filter((enrl) => enrl._id !== enrollment._id));
        toast.success('Enrollment deleted successfully');
      } catch (error) {
        toast.error('Failed to delete enrollment.');
      }
    }
  };

  const handleToggleEditMode = () => {
    setEditMode((prevEditMode) => !prevEditMode);
    setSelectedEnrollment(null);
    setNewFirstName('');
    setNewLastName('');
    setNewDateOfBirth('');
    setNewAddress('');
    setNewMedicalConditions('');
    setNewIdNumber('');
    setNewCourseStartDate('');
    setNewCourseTime('');
    setNewLocation('');
    setNewEnrollmentDate('');
    setNewOrderTrackingId('');
    setNewConfirmationCode('');
    setNewCallBackUrl('');
    setNewMerchantReference('');
    setNewCurrency('');
    setNewPaymentAccount('');
    setNewDescription('');
    setNewAmount('');
    setNewPaymentStatusDescription('');
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col-md-12">
              <div className="main-card mb-3 card">
                <div className="card-header">
                  Enrollments
                  <div className="btn-actions-pane-right">
                    <div role="group" className="btn-group-sm btn-group">
                      {!editMode && (
                        <Button
                          className="active btn btn-focus"
                          onClick={handleToggleEditMode}
                          disabled
                        >
                          <i className="fas fa-plus" />
                          &nbsp;Create Enrollment
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="align-middle mb-0 table table-borderless table-striped table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th className="text-center">First Name</th>
                        <th className="text-center">Last Name</th>
                        <th className="text-center">Date of Birth</th>
                        <th className="text-center">Address</th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {enrollments.map((enrollment) => (
                        <tr key={enrollment._id}>
                          <td className="text-center">{enrollment.id}</td>
                          <td className="text-center">{enrollment.first_name}</td>
                          <td className="text-center">{enrollment.last_name}</td>
                          <td className="text-center">{enrollment.date_of_birth}</td>
                          <td className="text-center">{enrollment.address}</td>
                          <td className="text-center">
                            <Button
                              onClick={() => handleEdit(enrollment)}
                              variant="light"
                              className="btn-sm"
                            >
                              <i className="fas fa-edit" />
                            </Button>

                            <Button
                              variant="danger"
                              className="btn-sm"
                              onClick={() => handleDelete(enrollment)}
                            >
                              <i className="fas fa-trash" />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="d-block text-center card-footer">
                  {/* <button className="mr-2 btn-icon btn-icon-only btn btn-outline-danger">
                  <i className="pe-7s-trash btn-icon-wrapper"> </i></button>
                  <button className="btn-wide btn btn-success">Save</button> */}
                </div>
              </div>
            </div>
          </div>
          <Offcanvas
            backdrop="static"
            placement="end"
            show={editMode}
            onHide={handleToggleEditMode}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                {selectedEnrollment ? 'Edit Enrollment' : 'Create Enrollment'}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Form>
                <Form.Group className="mb-3" controlId="formFirstName">
                  <CustomLabel>First Name</CustomLabel>
                  <CustomFormControl
                    type="text"
                    value={newFirstName}
                    onChange={(e) => setNewFirstName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formLastName">
                  <CustomLabel>Last Name</CustomLabel>
                  <CustomFormControl
                    type="text"
                    value={newLastName}
                    onChange={(e) => setNewLastName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formDateOfBirth">
                  <CustomLabel>Date of Birth</CustomLabel>
                  <CustomFormControl
                    type="date"
                    value={newDateOfBirth}
                    onChange={(e) => setNewDateOfBirth(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formAddress">
                  <CustomLabel>Address</CustomLabel>
                  <CustomFormControl
                    type="text"
                    value={newAddress}
                    onChange={(e) => setNewAddress(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formMedicalConditions">
                  <CustomLabel>Medical Conditions</CustomLabel>
                  <CustomFormControl
                    type="text"
                    value={newMedicalConditions}
                    onChange={(e) => setNewMedicalConditions(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formIdNumber">
                  <CustomLabel>ID Number</CustomLabel>
                  <CustomFormControl
                    type="text"
                    value={newIdNumber}
                    onChange={(e) => setNewIdNumber(e.target.value)}
                  />
                </Form.Group>
                {/* <Form.Group className="mb-3" controlId="formCourseStartDate">
                  <CustomLabel>Course Start Date</CustomLabel>
                  <CustomFormControl
                    type="date"
                    value={newCourseStartDate}
                    onChange={(e) => setNewCourseStartDate(e.target.value)}
                  />
                </Form.Group> */}
                {/* <Form.Group className="mb-3" controlId="formCourseTime">
                  <CustomLabel>Course Time</CustomLabel>
                  <CustomFormControl
                    type="text"
                    value={newCourseTime}
                    onChange={(e) => setNewCourseTime(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formLocation">
                  <CustomLabel>Location</CustomLabel>
                  <CustomFormControl
                    type="text"
                    readOnly
                    disabled
                    value={newLocation ?? ''}
                    onChange={(e) => setNewLocation(e.target.value)}
                  />
                </Form.Group> */}
                <Form.Group className="mb-3" controlId="formEnrollmentDate">
                  <CustomLabel>Enrollment Date</CustomLabel>
                  <CustomFormControl
                    type="date"
                    readOnly
                    disabled
                    value={newEnrollmentDate ? moment(newEnrollmentDate).format('YYYY-MM-DD') : ''}
                    onChange={(e) => setNewEnrollmentDate(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formOrderTrackingId">
                  <CustomLabel>Order Tracking ID</CustomLabel>
                  <CustomFormControl
                    type="text"
                    readOnly
                    disabled
                    value={newOrderTrackingId}
                    onChange={(e) => setNewOrderTrackingId(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formConfirmationCode">
                  <CustomLabel>Confirmation Code</CustomLabel>
                  <CustomFormControl
                    type="text"
                    readOnly
                    disabled
                    value={newConfirmationCode}
                    onChange={(e) => setNewConfirmationCode(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formCallBackUrl">
                  <CustomLabel>Callback URL</CustomLabel>
                  <CustomFormControl
                    type="text"
                    readOnly
                    disabled
                    value={newCallBackUrl}
                    onChange={(e) => setNewCallBackUrl(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formMerchantReference">
                  <CustomLabel>Merchant Reference</CustomLabel>
                  <CustomFormControl
                    type="text"
                    readOnly
                    disabled
                    value={newMerchantReference}
                    onChange={(e) => setNewMerchantReference(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formCurrency">
                  <CustomLabel>Currency</CustomLabel>
                  <CustomFormControl
                    type="text"
                    readOnly
                    disabled
                    value={newCurrency}
                    onChange={(e) => setNewCurrency(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formPaymentAccount">
                  <CustomLabel>Payment Account</CustomLabel>
                  <CustomFormControl
                    type="text"
                    readOnly
                    disabled
                    value={newPaymentAccount}
                    onChange={(e) => setNewPaymentAccount(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formDescription">
                  <CustomLabel>Description</CustomLabel>
                  <CustomFormControl
                    type="text"
                    readOnly
                    disabled
                    value={newDescription}
                    onChange={(e) => setNewDescription(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formAmount">
                  <CustomLabel>Amount</CustomLabel>
                  <CustomFormControl
                    type="text"
                    readOnly
                    disabled
                    value={newAmount}
                    onChange={(e) => setNewAmount(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formPaymentStatusDescription">
                  <CustomLabel>Payment Status Description</CustomLabel>
                  <CustomFormControl
                    type="text"
                    readOnly
                    disabled
                    value={newPaymentStatusDescription}
                    onChange={(e) => setNewPaymentStatusDescription(e.target.value)}
                  />
                </Form.Group>
                <div className="mt-3">
                  <CreateProductButton
                    className="w-100 required-field"
                    variant="outline-secondary"
                    type="submit"
                    onClick={selectedEnrollment ? handleUpdate : handleCreate}
                  >
                    {selectedEnrollment ? 'Update' : 'Create'}
                  </CreateProductButton>
                  {/* <Button variant="secondary" onClick={handleToggleEditMode}>Cancel</Button> */}
                </div>
              </Form>
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )}
    </div>
  );
}

export default EnrollmentListScreen;
