/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  Button, Row, Col, ListGroup, Image, Card, Alert, Container,
} from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PayPalButton } from 'react-paypal-button-v2';
import Loader from '../components/Loader';
import { getOrderDetails, payOrder, deliverOrder } from '../actions/orderActions';
import { ORDER_PAY_RESET, ORDER_DELIVER_RESET } from '../constants/orderConstants';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { BASE_URL } from '../utils/appconstants';

function OrderScreen() {
  const { id } = useParams();
  const orderId = id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [sdkReady, setSdkReady] = useState(false);

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, error, loading } = orderDetails;

  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingPay, success: successPay } = orderPay;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!loading && !error) {
    order.itemsPrice = order.orderItems.reduce((acc, item) => acc + Number(item.price), 0)
      .toFixed(2);
  }

  const addPayPalScript = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://www.paypal.com/sdk/js?client-id=AeDXja18CkwFUkL-HQPySbzZsiTrN52cG13mf9Yz7KiV2vNnGfTDP0wDEN9sGlhZHrbb_USawcJzVDgn';
    script.async = true;
    script.onload = () => {
      setSdkReady(true);
    };
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    }

    if (!order || successPay || order._id !== Number(orderId) || successDeliver) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });

      dispatch(getOrderDetails(orderId));
    } else if (!order.isPaid) {
      if (!window.paypal) {
        addPayPalScript();
      } else {
        setSdkReady(true);
      }
    }
  }, [dispatch, order, orderId, successPay, successDeliver]);

  const successPaymentHandler = (paymentResult) => {
    dispatch(payOrder(orderId, paymentResult));
  };

  const deliverHandler = () => {
    dispatch(deliverOrder(order));
  };

  return (
    <>
      <Header />
      {loading ? (
        <Loader />
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        <Container>
          <h1>
            Order:
            {' '}
            {orderId}
          </h1>
          <Row>
            <Col md={8}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h2>Shipping</h2>
                  <p>
                    <strong>Phone: </strong>
                    {' '}
                    {order.user.phone_number}
                  </p>
                  <p>
                    <strong>Email: </strong>
                    <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
                  </p>
                  <p>
                    <strong>Shipping: </strong>
                    {order.shippingAddress.address}
                    ,
                    {' '}
                    {order.shippingAddress.city}
                    {'  '}
                    {order.shippingAddress.postalCode}
                    ,
                    {'  '}
                    {order.shippingAddress.country}
                  </p>

                  {order.isDelivered ? (
                    <Alert variant="success">
                      Delivered on
                      {' '}
                      {order.deliveredAt}
                    </Alert>
                  ) : (
                    <Alert variant="warning">Not Delivered</Alert>
                  )}
                </ListGroup.Item>

                <ListGroup.Item>
                  <h2>Payment Method</h2>
                  <p>
                    <strong>Method: </strong>
                    {order.paymentMethod}
                  </p>
                  {order.isPaid ? (
                    <Alert variant="success">
                      Paid on
                      {' '}
                      {order.paidAt}
                    </Alert>
                  ) : (
                    <Alert variant="warning">Not Paid</Alert>
                  )}

                </ListGroup.Item>

                <ListGroup.Item>
                  <h2>Order Items</h2>
                  {order.orderItems.length === 0 ? (
                    <Alert variant="info">
                      Order is empty
                    </Alert>
                  ) : (
                    <ListGroup variant="flush">
                      {order.orderItems.map((item, index) => (
                        <ListGroup.Item key={index}>
                          <Row>
                            <Col md={2}>
                              <Image
                                loading="lazy"
                                src={`${BASE_URL}/media/${item.image}`}
                                alt={item.name}
                                fluid
                                rounded
                              />
                            </Col>

                            <Col>
                              <Link to={`/product/${item.product}`}><h6>{item.name}</h6></Link>
                            </Col>

                            <Col md={4}>
                              <h6>
                                Ksh&nbsp;
                                {' '}
                                {(Number(item.price)).toFixed(2)}
                              </h6>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  )}
                </ListGroup.Item>

              </ListGroup>

            </Col>

            <Col md={4}>
              <Card>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <h2>Order Summary</h2>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <Row>
                      <Col>Items:</Col>
                      <Col>
                        <h6>
                          Ksh&nbsp;
                          {order.itemsPrice}
                        </h6>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <Row>
                      <Col>Shipping:</Col>
                      <Col>
                        <h6>
                          Ksh&nbsp;
                          {order.shippingPrice}

                        </h6>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  {/*
                  <ListGroup.Item>
                    <Row>
                      <Col>Tax:</Col>
                      <Col>
                        <h6>
                          Ksh&nbsp;
                          {order.taxPrice}
                        </h6>
                      </Col>
                    </Row>
                  </ListGroup.Item> */}

                  <ListGroup.Item>
                    <Row>
                      <Col>Total:</Col>
                      <Col>
                        <h6>
                          Ksh&nbsp;
                          {order.totalPrice}
                        </h6>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  {!order.isPaid && (
                  <ListGroup.Item>
                    {loadingPay && <Loader />}

                    {!sdkReady ? (
                      <Loader />
                    ) : (
                      <PayPalButton
                        amount={order.totalPrice}
                        onSuccess={successPaymentHandler}
                      />
                    )}
                  </ListGroup.Item>
                  )}
                </ListGroup>
                {loadingDeliver && <Loader />}
                {userInfo && userInfo.isAdmin && order.isPaid && !order.isDelivered && (
                <ListGroup.Item>
                  <Button
                    type="button"
                    className="btn btn-block"
                    onClick={deliverHandler}
                  >
                    Mark As Delivered
                  </Button>
                </ListGroup.Item>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      <Footer />

    </>

  );
}

export default OrderScreen;
