/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
  Col, Form, Image, InputGroup, ListGroup, Offcanvas, Row, Stack,
} from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  CustomFormControl, CustomLabel, CustomSelect, CreateProductButton, DeleteUpload,
} from '../components/styled';

import { listWeights } from '../actions/weightAction';
import { listCategories } from '../actions/categoryAction';
import axios from '../utils/axios';

function PreviewImage({ image }) {
  return (
    <div>
      <Image
        loading="lazy"
        src={URL.createObjectURL(image)}
        alt="image.name"
        height={60}
        width={60}
      />
    </div>
  );
}

function ProductEditDetailsCanvas({
  show, handleClose, handleShow, selectedProduct, fetchProducts,
}) {
  const [name, setName] = useState('');
  const [image1, setImage1] = useState('');
  const [image2, setImage2] = useState('');
  const [image3, setImage3] = useState('');
  const [description, setDescription] = useState('');
  const [ingredients, setIngredients] = useState('');
  const [min_quantity, setMinQuantity] = useState(null);
  const [max_quantity, setMaxQuantity] = useState(null);
  const [cat, setCategory] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [allergens, setAllergens] = useState(null);
  const [start_date, setStartDate] = useState(selectedProduct?.promotion_start_date
    ? new Date(selectedProduct.promotion_start_date) : undefined);
  const [end_date, setEndDate] = useState(selectedProduct?.promotion_end_date
    ? new Date(selectedProduct.promotion_end_date) : undefined);
  const [discount, setDiscount] = useState('');
  const [weightTags, setWeightTags] = useState([]);
  const [preview1, setPreviewOne] = useState(false);
  const [preview2, setPreviewTwo] = useState(false);
  const [preview3, setPreviewThree] = useState(false);
  const [cake_quantity, setCakeQuantity] = useState(null);
  const [cake_quantity_price, setCakeQuantityPrice] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const weightsList = useSelector((state) => state.weightsList);
  const { loading: loadingWeight, weights } = weightsList;

  const categoriesList = useSelector((state) => state.categoriesList);
  const { loading: loadingCategory, categories } = categoriesList;

  useEffect(() => {
    dispatch(listWeights());
  }, [dispatch, navigate, userInfo]);

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listCategories());
    } else {
      navigate('/authenticate');
    }
  }, [dispatch, navigate, userInfo]);

  useEffect(() => {
    if (selectedProduct) {
      // Set the form fields with the existing selectedProduct data
      setName(selectedProduct.name);
      setDescription(selectedProduct.description);
      setIngredients(selectedProduct.ingredients);
      setMinQuantity(selectedProduct.min_quantity);
      setMaxQuantity(selectedProduct.max_quantity);
      setCategory(selectedProduct.category.id);
      setCategoryName(selectedProduct.category.name);
      setCakeQuantity(selectedProduct.cake_quantity);
      setCakeQuantityPrice(selectedProduct.cake_quantity_price);
      setAllergens(selectedProduct.allergens);
      // if (selectedProduct?.promotion_start_date) {
      //   setStartDate(moment(selectedProduct?.promotion_start_date));
      // }
      // if (selectedProduct?.promotion_end_date) {
      //   setEndDate(moment(selectedProduct?.promotion_end_date));
      // }
      setDiscount(selectedProduct.discount);
      setWeightTags(selectedProduct.weights);
    }
  }, [selectedProduct]);

  const updateProductHandler = async (e) => {
    e.preventDefault();
    const weightTagIds = weightTags.map((option) => Number(option.id));

    const product = new FormData();
    product.append('name', name);

    if (image1 instanceof File) {
      product.append('image1', image1);
    }

    if (image2 instanceof File) {
      product.append('image2', image2);
    }

    if (image3 instanceof File) {
      product.append('image3', image3);
    }

    product.append('description', description);
    product.append('ingredients', ingredients);
    product.append('min_quantity', min_quantity);
    product.append('max_quantity', max_quantity);

    // Check if the selected category includes "cup cakes" or "mini cakes"
    const isCupCakes = categoryName && categoryName?.toLowerCase().includes('cup');
    const isMiniCakes = categoryName && categoryName?.toLowerCase().includes('mini');

    if (isCupCakes || isMiniCakes) {
      // For "cup cakes" and "mini cakes,"
      if (!cake_quantity || !cake_quantity_price) {
        toast.error('Cake quantity and cake quantity price are required for "Cup Cakes" and "Mini Cakes" categories.');
        return;
      }
      product.append('cake_quantity', cake_quantity);
      product.append('cake_quantity_price', cake_quantity_price);
      product.delete('weights');
    } else {
      // For other categories (except "cup cakes" and "mini cakes")
      product.append('cake_quantity', cake_quantity);
      product.append('cake_quantity_price', cake_quantity_price);
      if (weightTagIds.length > 0) {
        product.append('weights', JSON.stringify(weightTagIds));
      }
    }

    // product.append('category', Number(cat));
    product.append('allergens', allergens);

    if (start_date) {
      product.append('promotion_start_date', moment(start_date).format('YYYY-MM-DD HH:mm:ss'));
    }

    if (end_date) {
      product.append('promotion_end_date', moment(end_date).format('YYYY-MM-DD HH:mm:ss'));
    }

    product.append('discount', discount);

    const headers = {
      Authorization: `Bearer ${userInfo.token}`,
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await axios.put(`/api/products/${selectedProduct.id}/`, product, { headers });
      handleClose();
      fetchProducts();
      toast.success('Product updated successfully!');
    } catch (error) {
      handleShow();
      toast.error('Failed to update product.');
    }
  };

  const checkSelectedValue = (option) => {
    const doesObjectExist = weightTags.some((object) => object.id === option.id);
    return doesObjectExist;
  };

  const handleImage1Change = (e) => {
    setImage1(e.target.files[0]);
    setPreviewOne(true);
  };

  const removeImage1 = () => {
    setImage1(null);
    setPreviewOne(false);
  };

  const removeImage2 = () => {
    setImage2(null);
    setPreviewTwo(false);
  };

  const removeImage3 = () => {
    setImage3(null);
    setPreviewThree(false);
  };

  const handleImage2Change = (e) => {
    setImage2(e.target.files[0]);
    setPreviewTwo(true);
  };

  const handleImage3Change = (e) => {
    setImage3(e.target.files[0]);
    setPreviewThree(true);
  };

  const handleCheckboxChange = (option) => {
    if (checkSelectedValue(option)) {
      // If the option is already selected, remove it from the weightTags array
      setWeightTags(weightTags.filter((tag) => tag.id !== option.id));
    } else {
      // If the option is not selected, add it to the weightTags array
      setWeightTags([...weightTags, option]);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredWeightTags = (weights || []).filter((option) => {
    // Filter by cake name or weight
    const cakeWeight = option.cake_weight.toLowerCase();
    const cakeName = option.price.toLowerCase();
    const query = searchQuery.toLowerCase();

    return cakeWeight.includes(query) || cakeName.includes(query);
  });

  const selectedCategory = categories.find((category) => category.id === parseInt(cat, 10));

  const isCupCakes = selectedCategory && selectedCategory?.name.toLowerCase().includes('cup');
  const isMiniCakes = selectedCategory && selectedCategory?.name.toLowerCase().includes('mini');
  const isOcassionCakes = selectedCategory && selectedCategory?.name.toLowerCase().includes('occasion');

  return (
    <Offcanvas
      scroll
      show={show}
      onHide={handleClose}
      backdrop="static"
      placement="end"
      className="custom-product-offcanvas"
    >

      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <h3>
            Edit Product
          </h3>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onSubmit={updateProductHandler}>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <CustomLabel>
                  Product name
                  <span className="asterisk">*</span>
                </CustomLabel>
                <CustomFormControl
                  type="text"
                  value={name}
                  required
                  className="w-100 required-field"
                  onChange={(event) => setName(event.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <CustomLabel>
                  Product description
                  <span className="asterisk">*</span>
                </CustomLabel>
                <CustomFormControl
                  as="textarea"
                  rows={5}
                  required
                  className="w-100 required-field"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <CustomLabel>
                  Category
                  <span className="asterisk">*</span>
                </CustomLabel>
                <CustomSelect
                  required
                  className="w-100 required-field"
                  value={cat}
                  disabled
                  onChange={(event) => setCategory(event.target.value)}
                >
                  <option value="">Select an option</option>
                  {!loadingCategory
      && categories.map((ctg) => (
        <option key={ctg.id} value={ctg.id}>
          {ctg.name}
        </option>
      ))}
                </CustomSelect>
              </Form.Group>

              <br />
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <CustomLabel>
                  Min quantity
                  <span className="asterisk">*</span>
                </CustomLabel>
                <CustomFormControl
                  value={min_quantity}
                  required
                  className="w-100 required-field"
                  onChange={(event) => setMinQuantity(event.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <CustomLabel>
                  Max quantity
                  <span className="asterisk">*</span>
                </CustomLabel>
                <CustomFormControl
                  value={max_quantity}
                  required
                  className="w-100 required-field"
                  onChange={(event) => setMaxQuantity(event.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Row>
                  {selectedProduct && selectedProduct.weights && selectedProduct.weights.length ? (
                    <Col xs={12} className="custom-column">
                      <CustomLabel>All weights</CustomLabel>
                      <div className="checkbox-group">
                        <div className="checkbox-label">
                          {selectedProduct.weights.map((option) => (
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <h6>
                                  {`Weight: ${option.cake_weight}  -  Price: Ksh ${option.price}`}
                                </h6>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Col>
                  ) : (
                    <Col xs={12} className="custom-column">
                      <CustomLabel>No weights available for this product</CustomLabel>
                    </Col>
                  )}
                  {selectedProduct && selectedProduct.weights
                  && selectedProduct.weights.length === 0 && (
                  <Col xs={12} className="custom-column">
                    <>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <CustomLabel>
                          Cake Quantity
                          <span className="asterisk">*</span>
                        </CustomLabel>
                        <CustomFormControl
                          value={cake_quantity}
                          type="number"
                          required
                          className="w-100 required-field"
                          onChange={(event) => setCakeQuantity(event.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="cakeQuantityPrice">
                        <CustomLabel>
                          Cake Quantity Price
                          <span className="asterisk">*</span>
                        </CustomLabel>
                        <CustomFormControl
                          type="number"
                          value={cake_quantity_price}
                          required
                          className="w-100 required-field"
                          onChange={(event) => setCakeQuantityPrice(event.target.value)}
                        />
                      </Form.Group>
                    </>
                  </Col>
                  )}
                </Row>

              </Form.Group>
              <br />
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <CustomLabel>
                  Allergens
                </CustomLabel>
                <CustomFormControl
                  className="w-100 required-field"
                  value={allergens}
                  onChange={(event) => setAllergens(event.target.value)}
                  as="textarea"
                  rows={3}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <CustomLabel>
                  Ingredients
                </CustomLabel>
                <CustomFormControl
                  className="w-100 required-field"
                  value={ingredients}
                  onChange={(event) => setIngredients(event.target.value)}
                  as="textarea"
                  rows={5}
                />
              </Form.Group>
              <ListGroup>
                <ListGroup.Item>
                  <Row style={{ border: 'None' }}>
                    <Col>
                      {preview1 && (
                      <PreviewImage
                        image={image1}
                        thumbnail
                      />
                      )}
                    </Col>
                    <Col style={{ float: 'left' }}>
                      {image1 && <small>{image1.name}</small>}
                    </Col>
                    <Col style={{ float: 'left' }}>
                      <div className="form-group">
                        {image1 ? (
                          <DeleteUpload onClick={removeImage1} variant={false}>
                            <i className="fa-solid fa-trash" />
                            &nbsp;
                            Delete
                          </DeleteUpload>
                        ) : (
                          <>
                            <label htmlFor="image1">
                              <span>
                                <i className="fa-regular fa-image" />
&nbsp; Upload image
                              </span>
                            </label>
                            <br />
                            <input
                              style={{ display: 'None' }}
                              type="file"
                              className="form-control-file"
                              id="image1"
                              onChange={(event) => handleImage1Change(event)}
                            />
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row style={{ border: 'None' }}>
                    <Col>
                      {preview2 && (
                      <PreviewImage
                        thumbnail
                        image={image2}
                      />
                      )}
                    </Col>
                    <Col style={{ float: 'left' }}>
                      {image2 && <small>{image2.name}</small>}
                    </Col>
                    <Col style={{ float: 'left' }}>
                      <div className="form-group">
                        {image2 ? (
                          <DeleteUpload onClick={removeImage2} variant={false}>
                            <i className="fa-solid fa-trash" />
                            &nbsp;
                            Delete
                          </DeleteUpload>
                        ) : (
                          <>
                            <label htmlFor="image2">
                              <span>
                                <i className="fa-regular fa-image" />
&nbsp; Upload image
                              </span>
                            </label>
                            <br />
                            <input
                              style={{ display: 'None' }}
                              type="file"
                              className="form-control-file"
                              id="image2"
                              onChange={(event) => handleImage2Change(event)}
                            />
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <Row style={{ border: 'None' }}>
                    <Col style={{ float: 'left' }}>
                      {preview3 && (
                      <PreviewImage
                        thumbnail
                        image={image3}
                      />
                      )}
                    </Col>
                    <Col style={{ float: 'left' }}>
                      {image3 && <small>{image3.name}</small>}
                    </Col>
                    <Col>
                      <div className="form-group">
                        {image3 ? (
                          <DeleteUpload onClick={removeImage3} variant={false}>
                            <i className="fa-solid fa-trash" />
                            &nbsp;
                            Delete
                          </DeleteUpload>
                        ) : (
                          <>
                            <label htmlFor="image3">
                              <span>
                                <i className="fa-regular fa-image" />
&nbsp; Upload image
                              </span>
                            </label>
                            <br />
                            <input
                              style={{ display: 'None' }}
                              type="file"
                              className="form-control-file"
                              id="image3"
                              onChange={(event) => handleImage3Change(event)}
                            />
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
              <br />
              <h4>Promotion</h4>
              <Row>
                <Form.Group controlId="formStartDate">
                  <CustomLabel>Start Date</CustomLabel>
                  <InputGroup>
                    <DatePicker
                      selected={start_date}
                      onChange={(date) => setStartDate(date)}
                      minDate={new Date()}
                      maxDate={end_date ? new Date(end_date) : undefined}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeCaption="time"
                      className="form-control"
                    />

                  </InputGroup>
                </Form.Group>

                <Form.Group controlId="formEndDate">
                  <CustomLabel>End Date</CustomLabel>
                  <InputGroup>
                    <DatePicker
                      selected={end_date}
                      onChange={(date) => setEndDate(date)}
                      minDate={start_date ? new Date(start_date) : undefined}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeCaption="time"
                      className="form-control"
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group controlId="discount">
                  <CustomLabel>Discount</CustomLabel>
                  <Form.Control
                    placeholder="Enter discount"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </Form.Group>
              </Row>
            </Col>
          </Row>
          <br />
          <Stack direction="horizontal" gap={2}>
            <CreateProductButton
              variant="secondary"
              type="submit"
              className="w-100"
            >
              Update Product
            </CreateProductButton>
          </Stack>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default ProductEditDetailsCanvas;
