/* eslint-disable no-underscore-dangle */
import axios from '../utils/axios';
import {
  CURRICULUM_LIST_REQUEST,
  CURRICULUM_LIST_SUCCESS,
  CURRICULUM_LIST_FAIL,

  CURRICULUM_DETAILS_REQUEST,
  CURRICULUM_DETAILS_SUCCESS,
  CURRICULUM_DETAILS_FAIL,

  CURRICULUM_DELETE_REQUEST,
  CURRICULUM_DELETE_SUCCESS,
  CURRICULUM_DELETE_FAIL,

  CURRICULUM_CREATE_REQUEST,
  CURRICULUM_CREATE_SUCCESS,
  CURRICULUM_CREATE_FAIL,

  CURRICULUM_UPDATE_REQUEST,
  CURRICULUM_UPDATE_SUCCESS,
  CURRICULUM_UPDATE_FAIL,

} from '../constants/curriculumConstants';

export const listCurriculum = () => async (dispatch) => {
  try {
    dispatch({ type: CURRICULUM_LIST_REQUEST });

    const { data } = await axios.get('/api/curriculums/');

    dispatch({
      type: CURRICULUM_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CURRICULUM_LIST_FAIL,
      payload: error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message,
    });
  }
};

export const listCurriculumDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CURRICULUM_DETAILS_REQUEST,
    });

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    const { data } = await axios.get(
      `/api/curriculums/${id}/`,
      config,
    );

    dispatch({
      type: CURRICULUM_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CURRICULUM_DETAILS_FAIL,
      payload: error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message,
    });
  }
};

export const deleteCurriculum = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CURRICULUM_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // eslint-disable-next-line no-unused-vars
    const { data } = await axios.delete(
      `/api/curriculums/${id}/`,
      config,
    );

    dispatch({
      type: CURRICULUM_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CURRICULUM_DELETE_FAIL,
      payload: error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message,
    });
  }
};

export const createCurriculum = (curriculum) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CURRICULUM_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      '/api/curriculums/',
      curriculum,
      config,
    );
    dispatch({
      type: CURRICULUM_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CURRICULUM_CREATE_FAIL,
      payload: error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message,
    });
  }
};

export const updateCurriculum = (curriculum) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CURRICULUM_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/curriculums/${curriculum.id}/`,
      curriculum,
      config,
    );
    dispatch({
      type: CURRICULUM_UPDATE_SUCCESS,
      payload: data,
    });

    dispatch({
      type: CURRICULUM_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CURRICULUM_UPDATE_FAIL,
      payload: error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message,
    });
  }
};
