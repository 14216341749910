/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import axios from '../utils/axios';
import { CustomFormControl, CustomLabel, CustomSelect } from '../components/styled';
import { numberWithCommas } from '../utils/utlilities';

function WeightEditScreen({
  weightData,
  fetchWeights,
  weightVisible,
  handleWeightModalClose,
  handleWeightModalShow,
}) {
  const [cakeWeight, setCakeWeight] = useState(weightData.cake_weight);
  const [price, setPrice] = useState(weightData.price);
  const [servesPerWeight, setServesPerWeight] = useState(weightData.serves_per_weight);
  const [toppings, setToppings] = useState([]);
  const [availableToppings, setAvailableToppings] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(weightData.product
    ? weightData.product.id : null);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const fetchToppings = async () => {
    try {
      const response = await axios.get('/api/toppings/');
      setAvailableToppings(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProducts = async () => {
    try {
      const { data } = await axios.get('/api/products/', {
        params: {
          page: 1,
          page_size: 100,
          ordering: '-createdAt',
        },
        headers: {
          Authorization: `Bearer ${userInfo.token}`, // Add the token as a bearer token
        },
      });

      setProducts(data?.results || []);
    } catch (error) {
      // Throw the error to v
      console.log('');
    }
  };

  useEffect(() => {
    fetchToppings();
    fetchProducts();
    const selectedToppings = weightData.toppings.map((topping) => topping.id);
    setToppings(selectedToppings);
  }, [weightData]);

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      await axios.put(`/api/weights/${weightData.id}/`, {
        cake_weight: cakeWeight,
        price,
        serves_per_weight: servesPerWeight,
        toppings,
        product_id: selectedProduct,
      });
      handleWeightModalClose();
      fetchWeights();
      toast.success('Weight updated successfully');
    } catch (error) {
      handleWeightModalShow();
      toast.error('Failed to update weight.');
    }
  };

  const handleToppingsChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions);
    const selectedToppings = selectedOptions.map((option) => option.value);
    setToppings(selectedToppings);
  };

  return (
    <Form onSubmit={submitHandler}>
      <Modal show={weightVisible} onHide={handleWeightModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Edit Weight</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {products && products.length > 0 && (
            <Form.Group controlId="product">
              <CustomLabel>Select Product</CustomLabel>
              <CustomSelect
                value={selectedProduct}
                onChange={(e) => setSelectedProduct(e.target.value)}
              >
                <option value="">Select a product</option>
                {products.map((product) => (
                  <option key={product.id} value={product.id}>
                    {product.name}
                  </option>
                ))}
              </CustomSelect>
            </Form.Group>
          )}
          <br />
          <Form.Group controlId="cakeWeight">
            <CustomLabel>Cake Weight</CustomLabel>
            <CustomFormControl
              type="text"
              value={cakeWeight}
              onChange={(e) => setCakeWeight(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="price">
            <CustomLabel>Price</CustomLabel>
            <CustomFormControl
              type="text"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="servesPerWeight">
            <CustomLabel>Serves Per Weight</CustomLabel>
            <CustomFormControl
              type="text"
              value={servesPerWeight}
              onChange={(e) => setServesPerWeight(e.target.value)}
            />
          </Form.Group>

          {/* <Form.Group controlId="toppings">
            <CustomLabel>Toppings</CustomLabel>
            <Form.Select
              multiple
              value={toppings}
              onChange={handleToppingsChange}
            >
              {availableToppings.map((topping) => (
                <option key={topping.id} value={topping.id}>
                  {topping.name}
                  {' '}
                  - Ksh
                  {numberWithCommas(topping.price)}
                </option>
              ))}
            </Form.Select>
          </Form.Group> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleWeightModalClose}>
            Close
          </Button>
          <Button onClick={submitHandler} type="submit" variant="primary">
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}

export default WeightEditScreen;
