import React from 'react';
import { SeeOurMenuButton } from './styled';
import menu from '../assets/menu.pdf';

function Delivery() {
  return (
    <div style={{ textAlign: 'center' }} className="m-5">
      <h3>Delivering creamy deliciousness for you since forever</h3>
      <p>
        A one-of-a-kind experience for the senses,
        dedicated to crafting treats that are not only delicious, but also visually stunning
      </p>
      <div className="d-flex justify-content-center">
        <a href={menu} target="_blank" rel="noopener noreferrer">
          <SeeOurMenuButton variant="outline-light" type="submit">See our menu</SeeOurMenuButton>
        </a>
      </div>
    </div>
  );
}

export default Delivery;
