/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Alert, Image } from 'react-bootstrap';
import axios from '../utils/axios';
import Loader from '../components/Loader';
import cakeImage1 from '../assets/images/Page 1_3.png';

function OrderCreationConfirmationPage() {
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderTrackingId = searchParams.get('OrderTrackingId');

  const cart = useSelector((state) => state.cart);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  cart.itemsPrice = cart.cartItems.reduce((acc, item) => acc + item.price, 0).toFixed(2);
  cart.shippingPrice = (cart.itemsPrice > 100 ? 0 : 10).toFixed(2);
  cart.taxPrice = Number((0.0082) * cart.itemsPrice).toFixed(2);
  cart.totalPrice = (Number(cart.itemsPrice));

  const initiateOrderPaymentAfterStatus = async (
    orderItems,
    shippingAddress,
    paymentMethod,
    itemsPrice,
    shippingPrice,
    taxPrice,
    totalPrice,
    paidAmount,
    trackingID,
  ) => {
    setLoading(true);

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    try {
      const { data } = await axios.post(
        '/api/orders/order/payment_status/',
        {
          orderItems,
          shippingAddress,
          paymentMethod,
          itemsPrice,
          shippingPrice,
          taxPrice,
          totalPrice,
          paidAmount,
          orderTrackingId: trackingID,
        },
        config,
      );
      navigate('/profile');
      localStorage.removeItem('cartItems');
      setLoading(false);
    } catch (err) {
      setLoading(false);
      navigate('/profile');
      setError(err.response.data.error);
    }
  };

  // Function to check transaction status
  const checkTransactionStatus = async () => {
    // Make an API call to your backend to retrieve the transaction status
    // You can use fetch or axios for making the API call
    // Update the 'paymentStatus' state based on the response
    // For example, if the response indicates a failed payment:
    // setPaymentStatus('failed');
    // If the response indicates a successful payment:
    // setPaymentStatus('success');

    initiateOrderPaymentAfterStatus(
      cart.cartItems,
      cart.shippingAddress,
      'Pesapal',
      cart.itemsPrice,
      cart.shippingPrice,
      cart.taxPrice,
      cart.totalPrice,
      cart.totalPrice,
      orderTrackingId,
    );
  };

  useEffect(() => {
    checkTransactionStatus();
  }, [orderTrackingId]);

  return (
    <div>
      {paymentStatus === 'success' && (
      <Alert variant="success">
        Payment successful! Your order has been placed.
      </Alert>
      )}
      {paymentStatus === 'failed' && (
        <Alert variant="danger">
          Payment failed. Please try again or choose an alternative payment method.
        </Alert>
      )}
      { error && <Alert variant="danger">{error}</Alert>}
      { loading && <Loader />}
      <div className="not-found-template">
        {/* <h4>
              {' '}
              {searchQuery}
              {' '}
              not found.
            </h4> */}
        <Image
          loading="lazy"
          src={cakeImage1}
          alt="404 Not Found"
          fluid
        />
        <h3>Please Do not refresh this page as we redirect you to profile page</h3>
        {/* You can add a nice design and additional information here */}
      </div>
    </div>
  );
}

export default OrderCreationConfirmationPage;
