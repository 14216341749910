/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import {
  LOCATION_CREATE_FAIL,
  LOCATION_CREATE_REQUEST,
  LOCATION_CREATE_SUCCESS,
  LOCATION_DELETE_FAIL,
  LOCATION_DELETE_REQUEST,
  LOCATION_DELETE_SUCCESS,
  LOCATION_DETAILS_FAIL,
  LOCATION_DETAILS_REQUEST,
  LOCATION_DETAILS_SUCCESS,
  LOCATION_LIST_FAIL,
  LOCATION_LIST_REQUEST,
  LOCATION_LIST_SUCCESS,
  LOCATION_LIST_TOTAL_FAIL,
  LOCATION_LIST_TOTAL_SUCCESS,
  LOCATION_UPDATE_FAIL,
  LOCATION_UPDATE_REQUEST,
  LOCATION_UPDATE_SUCCESS,
} from '../constants/locationConstant';
import axios from '../utils/axios';

export const listLocations = () => async (dispatch, getState) => {
  try {
    dispatch({ type: LOCATION_LIST_REQUEST });

    const { data } = await axios.get('/api/locations/');

    dispatch({
      type: LOCATION_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOCATION_LIST_FAIL,
      payload: error.response && error.response.data.detail
        ? error.response.data.detail : error.message,

    });
  }
};

export const deleteLocation = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOCATION_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `/api/locations/${id}/`,
      config,
    );

    dispatch({
      type: LOCATION_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOCATION_DELETE_FAIL,
      payload: error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message,
    });
  }
};

export const updateLocation = (location) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOCATION_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/locations/${location.id}/`,
      location,
      config,
    );

    dispatch({
      type: LOCATION_UPDATE_SUCCESS,
    });

    dispatch({
      type: LOCATION_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOCATION_UPDATE_FAIL,
      payload: error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message,
    });
  }
};

export const createLocation = (location) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOCATION_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      '/api/locations/',
      location,
      config,
    );

    dispatch({
      type: LOCATION_CREATE_SUCCESS,
    });

    dispatch({
      type: LOCATION_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOCATION_CREATE_FAIL,
      payload: error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message,
    });
  }
};

export const getLocationDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOCATION_DETAILS_REQUEST,
    });

    const { data } = await axios.get(`/api/locations/${id}/`);

    dispatch({
      type: LOCATION_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOCATION_DETAILS_FAIL,
      payload: error.response && error.response.data.detail
        ? error.response.data.detail
        : error.message,
    });
  }
};

export const listTotalLocations = () => async (dispatch, getState) => {
  try {
    dispatch({ type: LOCATION_LIST_TOTAL_SUCCESS });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get('/api/locations/total/', config);

    dispatch({
      type: LOCATION_LIST_TOTAL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOCATION_LIST_TOTAL_FAIL,
      payload: error.response && error.response.data.detail
        ? error.response.data.detail : error.message,
    });
  }
};
