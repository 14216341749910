/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Form, ListGroup } from 'react-bootstrap';

function Autocomplete({
  options, placeholder, onSearch, onSelect,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const filteredOptions = options.filter(
    (option) => option.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleSelect = (option) => {
    setSearchTerm(option);
    onSelect(option);
  };

  return (
    <Form>
      <Form.Control
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          onSearch(e.target.value);
        }}
      />
      {filteredOptions.length > 0 && (
        <ListGroup>
          {filteredOptions.map((option, index) => (
            <ListGroup.Item
              key={index}
              action
              onClick={() => handleSelect(option)}
            >
              {option}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </Form>
  );
}
export default Autocomplete;
