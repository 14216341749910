/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Col, Container, Form, Image, Pagination, Row,
} from 'react-bootstrap';
import { useLocation } from 'react-router';
import Header from './Header';
import Footer from './Footer';
import CakeAddToCart from './cakeAddToCart';
import axios from '../utils/axios';
import Loader from './Loader';
import cakeImage1 from '../assets/images/Page 1_3.png';
import { CustomFormControl, CustomSelect } from './styled';

function ShopPage() {
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isAscending, setIsAscending] = useState('asc');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(location?.state || 'All');
  const [isLoading, setIsLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({
    count: 0,
    current_page: 1,
    page_size: 100,
  });

  const [currentPage, setCurrentPage] = useState(1);

  const fetchCategories = async () => {
    try {
      const response = await axios.get('/api/categories/');
      setCategories(response.data);
    } catch (error) {
      console.log('Error while fetching categories');
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    // Update the selectedCategory state when location.state changes
    setSelectedCategory(location?.state);
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true); // Start loading

      // Add a check to prevent fetching products if selectedCategory is null or undefined
      if (selectedCategory === null || selectedCategory === undefined) {
        setIsLoading(false);
        return;
      }

      try {
        const response = await axios.get('/api/products/', {
          params: {
            search: searchQuery,
            ordering: isAscending === 'asc' ? 'createdAt' : '-createdAt',
            category: selectedCategory === 'All' ? null : selectedCategory,
            page: pageInfo.current_page,
            page_size: pageInfo.page_size,
          },
        });
        setProducts(response.data.results);
        setPageInfo(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        // You can add error handling here, e.g., display an error message to users.
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [searchQuery, isAscending, selectedCategory, pageInfo.current_page, pageInfo.page_size]);

  const handleSortChange = (event) => {
    setIsAscending(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handlePageChange = (page) => {
    setPageInfo((prevPageInfo) => ({
      ...prevPageInfo,
      current_page: page,
    }));
  };

  const productsPerPage = 12;
  const totalPages = Math.ceil(pageInfo.count / productsPerPage);
  // const itemsPerPage = 9;

  return (
    <>
      <Header />
      <Container className="page-content-wrapper">
        <div
          className="mb-3"
          style={{
            textAlign: 'center', margin: 'auto', width: '80%',
          }}
        >
          <h3>Meet the whole family</h3>
          <h6>
            Our visually stunning and mouth-watering selection of cakes,
            are not only pleasing to the eye but also tempting
            to your taste buds. You can't resist the urge to
            get one or two! With Cakes & Cakes, you will definitely be the life of the party
          </h6>
        </div>

        <Row className="mb-3">
          <Col xs={12} md={8}>
            <CustomFormControl
              type="text"
              placeholder="Search for cakes"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Col>
          <Col xs={6} md={2} className="mt-3 mt-md-0">
            <CustomSelect id="sortOrderSelect" onChange={handleSortChange} defaultValue="asc">
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </CustomSelect>
          </Col>
          <Col xs={6} md={2} className="mt-3 mt-md-0">
            <CustomSelect onChange={handleCategoryChange} value={selectedCategory}>
              <option value="All">All</option>
              {categories.map((ctg) => (
                <option key={ctg.id} value={ctg.name}>
                  {ctg.name}
                </option>
              ))}
            </CustomSelect>
          </Col>
        </Row>

        {isLoading ? (
          <Loader />
        ) : products.length === 0 ? (
          <div className="not-found-template">
            {/* <h4>
              {' '}
              {searchQuery}
              {' '}
              not found.
            </h4> */}
            <Image
              loading="lazy"
              src={cakeImage1}
              alt="404 Not Found"
              fluid
            />
            <h6>There are no cakes available at the moment</h6>
            {/* You can add a nice design and additional information here */}
          </div>
        ) : (
          <>
            <Row className="mb-4">
              {products.map((product) => (
                <Col
                  className="mt-2 row-1 mb-3 px-sm-3 px-2"
                  xs={12}
                  sm={6}
                  md={3}
                  key={product.id}
                >
                  <div className="px-2 d-flex flex-column h-100">
                    <CakeAddToCart product={product} />
                  </div>
                </Col>
              ))}
            </Row>
            {totalPages > 1 && pageInfo.count > 0 && (
            <Row>
              <Col className="d-flex justify-content-center mt-4">
                {/* <Pagination
                  size="lg"
                  className="custom-pagination"
                >
                  <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === currentPage}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  />
                </Pagination> */}
              </Col>
            </Row>
            )}
          </>
        )}
      </Container>
      <Footer />
    </>
  );
}

export default ShopPage;
