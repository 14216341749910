/* eslint-disable no-unused-vars */
import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CategoryListScreen from './CategoryLIstScreen';
import ProductListScreen from './ProductListScreen';
import UserListScreen from './UserListScreen';
import WeightListScreen from './WeightListScreen';
import LocationListScreen from './Location/LocationListScreen';
import OrderScreenData from './OrderScren';
import ToppingsListScreen from './ToppingsListScreen';

function AdminEccomerceTable() {
  return (

    <Tabs
      defaultActiveKey="Products"
      id="fill-tab-example"
      className="mb-3"
      fill
    >
      <Tab eventKey="Orders" title={<div className="widget-heading">Orders</div>}>
        <OrderScreenData />
      </Tab>
      <Tab eventKey="Products" title={<div className="widget-heading">Products</div>}>
        <ProductListScreen />
      </Tab>
      <Tab eventKey="Customers" title={<div className="widget-heading">Customers</div>}>
        <UserListScreen />
      </Tab>
      <Tab eventKey="Categories" title={<div className="widget-heading">Categories</div>}>
        <CategoryListScreen />
      </Tab>
      <Tab eventKey="weight" title={<div className="widget-heading">Weights</div>}>
        <WeightListScreen />
      </Tab>
      <Tab eventKey="topping" title={<div className="widget-heading">Toppings</div>}>
        <ToppingsListScreen />
      </Tab>
      <Tab eventKey="location" title={<div className="widget-heading">Locations</div>}>
        <LocationListScreen />
      </Tab>
    </Tabs>
  );
}

export default AdminEccomerceTable;
