/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {
  Col, Container, Row, Image, Form, InputGroup,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaTiktok,
  FaYoutube,
} from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import cakeImage from '../assets/images/Cakes & Cakes Logo-white.png';
import pdfFile from '../assets/CAKES AND CAKES BAKERY LIMITED Registration Certificate.pdf';
import FooterBanner from './FooterBanner';
import axios from '../utils/axios';
import menu from '../assets/menu.pdf';

function Footer() {
  const [email, setEmail] = useState('');

  const socialLinks = [
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/cakesandcakeskenya',
      icon: FaFacebook,
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/cakesandcakeskenya/',
      icon: FaInstagram,
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/CakesandCakes',
      icon: FaTwitter,
    },
    {
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/company/cakes-and-cakes-bakery-ltd/',
      icon: FaLinkedin,
    },
    {
      name: 'TikTok',
      url: 'https://www.tiktok.com/@cakesandcakeskenya',
      icon: FaTiktok,
    },
    {
      name: 'YouTube',
      url: 'https://youtube.com/@CakesandCakesKE',
      icon: FaYoutube,
    },
  ];

  const handleSubscribe = async () => {
    try {
      const response = await axios.post('/api/users/subscribers/', { email });
      toast.success("Thank you for subscribing to Cakes & Cakes. We're excited to have you onboard!");
      setEmail('');
    } catch (error) {
      toast.error(error?.response?.data?.email[0]);
    }
  };

  const isMobileView = useMediaQuery({ maxWidth: 767 }); // Define the mobile view breakpoint

  return (
    <>
      <Container style={{ backgroundColor: '#CDF8EE', minHeight: '373px' }} fluid>
        {
          isMobileView
            ? (
              <>
                <Row>
                  <Col xs={12} sm={12} className="text-center">
                    <Link to="/shop/" className="link-color link-padding">
                      <p>Our Cakes</p>
                    </Link>
                    <Link to="/academy/curriculum" className="link-color link-padding">
                      <p>Our Academy</p>
                    </Link>
                    <a href={menu} target="_blank" rel="noopener noreferrer" className="link-color link-padding">
                      <p>Our Menu</p>
                    </a>
                    <p>
                      <a href={pdfFile} target="_blank" rel="noopener noreferrer" className="link-color link-padding">
                        Our Data Protection Compliance Certificate
                      </a>
                    </p>
                    <Link to="/faqs" className="link-color link-padding">
                      <p>FAQs</p>
                    </Link>
                  </Col>

                  <Col xs={12} sm={12} className="p-2">
                    <div className="social-icons">
                      {socialLinks.map((link) => (
                        <a key={link.name} href={link.url} target="_blank" rel="noopener noreferrer">
                          <link.icon size={40} className="icon" />
                        </a>
                      ))}
                    </div>
                    <br />
                    <br />
                    <Form.Label htmlFor="subscription-input">Subscribe to stay connected</Form.Label>
                    <Form onSubmit={handleSubscribe}>
                      <InputGroup className="mb-3">
                        <Form.Control
                          id="subscription-input"
                          type="email"
                          placeholder="Enter your email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        <InputGroup.Text
                          style={{ backgroundColor: '#CDF8EE', border: 'none', cursor: 'pointer' }}
                          onClick={handleSubscribe}
                        >
                          Subscribe &nbsp;
                          <i className="fa-brands fa-telegram" />
                        </InputGroup.Text>
                      </InputGroup>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex align-items-center justify-content-center" xs={6} sm={6}>
                    <div className="img-bg-mobile">
                      <Image loading="lazy" fluid src={cakeImage} style={{ maxWidth: '120px' }} />
                    </div>
                  </Col>
                  <Col xs={6} sm={6}>
                    <p>
                      Our goal is to be known as the purveyor of Tech for Cake - where traditional
                      baking meets new age technology to enhance cake consumption moments and bakery
                      education
                    </p>

                  </Col>
                </Row>

              </>

            ) : (
              <Row
                className="row justify-content-center mb-0 pt-5 pb-0 row-2"
              >
                <Col className="d-flex align-items-center justify-content-center" xs={12} sm={12} md={4} lg={3}>
                  <div className="img-bg">
                    <Image src={cakeImage} loading="lazy" />
                  </div>
                </Col>
                <Col xs={12} sm={12} md={4} lg={3}>
                  <p>
                    Our goal is to be know as the purveyor of Tech for Cake - where traditional
                    baking meets new age technology to enhance cake consumption moments and bakery
                    education
                  </p>
                  {/* <div className="social-icons">
                {socialLinks.map((link) => (
                  <a key={link.name} href={link.url} target="_blank" rel="noopener noreferrer">
                    <link.icon size={40} className="icon" />
                  </a>
                ))}
              </div> */}
                  {socialLinks.map((link) => (
                    <>
                      <a href={link.url} target="_blank" rel="noopener noreferrer">
                        <link.icon size={40} className="icon" />
                      </a>
                  &nbsp; &nbsp; &nbsp;
                    </>
                  ))}
                  <p className="contact-info">
                    <span className="contact-label">Email:</span>
                    {' '}
                    info@cakes.co.ke
                    <br />
                    <span className="contact-label">Kilimani (HQ):</span>
                    {' '}
                    0715 533144
                    <br />
                    <span className="contact-label">Buruburu branch:</span>
                    {' '}
                    0707 026986
                  </p>
                </Col>
                <Col xs={12} sm={12} md={2} lg={3}>
                  <Row>
                    <Col>
                      <Link to="/shop/">
                        <p>Our Cakes</p>
                      </Link>
                      <Link to="/academy/curriculum">
                        <p>Our Academy</p>
                      </Link>

                      <a href={menu} target="_blank" rel="noopener noreferrer">
                        <p>Our Menu</p>
                      </a>
                      <p>
                        <a href={pdfFile} target="_blank" rel="noopener noreferrer">
                          Our Data Protection Compliance Certificate
                        </a>
                      </p>
                      <Link to="/faqs">
                        <p>FAQs</p>
                      </Link>
                    </Col>
                    {/* <Col>
                  <p>Get in touch</p>
                  <Link to="/faqs">
                    <p>FAQs</p>
                  </Link>
                  <p>Privacy</p>
                  <p>Terms of Service</p>
                </Col> */}
                  </Row>
                </Col>
                <Col xs={12} sm={6} md={4} lg={3}>
                  <Form.Label htmlFor="subscription-input">Subscribe to stay connected</Form.Label>
                  <Form onSubmit={handleSubscribe}>
                    <InputGroup className="mb-3">
                      <Form.Control
                        id="subscription-input"
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <InputGroup.Text
                        style={{ backgroundColor: '#CDF8EE', border: 'none', cursor: 'pointer' }}
                        onClick={handleSubscribe}
                      >
                        Subscribe &nbsp;
                        <i className="fa-brands fa-telegram" />
                      </InputGroup.Text>
                    </InputGroup>
                  </Form>
                  <br />
                  <br />
                </Col>
              </Row>
            )
        }

      </Container>
      <FooterBanner />
    </>
  );
}

export default Footer;
