/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Alert, Image } from 'react-bootstrap';
import axios from '../utils/axios';
import Loader from './Loader';
import cakeImage1 from '../assets/images/Page 1_3.png';

function CourseCreationConfirmationPage() {
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderTrackingId = searchParams.get('OrderTrackingId');

  const { userInfo } = useSelector((state) => state.userLogin);

  const createEnrollmentData = (enrollmentFormValues) => ({
    // ... (existing data)
    user: userInfo.id,
    course_time_id: enrollmentFormValues.selectedTimeSlot,
    course: enrollmentFormValues.courseId,
    curriculum: enrollmentFormValues.curriculumId,
    order_tracking_id: orderTrackingId,
    curriculum_id: enrollmentFormValues.curriculumId,
    first_name: enrollmentFormValues.firstName,
    last_name: enrollmentFormValues.lastName,
    date_of_birth: enrollmentFormValues.dateOfBirth,
    address: enrollmentFormValues.address,
    medical_conditions: enrollmentFormValues.medicalConditions,
    id_number: enrollmentFormValues.idNumber,
    course_start_date: null,
    course_time: enrollmentFormValues.courseTime,
    selectedTimeSlot: enrollmentFormValues.selectedTimeSlot,
  });

  const initiateCourseCreationAfterStatus = async () => {
    setLoading(true);
    const enrollmentFormValues = JSON.parse(localStorage.getItem('enrollmentFormValues'));

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    try {
      const { data } = await axios.post('/api/enrollments/', createEnrollmentData(enrollmentFormValues), config);

      const updatedUserInfo = { ...userInfo, is_student: true };
      localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));

      if (userInfo.is_student) {
        navigate('/studentportal');
        localStorage.removeItem('enrollmentFormValues');
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log({ err });
      // setError(err.response.data.error);
    }
  };

  // Function to check transaction status
  const checkTransactionStatus = async () => {
    initiateCourseCreationAfterStatus();
  };

  useEffect(() => {
    checkTransactionStatus();

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [orderTrackingId]);

  return (
    <div>
      {paymentStatus === 'success' && (
      <Alert variant="success">
        Payment successful! You have enrolled for this course has been placed.
      </Alert>
      )}
      {paymentStatus === 'failed' && (
        <Alert variant="danger">
          Payment failed. Please try again or choose an alternative payment method.
        </Alert>
      )}
      {/* { error && <Alert variant="danger">{error}</Alert>} */}
      { loading && <Loader />}
      <div className="not-found-template">
        {/* <h4>
              {' '}
              {searchQuery}
              {' '}
              not found.
            </h4> */}
        <Image
          loading="lazy"
          src={cakeImage1}
          alt="404 Not Found"
          fluid
        />
        <h3>Please do not refresh this page as we redirect you to student's portal</h3>
        {/* You can add a nice design and additional information here */}
      </div>
    </div>
  );
}

export default CourseCreationConfirmationPage;
