/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UserListScreen from '../UserListScreen';
import AdminCurriculumScreen from '../AddCurriculumScreen';
import InstructorListScreen from '../Instructor';
import EnrollmentListScreen from '../enrollmentScreen';

function AdminAcademyScreen({ is_student, is_teacher }) {
  return (

    <Tabs
      defaultActiveKey="Curriculum"
      id="fill-tab-example"
      className="mb-3"
      fill
    >
      <Tab eventKey="Students" title={<div className="widget-heading">Students</div>}>
        <EnrollmentListScreen is_student={is_student} />
      </Tab>
      <Tab eventKey="Instructors" title={<div className="widget-heading">Instructors</div>}>
        <InstructorListScreen />
      </Tab>
      <Tab eventKey="Curriculum" title={<div className="widget-heading">Curriculum</div>}>
        <AdminCurriculumScreen />
      </Tab>
    </Tabs>
  );
}

export default AdminAcademyScreen;
