/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Button, Container, Row, Form, Pagination, Stack, Offcanvas, Image, ListGroup,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CreateProductButton, CustomFormControl, CustomLabel } from '../../components/styled';
import axios from '../../utils/axios';

function LocationListScreen() {
  const [locations, setLocations] = useState([]);
  const [showCreateOffcanvas, setShowCreateOffcanvas] = useState(false);
  const [showEditOffcanvas, setShowEditOffcanvas] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [newLocation, setNewLocation] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    name: '',
  });
  const [showLocation, setShowLocation] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const fetchLocations = () => {
    axios
      .get('/api/locations/')
      .then((response) => {
        setLocations(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCreateLocation = () => {
    const formData = new FormData();
    formData.append('name', newLocation.name);
    formData.append('contact', newLocation.contact);
    formData.append('address', newLocation.address);
    formData.append('url', newLocation.url);
    formData.append('location_image', newLocation.location_image);

    axios
      .post('/api/locations/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`,
        },
      })
      .then(() => {
        fetchLocations();
        setShowCreateOffcanvas(false);
        setNewLocation({});
        toast.success('Location created successfully');
      })
      .catch((error) => {
        if (error?.response) {
          const { data } = error.response;
          if (data.location_image) {
            toast.error(data.location_image[0]);
          }
          if (data.url) {
            toast.error(data.url[0]);
          }
        } else {
          toast.error('Failed to create location. Please try again.');
        }
      });
  };

  const handleEditLocation = () => {
    const formData = new FormData();
    formData.append('name', selectedLocation.name);
    formData.append('contact', selectedLocation.contact);
    formData.append('address', selectedLocation.address);
    formData.append('url', selectedLocation.url);

    if (selectedLocation.location_image instanceof File) {
      formData.append('location_image', selectedLocation.location_image);
    }

    axios
      .put(`/api/locations/${selectedLocation.id}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userInfo.token}`,
        },
      })
      .then(() => {
        fetchLocations();
        setShowEditOffcanvas(false);
        setSelectedLocation({});
        toast.success('Location edited successfully');
      })
      .catch((error) => {
        if (error?.response) {
          const { data } = error.response;
          if (data.location_image) {
            toast.error(data.location_image[0]);
          }
          if (data.url) {
            toast.error(data.url[0]);
          }
        } else {
          toast.error('Failed to edit location. Please try again.');
        }
      });
  };

  const handleDeleteLocation = (id) => {
    const confirmDelete = (window.confirm('Are you sure you want to delete this location?'));
    if (confirmDelete) {
      axios
        .delete(
          `/api/locations/${id}/`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userInfo.token}`,
            },
          },
        )
        .then(() => {
          fetchLocations();
          toast.success('Location deleted successfully');
        })
        .catch((error) => {
          toast.error('Failed to delete location');
        });
    }
  };

  const handleOpenEditOffcanvas = (location) => {
    setSelectedLocation(location);
    setShowEditOffcanvas(true);
  };

  const handleCloseEditOffcanvas = () => {
    setShowEditOffcanvas(false);
    setSelectedLocation({});
  };

  const handleCloseOffcanvas = () => {
    setShowCreateOffcanvas(false);
    setShowEditOffcanvas(false);
    setNewLocation({});
    setSelectedLocation({});
  };

  const handleImageInputChange = (e) => {
    const file = e.target.files[0];
    setNewLocation({ ...newLocation, location_image: file });
  };

  const handleViewLocation = (location) => {
    setSelectedLocation(location);
    setShowLocation(true);
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  const itemsPerPage = 10; // Number of items to display per page
  const totalItems = locations.length; // Total number of items

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // Perform any data fetching or updating based on the selected page
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    // Perform any data fetching or updating based on the filters
  };

  const renderTableRows = () => {
    // Replace with your own data fetching logic
    const data = locations || []; // Array of your data

    const filteredData = data.filter((item) => (
      item.name.toLowerCase().includes(filters.name.toLowerCase())
    ));

    // Filter and slice the data based on the current page
    const paginatedData = filteredData.slice(startIndex, endIndex);

    return paginatedData.map((item, index) => (
      <tr key={item.id}>
        <td className="text-center text-muted">{item.id}</td>
        <td className="text-center">
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left mr-3">
                <div className="widget-content-left">
                  <img
                    width="40"
                    loading="lazy"
                    className="rounded-circle"
                    src="assets/images/avatars/4.jpg"
                    alt=""
                  />
                </div>
              </div>
              <div className="widget-content-left flex2">
                <div className="widget-heading">{item.city}</div>
                <div className="widget-subheading opacity-7">
                  {item.address}

                </div>
              </div>
            </div>
          </div>
        </td>
        <td className="text-center">
          {item.name}
        </td>
        <td className="text-center">
          <div className="badge badge-warning">{item.url}</div>
        </td>
        <td className="text-center">
          <Stack direction="horizontal" gap={3}>
            <Button
              onClick={() => handleOpenEditOffcanvas(item)}
              variant="light"
              className="btn-sm"
            >
              <i className="fas fa-edit" />
            </Button>
            <Button variant="danger" className="btn-sm" onClick={() => handleDeleteLocation(item.id)}>
              <i className="fas fa-trash" />
            </Button>
            <Button
              variant="info"
              className="btn-sm"
              onClick={() => handleViewLocation(item)}
            >
              view Location
            </Button>

          </Stack>

        </td>
      </tr>
    ));
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="main-card mb-3 card">
            <div className="card-header">
              Locations
              <div className="btn-actions-pane-right">
                <div role="group" className="btn-group-sm btn-group">
                  <Button
                    onClick={() => setShowCreateOffcanvas(true)}
                    className="active btn btn-focus"
                  >
                    <i className="fas fa-plus" />
                    &nbsp;
                    Create Location

                  </Button>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <div className="filters">
                <Container>
                  <Row className="mt-0 pb-3 row-1 mb-0 px-sm-3 px-2">

                    <Form.Group controlId="nameFilter">
                      <CustomLabel>Name</CustomLabel>
                      <CustomFormControl
                        type="text"
                        name="name"
                        value={filters.name}
                        onChange={handleFilterChange}
                      />
                    </Form.Group>
                  </Row>
                </Container>
              </div>
              <table className="align-middle mb-0 table table-borderless table-striped table-hover">
                <thead>
                  <tr>
                    <th className="text-center">#</th>
                    <th className="text-center">Address</th>
                    <th className="text-center">Name</th>
                    <th className="text-center">Url</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {renderTableRows()}
                </tbody>
              </table>
            </div>
            <div className="d-block text-center card-footer">
              <Pagination
                size="lg"
                className="custom-pagination"
                style={{ float: 'right' }}
              >
                <Pagination.Prev
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {Array.from({ length: Math.ceil(totalItems / itemsPerPage) }, (_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={currentPage === index + 1}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
                />
              </Pagination>
            </div>
          </div>
        </div>
      </div>
      <Offcanvas show={showCreateOffcanvas} onHide={handleCloseOffcanvas} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Create Location</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            <Form.Group className="mb-3" controlId="createLocationName">
              <CustomLabel>Name</CustomLabel>
              <CustomFormControl
                type="text"
                value={newLocation.name || ''}
                onChange={(e) => setNewLocation({ ...newLocation, name: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="createLocationContact">
              <CustomLabel>Contact</CustomLabel>
              <CustomFormControl
                type="text"
                value={newLocation.contact || ''}
                onChange={(e) => setNewLocation({ ...newLocation, contact: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="createLocationAddress">
              <CustomLabel>Address</CustomLabel>
              <CustomFormControl
                type="text"
                value={newLocation.address || ''}
                onChange={(e) => setNewLocation({ ...newLocation, address: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="createLocationURL">
              <CustomLabel>URL</CustomLabel>
              <CustomFormControl
                type="text"
                value={newLocation.url || ''}
                onChange={(e) => setNewLocation({ ...newLocation, url: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="createLocationImage">
              <CustomLabel>Image</CustomLabel>
              <CustomFormControl type="file" onChange={handleImageInputChange} />
            </Form.Group>
            <div className="cart-footer-product">
              <CreateProductButton
                className="w-100 btn btn-focus mt-5 mb-0"
                variant="secondary"
                onClick={handleCreateLocation}
              >
                Create
              </CreateProductButton>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={showEditOffcanvas} onHide={handleCloseEditOffcanvas} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit Location</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            <Form.Group className="mb-3" controlId="editLocationName">
              <CustomLabel>Name</CustomLabel>
              <CustomFormControl
                type="text"
                value={selectedLocation.name || ''}
                onChange={(e) => setSelectedLocation({ ...selectedLocation, name: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="editLocationContact">
              <CustomLabel>Contact</CustomLabel>
              <CustomFormControl
                type="text"
                value={selectedLocation.contact || ''}
                onChange={(e) => setSelectedLocation(
                  { ...selectedLocation, contact: e.target.value },
                )}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="editLocationAddress">
              <CustomLabel>Address</CustomLabel>
              <CustomFormControl
                type="text"
                value={selectedLocation.address || ''}
                onChange={(e) => setSelectedLocation(
                  { ...selectedLocation, address: e.target.value },
                )}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="editLocationURL">
              <CustomLabel>URL</CustomLabel>
              <CustomFormControl
                type="text"
                value={selectedLocation.url || ''}
                onChange={(e) => setSelectedLocation(
                  { ...selectedLocation, url: e.target.value },
                )}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="editLocationImage">
              <CustomLabel>Image</CustomLabel>
              <CustomFormControl type="file" onChange={handleImageInputChange} />
            </Form.Group>
            <div className="cart-footer-product">
              <CreateProductButton
                className="w-100 mt-5 mb-0"
                variant="secondary"
                onClick={handleEditLocation}
              >
                Edit
              </CreateProductButton>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={showLocation}
        onHide={() => setShowLocation(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{selectedLocation.name}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <ListGroup style={{ maxWidth: '100%' }}>
              <ListGroup.Item>
                <strong>Contact:</strong>
                {' '}
                {selectedLocation.contact}
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>Address:</strong>
                {' '}
                {selectedLocation.address}
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>URL:</strong>
                {' '}
                {selectedLocation.url}
              </ListGroup.Item>
            </ListGroup>
            <br />
            <Image
              src={selectedLocation.location_image}
              alt="location image"
              loading="lazy"
              style={{ maxWidth: '300px' }}
            />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default LocationListScreen;
