/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Offcanvas, Button, Form, InputGroup,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import axios from '../../utils/axios';
import {
  CreateProductButton,
  CustomFormControl,
  CustomLabel,
  CustomSelect,
} from '../../components/styled';
import { listLocations } from '../../actions/locationAction';

function CreateCourseForm({
  show,
  handleCurriculumModalClose,
  curriculum,
  fetchCurriculumns,
  userInfo,
}) {
  const [title, setTitle] = useState(curriculum ? curriculum.title : '');
  const [description, setDescription] = useState(
    curriculum ? curriculum.description : '',
  );
  const [image, setImage] = useState(curriculum ? curriculum.image : null);
  const [price, setPrice] = useState(curriculum ? curriculum.price : 0);
  const [instructor, setInstructor] = useState(
    curriculum ? curriculum.instructor : '',
  );
  const [location, setLocation] = useState(
    curriculum ? curriculum.location : '',
  );
  const [courseContent, setCourseContent] = useState(
    curriculum ? curriculum.course_content : '',
  );
  const [instructors, setInstructors] = useState([]);
  const [courses, setCourses] = useState(curriculum ? curriculum.courses : []);
  const [lessons, setLessons] = useState(
    curriculum && curriculum.lessons ? JSON.parse(curriculum.lessons) : [],
  );

  const dispatch = useDispatch();
  const locationsList = useSelector((state) => state.locationsList);
  const { locations } = locationsList;

  const fetchInstructors = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${userInfo.token}`,
      };
      const response = await axios.get('/api/instructors/', { headers });
      setInstructors(response.data);
    } catch (error) {
      toast.error('Error while fetching instructors');
    }
  };

  useEffect(() => {
    dispatch(listLocations());
    fetchInstructors();
  }, [dispatch]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const headers = {
        Authorization: `Bearer ${userInfo.token}`,
        'Content-Type': 'multipart/form-data',
      };

      const formData = new FormData();
      formData.append('title', title.trim());
      formData.append('description', description);
      formData.append('price', price);
      formData.append('instructor', instructor);
      formData.append('location', location);
      formData.append('course_content', courseContent);
      formData.append(
        'courses',
        courses.map((course) => course.id).join(','),
      );
      formData.append('lessons', JSON.stringify(lessons));

      if (image instanceof File) {
        formData.append('image', image);
      }

      if (curriculum) {
        const response = await axios.put(
          `/api/curriculums/${curriculum.id}/`,
          formData,
          { headers },
        );
        toast.success('Curriculum updated successfully');
        fetchCurriculumns();
        handleCurriculumModalClose();
      } else {
        // Perform create
        const response = await axios.post('/api/curriculums/create/', formData, {
          headers,
        });
        toast.success('Curriculum created successfully');
        fetchCurriculumns();
        handleCurriculumModalClose();
      }
    } catch (error) {
      console.log({ error });
      toast.error('An error occurred');
    }
  };

  const handleLessonChange = (e, index) => {
    const newLessons = [...lessons];
    newLessons[index] = e.target.value;
    setLessons(newLessons);
  };

  const handleAddLesson = () => {
    setLessons([...lessons, '']);
  };

  const handleRemoveLesson = (index) => {
    const newLessons = [...lessons];
    newLessons.splice(index, 1);
    setLessons(newLessons);
  };

  return (
    <Offcanvas
      scroll
      show={show}
      onHide={() => handleCurriculumModalClose()}
      backdrop
      placement="end"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <h3>{curriculum ? 'Edit curriculum' : 'Add curriculum'}</h3>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onSubmit={handleFormSubmit} encType="multipart/form-data">
          <Form.Group className="mb-3" controlId="formcurriculumName">
            <CustomLabel>Title</CustomLabel>
            <CustomFormControl
              type="text"
              className="w-100 required-field"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formDescription">
            <CustomLabel>Description</CustomLabel>
            <CustomFormControl
              as="textarea"
              rows={5}
              required
              className="w-100 required-field"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formImage">
            <CustomLabel>Image</CustomLabel>
            {/* <br />
            {image && (
            <img
              src={image}
              loading="lazy"
              alt="Curriculum"
              style={{ width: '100px', height: 'auto' }}
            />
            )}
            <br /> */}
            <CustomFormControl
              type="file"
              accept="image/*"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formPrice">
            <CustomLabel>Price</CustomLabel>
            <CustomFormControl
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formInstructor">
            <CustomLabel>Instructor</CustomLabel>
            <CustomSelect
              className="w-100 required-field"
              value={instructor}
              onChange={(e) => setInstructor(e.target.value)}
            >
              <option value="">Select an instructor</option>
              {instructors.map((ins) => (
                <option key={ins.id} value={ins.id}>
                  {`${ins?.user?.first_name} ${ins?.user?.last_name}`}
                </option>
              ))}
            </CustomSelect>
          </Form.Group>

          <Form.Group className="mb-3" controlId="location">
            <CustomLabel>
              Location
              <span className="asterisk">*</span>
            </CustomLabel>
            <CustomSelect
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              className="w-100 required-field"
            >
              <option value="">Locations Offered</option>
              {(locations || []).map((loc) => (
                <option key={loc.id} value={loc.id}>
                  {loc.name}
                </option>
              ))}
            </CustomSelect>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formCourses">
            <CustomLabel>Courses</CustomLabel>
            {courses.map((course) => (
              <Form.Check
                key={course.id}
                type="checkbox"
                id={`course-checkbox-${course.id}`}
                label={`${course.start_date} - ${course.end_date}`}
                checked={courses.some((c) => c.id === course.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCourses((prevCourses) => [...prevCourses, course]);
                  } else {
                    setCourses((prevCourses) => prevCourses.filter((c) => c.id !== course.id));
                  }
                }}
              />
            ))}
          </Form.Group>

          <Form.Group controlId="lessons">
            <Form.Label>Lessons</Form.Label>
            <br />
            {lessons.map((lesson, index) => (
              <InputGroup key={index} className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Enter lesson"
                  value={lesson}
                  onChange={(e) => handleLessonChange(e, index)}
                  required
                />
                <Button
                  variant="outline-danger"
                  onClick={() => handleRemoveLesson(index)}
                >
                  Remove Lesson
                </Button>
              </InputGroup>
            ))}
            <Button variant="success" onClick={handleAddLesson}>
              Add Lesson
            </Button>
          </Form.Group>

          <br />
          <CreateProductButton className="w-100" variant="secondary" type="submit">
            {curriculum?.title ? 'Update' : 'Add'}
          </CreateProductButton>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default CreateCourseForm;
