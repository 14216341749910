/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Accordion, Container } from 'react-bootstrap';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { CustomSpan } from '../components/styled';

function FaqPage() {
  const faqs = [
    {
      question: 'What types of cakes do you offer in your retail shop?',
      answer: 'Our bakery offers a wide variety of cakes and pastries, including traditional favorites, unique and specialty items, vegan, gluten-free, and low sugar options, that are suitable for all occasions, from birthdays and weddings to corporate events and special celebrations.',
    },
    {
      question: 'What is the lead time for ordering a cake?',
      answer: 'We typically require a lead time of at least 24 hours for cake orders. This allows us to ensure that we have enough time to bake and decorate your cake to perfection. However, for larger or more intricate custom cake designs, we may require a longer lead time. We recommend placing your order well in advance, especially during busy seasons or for special occasions like weddings or birthdays. In some cases, we may be able to accommodate last-minute orders depending on our current availability. We encourage you to reach out to our shop directly to inquire about any urgent orders, and we will do our best to accommodate your request.',
    },
    {
      question: 'Can I place an order online or over the phone?',
      answer: 'Absolutely! We offer convenient options for placing cake orders. You can easily place an order through our website by selecting the desired cake, size, and any customizations you\'d like. Our online ordering system allows you to specify the pickup or delivery date and time. If you prefer to speak with someone directly, you can also call our shop, and our friendly staff will assist you in placing your order over the phone and/or WhatsApp. We strive to make the ordering process as seamless as possible to ensure you get the perfect cake for your occasion.',
    },
    {
      question: 'Do you offer delivery services?',
      answer: 'Yes, we offer delivery services for our cakes within a specified radius from our retail shop. Delivery charges may apply based on the distance. We understand the importance of delivering our cakes fresh and intact to your desired location, whether it\'s for a birthday party, wedding, or any other special occasion. Our experienced delivery team ensures that your cake arrives safely and on time. Please note that advance notice may be required for delivery orders to accommodate our schedule and make necessary arrangements. Feel free to contact our retail shop for more information regarding delivery options and charges.',
    },
    {
      question: 'Can I visit the shop and purchase a cake directly?',
      answer: 'Yes. We have multiple shop locations to serve our customers conveniently. You can visit any of our shops and purchase cakes directly. Our shop addresses are as follows:\n\ni. Kilimani branch at Starwood Apartments, suite B2 - Along Argwings Kodhek road, Nairobi.\nii. Buruburu branch at Rubis Rabai road, opposite The Point Mall\n\nEach location offers a wide selection of delectable cakes for you to choose from. Our friendly staff will be available to guide you through the options and help you find the perfect cake for your occasion. If you have any specific requirements or need customization, we recommend contacting the respective shop in advance so that we can better assist you.',
    },
    {
      question: 'What are your payment options?',
      answer: 'We offer multiple payment options to make your shopping experience convenient. You can pay for your cake purchases using credit cards (Visa, Mastercard) and debit cards. We also accept mobile payment i.e MPESA Till Number.',
    },
    {
      question: 'What courses or classes do you offer in your cake baking school?',
      answer: 'At our cake baking school, we offer a wide range of courses and classes to suit various skill levels and interests. Whether you\'re a beginner looking to learn the basics of cake baking or an experienced baker aiming to enhance your skills, we have something for everyone. Some of our popular courses include:\n\na) Basic Cake Baking & Decoration: A comprehensive course for beginners, covering essential techniques such as mixing, baking, and frosting.\nb) Advanced Cake Baking & Decoration: Designed for experienced bakers, this course focuses on intricate decorating techniques, including fondant work, piping, and creating elaborate designs.\nc) Bread & Pastry Classes: Explore the world of bread, pastries, and desserts beyond cakes, including tarts, pies, macarons, and more. Learn the techniques and skills needed to create delicious treats.\n\nThese are just a few examples of the courses we offer. We regularly introduce new classes and workshops to cater to the evolving interests and trends in the baking industry. Please check our website or contact us directly for the latest course offerings and schedules.',
    },
    {
      question: 'What is the duration of the courses?',
      answer: 'Our baking course is divided into levels as covered above. Each level runs for a month to complete and earns a certificate of participation after every level is covered. We have regular intakes - every first Monday of each month. Our classes are fun, interactive, and flexible.',
    },
    {
      question: 'Do you provide the necessary ingredients and tools for the classes?',
      answer: 'We provide all the material that a student needs in class from ingredients, tools & equipment, aprons, hairnets, and recipe booklets. A student carries home every baked good done during classes.',
    },
  ];

  return (
    <>
      <Header />
      <Container className="mb-5 mt-2 page-content-wrapper">
        <h2 className="mb-3 mt-3">Frequently Asked Questions (FAQs)</h2>
        <Accordion defaultActiveKey="0">
          {faqs.map((faq, index) => (
            <Accordion.Item key={index} eventKey={index.toString()}>
              <Accordion.Header>
                <h5>{faq.question}</h5>
              </Accordion.Header>
              <Accordion.Body>
                <CustomSpan>{faq.answer}</CustomSpan>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Container>
      <Footer />
    </>
  );
}

export default FaqPage;
