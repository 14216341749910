/* eslint-disable radix */
import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { CakeNameButton, PriceCard, ShopButton } from './styled';

function ProductCard({ product }) {
  const percentage = parseFloat(product.discount ?? '0%');
  const remainingPercentage = 100 - percentage;
  const discountPrice = parseInt(
    product.weights?.[0]?.price ?? 0,
  ) * (parseInt(remainingPercentage) / 100 ?? 0);

  const navigate = useNavigate();

  const handleShopNowClick = () => {
    navigate(`/product/${product.id}`);
  };

  return (
    <>
      <PriceCard>
        <PriceCard.Img
          src={`${product?.image1}`}
          alt={product?.name}
          className="product-image"
          style={{ display: 'block' }}
        />
        <PriceCard.Body>
          <CakeNameButton>
            <h5>{product?.category?.name}</h5>
          </CakeNameButton>
          <div className="price-button-container">
            {product.weights?.[0]?.price ? (
              <Button
                variant="outline-light"
                className="discount-button"
              >
                <h5>
                  <s>{product?.weights?.[0]?.price}</s>
                </h5>
                <h4>
                  {discountPrice ? `${discountPrice}/-` : 'Price not available'}
                </h4>
              </Button>
            ) : (
              <h4 className="price-not-available">Price not available</h4>
            )}
          </div>
        </PriceCard.Body>
      </PriceCard>
      <div className="d-grid gap-2">
        <ShopButton
          onClick={handleShopNowClick}
          variant="outline-light"
          type="submit"
        >
          Shop now
        </ShopButton>
      </div>
    </>
  );
}

export default ProductCard;
