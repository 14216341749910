/* eslint-disable default-param-last */
/* eslint-disable import/prefer-default-export */
import {
  WEIGHT_CREATE_FAIL,
  WEIGHT_CREATE_REQUEST,
  WEIGHT_CREATE_RESET,
  WEIGHT_CREATE_SUCCESS,
  WEIGHT_DELETE_FAIL,
  WEIGHT_DELETE_REQUEST,
  WEIGHT_DELETE_SUCCESS,
  WEIGHT_DETAILS_FAIL,
  WEIGHT_DETAILS_REQUEST,
  WEIGHT_DETAILS_RESET,
  WEIGHT_DETAILS_SUCCESS,
  WEIGHT_LIST_FAIL,
  WEIGHT_LIST_REQUEST,
  WEIGHT_LIST_SUCCESS,
  WEIGHT_LIST_TOTAL_FAIL,
  WEIGHT_LIST_TOTAL_REQUEST,
  WEIGHT_LIST_TOTAL_SUCCESS,
  WEIGHT_UPDATE_FAIL,
  WEIGHT_UPDATE_REQUEST,
  WEIGHT_UPDATE_RESET,
  WEIGHT_UPDATE_SUCCESS,
} from '../constants/weightConstants';

export const weightsListReducers = (state = { weights: [] }, action) => {
  switch (action.type) {
    case WEIGHT_LIST_REQUEST:
      return { loading: true, weights: [] };

    case WEIGHT_LIST_SUCCESS:
      return { loading: false, weights: action.payload };

    case WEIGHT_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const weightDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case WEIGHT_DELETE_REQUEST:
      return { loading: true };

    case WEIGHT_DELETE_SUCCESS:
      return { loading: false, success: true };

    case WEIGHT_DELETE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const weightUpdateReducer = (state = { weight: {} }, action) => {
  switch (action.type) {
    case WEIGHT_UPDATE_REQUEST:
      return { loading: true };

    case WEIGHT_UPDATE_SUCCESS:
      return { loading: false, success: true };

    case WEIGHT_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case WEIGHT_UPDATE_RESET:
      return { weight: {} };

    default:
      return state;
  }
};

export const weightCreateReducer = (state = { weight: {} }, action) => {
  switch (action.type) {
    case WEIGHT_CREATE_REQUEST:
      return { loading: true };

    case WEIGHT_CREATE_SUCCESS:
      return { loading: false, success: true };

    case WEIGHT_CREATE_FAIL:
      return { loading: false, error: action.payload };

    case WEIGHT_CREATE_RESET:
      return { weight: {} };

    default:
      return state;
  }
};

export const weightDetailsReducer = (state = { weight: {} }, action) => {
  switch (action.type) {
    case WEIGHT_DETAILS_REQUEST:
      return { ...state, loading: true };

    case WEIGHT_DETAILS_SUCCESS:
      return { loading: false, weight: action.payload };

    case WEIGHT_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case WEIGHT_DETAILS_RESET:
      return { weight: {} };

    default:
      return state;
  }
};

export const weightsTotalReducers = (state = { total: {} }, action) => {
  switch (action.type) {
    case WEIGHT_LIST_TOTAL_REQUEST:
      return { loading: true, total: {} };

    case WEIGHT_LIST_TOTAL_SUCCESS:
      return { loading: false, total: action.payload };

    case WEIGHT_LIST_TOTAL_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
